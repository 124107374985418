import React, { useEffect, useRef, useState } from "react";
import "./list.scss";
import ListItem from "../listItem/ListItem";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import NotFound from "../../../ELearning/Assets/Components/NotFound";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Audio } from "react-loader-spinner";

export const List = ({
  type,
  items,
  state,
  setState,
  setseeAllFlag,
  showNotFound = false,
}) => {
  const [slideNumber, setslideNumber] = useState(0);
  const [isMoved, setisMoved] = useState(false);
  const listRef = useRef();
  const [i, setI] = useState(10);
  const [leftslideCondition, setleftslideCondition] = useState(0);
  const [rightslideCondition, setrightslideCondition] = useState(5);
  const [isList, setisList] = useState(true);
  const [open, setOpen] = useState(false);
  let { setcurrentID } = useContext(Contexts);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
  };

  useEffect(() => {
    if (items.length > 0) {
      setisList(true);
    } else {
      setisList(false);
    }
  }, [items]);

  const seeAllClicked = (e, t) => {
    setseeAllFlag(true);
    setState(t.type);
    setcurrentID("");
  };

  const moreClicked = (e) => {};

  const handleClick = (direction) => {
    slideNumber == 0 ? setisMoved(false) : setisMoved(true);
    let distance = listRef.current.getBoundingClientRect().x - 50;
    if (direction === "left" && slideNumber > leftslideCondition) {
      setslideNumber(slideNumber - 1);
      listRef.current.style.transform = `translateX(${245 + distance}px)`;
    }
    if (direction === "right" && slideNumber < rightslideCondition) {
      setisMoved(true);
      setslideNumber(slideNumber + 1);
      listRef.current.style.transform = `translateX(${-245 + distance}px)`;
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClickDisappear = () => {
    setOpen((prev) => !prev);
  };

  const { t } = useTranslation();

  if (items.length === 0 && showNotFound) {
    return <NotFound message={t("rbkey_NoDataFound")} />;
  }

  return (
    <>
      {isList && (
        <div
          id={
            type !== null && type !== undefined ? type.replace(/ /g, "") : type
          }
          className="list"
          style={{ zIndex: -6 }}
        >
          <div className="listTitleHeader" style={{ zIndex: -6 }}>
            <span className="listTitle">
              {type}{" "}
              {type !== t("rbkey_RcntlyAddLBL") &&
                items.length > 10 &&
                i === 10 && (
                  <span
                    className="seeAll"
                    onClick={(e) => {
                      seeAllClicked(e, { type });
                    }}
                  >
                    {t("rbkey_SeeAll")}
                  </span>
                )}
            </span>
          </div>
          <div style={{ margin: "30px", backgroundColor: "#111f3b" }}>
            {isList ? (
              <Slider {...settings} className="video_channel_slider">
                {items.map(
                  (item, index) =>
                    index < i && (
                      <div
                        key={item.NM_PDCST_ID}
                        onClick={handleClickDisappear}
                      >
                        <ListItem type={type.replace(/ /g, "")} info={item} />

                        <div className="title" style={{ maxWidth: 224 }}>
                          {item.VC_PDCST_NM}
                        </div>
                      </div>
                    )
                )}
              </Slider>
            ) : (
              <div style={{ paddingLeft: "45%" }}>
                <Audio
                  height="60"
                  width="75"
                  color="white"
                  arialLabel="Loading"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const PUBLIC_URL = "https://pscfmje-lmshorizon-lms-api.azurewebsites.net";
export const PUBLIC_URL_API2 = "https://pscfmje-lmshorizon-lms-api.azurewebsites.net";
export const LMS_DEV = "https://pscfmje-lmshoriz-dt.azurewebsites.net";
export const LMS_QA = "https://pscfmje-lmshoriz-dt.azurewebsites.net";
export const LMS_PROD = "https://pscfmje-lmshoriz-dt.azurewebsites.net";
export const LMS="https://lms-fusoacademy.mitsubishi-fuso.com";
export const CONTAINER_NAME = `pscfmjelmscont`;
export const STORAGE_ACCOUNT_NAME = "pscfmjelmsdt";

export const SAS_TOKEN =
  "sp=racwdli&st=2023-11-18T06:15:12Z&se=2025-11-30T14:15:12Z&spr=https&sv=2022-11-02&sr=c&sig=uTkj9iORSHZWb3bcVgEB2UIGdiFF0amPAM3vIXHNn5w%3D";

//token from new container qsclmshorfacont
export const LANG_SAS_TOKEN =
  "sp=r&st=2023-11-01T05:57:40Z&se=2025-11-30T13:57:40Z&spr=https&sv=2022-11-02&sr=c&sig=vQ2wr1uBgHsWKV%2BVBl4YHmn9%2FQctGp%2FZDmriUjFwUt8%3D";

export const BLOB_LANG =
  "https://qsclmshorfasadt.blob.core.windows.net/qsclmshorfacont/WCR/WCRLanguageJSON";

export const STORAGE_ADDRESS = "https://qsclmshorfasadt.blob.core.windows.net/qsclmshorfacont"
  // "https://psclmshorfasa.blob.core.windows.net/psclmshorfacont";

export const HOME_URL = "https://pscfmje-lmshoriz-dt.azurewebsites.net/";

export const DOMAIN_ID = 10;

export const formatTime = (date, type) => {
  const date1 = date
    ? new Date(date).toLocaleDateString("JP", {
        timeZone: "Asia/Tokyo",
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric",
      })
    : new Date().toLocaleDateString("JP", {
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric",
      });

  var theDate = new Date(Date.parse(date1 + " UTC"));

  if (type === "date") {
    return theDate.toLocaleString().split(",")[0];
  } else {
    return theDate.toLocaleString().split(",")[1];
  }
};

export const CreateValidation = {
  FILE_SIZE: "Please select a file lower than 10 MB.",
  FILE_COUNT: "Maximum 5 files are allowed.",
};

export const FileExtensions = [
  "doc",
  "docx",
  "docm",
  "xls",
  "xlsm",
  "xlsx",
  "csv",
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "tif",
  "gif",
  "ppt",
  "pptx",
];

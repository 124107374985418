import React, { useState } from "react";
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../VideoChannel/components/Language/languageSelect";
import Fusologo from "../Assets/Images/FusoSifnImg5.png";
import styles from "./DrawerStyles.module.scss";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(() => ({
  drawerContainer: {
    width: "75vw",
    background: "#4a4646",
    paddingTop: "50px",
    paddingLeft: "20px",
  },
  listItem: {
    color: "white",
    fontWeight: "bold",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLearningCenterOpen, setIsLearningCenterOpen] = useState(false);
  const [isCollabOpen, setIsCollabOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          width: "100vw",
          background: "#4a4646",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <img src={Fusologo} alt="FusoImg" style={{ maxWidth: "50vw" }} />
        <IconButton onClick={() => setOpenDrawer((prev) => !prev)}>
          <MenuIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{ paper: classes.drawerContainer }}
      >
        <div className={styles.drawerImageRow}>
          <img
            src={Fusologo}
            alt="FusoImg"
            style={{ maxWidth: "35vw", paddingBottom: "50px" }}
          />
        </div>
        <List>
          <ListItem onClick={() => setOpenDrawer((prev) => !prev)}>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <ListItemText className={classes.listItem}>
                {t("rbkey_DshbrdLBL").toUpperCase()}
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem onClick={() => setIsLearningCenterOpen((prev) => !prev)}>
            <ListItemText className={classes.listItem}>
              {t("rbkey_LrnngCntr").toUpperCase()}
            </ListItemText>
          </ListItem>
          <Collapse in={isLearningCenterOpen}>
            <List style={{ marginLeft: "15px" }}>
              <ListItem>
                <Link to="/mylearning" style={{ textDecoration: "none" }}>
                  <ListItemText className={classes.listItem}>
                    {t("rbkey_MyECrssLBL").toUpperCase()}
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to="/availablelearning"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemText className={classes.listItem}>
                    {t("rbkey_AvlblLrnngLBL").toUpperCase()}
                  </ListItemText>
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem onClick={() => setIsCollabOpen((prev) => !prev)}>
            <ListItemText className={classes.listItem}>
              {t("rbkey_CllbrtnLBL").toUpperCase()}
            </ListItemText>
          </ListItem>
          <Collapse in={isCollabOpen}>
            <List style={{ marginLeft: "15px" }}>
              <ListItem>
                <Link to="/videochannel" style={{ textDecoration: "none" }}>
                  <ListItemText className={classes.listItem}>
                    {t("rbkey_VideoChannelLBL").toUpperCase()}
                  </ListItemText>
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem onClick={() => setOpenDrawer((prev) => !prev)}>
            <Link to="/trainingcalender" style={{ textDecoration: "none" }}>
              <ListItemText className={classes.listItem}>
                {t("rbkey_IltClndrLBL").toUpperCase()}
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer((prev) => !prev)}>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <ListItemText className={classes.listItem}>
                {t("rbkey_CntctUsLBL").toUpperCase()}
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer((prev) => !prev)}>
            <Link to="/help" style={{ textDecoration: "none" }}>
              <ListItemText className={classes.listItem}>
                {t("rbkey_HlpLBL").toUpperCase()}
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem>
            <div className={styles.language}>
              <LanguageSelect />
            </div>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
export default DrawerComponent;

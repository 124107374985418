import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { ML_Elearning } from "./ML_Elearning";
import { ML_ClassroomTraining } from "./ML_ClassroomTraining";
import { ML_Program } from "./ML_Program";
import truck from "../../Assets/Images/truck.png";
import Navbar from "../../Pages/NavBar.jsx";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import SearchIcon from "@material-ui/icons/Search";
import "../../Pages/Home.scss";
import "./ML_Videochannel.css";
import { Contexts } from "../../../contexts/Contexts";
import { useTranslation } from "react-i18next";
import { ImHome } from "react-icons/im";
//comment
import { Stack, Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { PUBLIC_URL, HOME_URL, DOMAIN_ID } from "./../Common/Constants.js";
import { GoDeviceCameraVideo } from "react-icons/go";

export const Mylearn = () => {
  const { searchField } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [posts1, setPosts1] = useState([]);
  const [posts2, setPosts2] = useState([]);
  const [posts3, setPosts3] = useState([]);

  const [postData1, setpostData1] = useState([]);
  const [postData2, setpostData2] = useState([]);
  const [postData3, setpostData3] = useState([]);
  const [toggleState, setToggleState] = useState(0);
  const [value, setValue] = useState("");
  const { setgblEnrollDisable } = useContext(Contexts);

  const toggletab = (index) => {
    setToggleState(index);
  };

  const [searchValue, setSearchValue] = useState([]);
  const [searchValueOnEnter, setSearchValueOnEnter] = useState([]);

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts1(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
        setpostData1(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios

      .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "desc",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts2(res.data);
        setpostData2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/Program/MyLearning/Program", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts3(res.data);
        setpostData3(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const courses = [];
  const courseList1 = posts1.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    courses.push(course.VC_ILT_CD);
  });

  const courseList2 = posts2.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    courses.push(course.VC_ILT_CD);
  });

  const courseList3 = posts3.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    courses.push(course.VC_ILT_CD);
  });
  const handleSearchClick = () => {
    setSearchValue(searchValueOnEnter);
  };

  const handleKeyDown = (e, value) => {
    if (e.key == "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
    } else if (e.key == "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };
  //Changes made by Saurav
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      setSearchValue(searchValueOnEnter);
    }
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setcurrentID("");
  };

  function SearchItemData(searchtext) {
    if (searchtext == null) {
      setPosts1(postData1);
      setPosts2(postData2);
      setPosts3(postData3);
    } else {
      posts1.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM == searchtext) {
          let myData = [item];
          setPosts1(myData);
        }
      });
      posts2.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM == searchtext) {
          let myData = [item];
          setPosts2(myData);
        }
      });
      posts3.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM == searchtext) {
          let myData = [item];
          setPosts3(myData);
        }
      });
    }
  }
  //
  return (
    <>
      <Navbar />

      <div className="bg-image">
        <p className="userinfo" style={{ display: "flex" }}>
          {" "}
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaUserCircle style={{ fontSize: "20px" }} />
          </div>
          <div
            style={{
              color: "white",
              textAlign: "right",
              paddingLeft: "5px",
              fontWeight: "normal",
            }}
          >
            {" "}
            {sessionStorage.getItem("userFirstName")}{" "}
            {sessionStorage.getItem("userLastName")}
          </div>
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            <GoDeviceCameraVideo
              title="VideoChannel"
              style={{ fontSize: "20px" }}
              onClick={() => {
                navigate("/videochannel");
              }}
            />
            <a
              href={HOME_URL}
              style={{
                color: "white",
                textAlign: "right",
                paddingLeft: "10px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              title="Back To Main Application"
            >
              <ImHome />
            </a>
          </div>
        </p>

        <div
          style={{
            color: "white",
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: "#435270",
            height: "35px",
            paddingTop: "8px",
            marginBottom: "20px",
            paddingLeft: "15px",
          }}
        >
          <>
            <p>
              {t("rbkey_MyECrssLBL")}

              <Stack
                spacing={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  float: "right",
                  maxHeight: "25px",
                  minWidth: "22%",
                }}
              >
                <input
                  placeholder="Search"
                  id="mySearch"
                  value={searchValueOnEnter}
                  onKeyDown={handleEnterKey}
                  onChange={(e) => setSearchValueOnEnter(e.target.value)}
                  style={{ width: "90%" }}
                />
                <SearchIcon
                  style={{ marginTop: -1, marginRight: "5%" }}
                  onClick={handleSearchClick}
                ></SearchIcon>
              </Stack>
            </p>
          </>
        </div>
        <div />

        <div className="ribbon">
          <p title={t("rbkey_ILTMSG")}>{t("rbkey_ClssrmTrnngsLBL")}</p>
        </div>
        <ML_ClassroomTraining search={searchValue} />

        <div className="ribbon">
          <p title={t("rbkey_eLrningMSG")}>{t("rbkey_ELrrngLbl")}</p>
        </div>
        <ML_Elearning search={searchValue} />
        <div className="ribbon">
          <p title={t("rbkey_prgrmMSG")}>{t("rbkey_PrgrmLbL")} </p>
        </div>
        <ML_Program search={searchValue} />
      </div>
    </>
  );
};
function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <p>{children}</p>}</div>;
}

export default Mylearn;

import { ArrowBackIosNewOutlined, VideoFileRounded } from "@mui/icons-material";
import React, { useState } from "react";
import "./watch.scss";
import { AzureMP } from "react-azure-mp";
import { useNavigate, useLocation , Link} from "react-router-dom";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PUBLIC_URL } from "./../../../ELearning/Components/Common/Constants.js";

const Watch = () => {
  const location = useLocation();
  let videosource = "";
  if (location.state.videourl != null) {
    videosource = location.state.videourl.toString();
  }
  const corporateId = location.state.corporateId;
  const podcastId = location.state.podcastId;
  const timestamp = location.state.timestamp;
  const playbackFinished = location.state.playbackCompleted;

  if (location.moreFlag) {
    location.setmoreFlag(false);
  }

  let { duration } = useContext(Contexts);
  let { playbackCompleted } = useContext(Contexts);
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.token}` },
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/videochannel`;
    navigate(path);
  };

  useEffect(() => {
    setInterval(() => {
      axios({
        method: "post",
        url: PUBLIC_URL + `/api/Podcast/UserVideoMapping`,
        headers: { Authorization: `Bearer ${sessionStorage.token}` },
        data: {
          corpID: corporateId,
          podcastID: podcastId,
          userID: Number(sessionStorage.getItem("userId")),
          playBackRemaining: duration,
          videoCompleted: playbackCompleted,
        },
      }).then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
    }, 10000);
  }, []);

  useEffect(() => {
    console.log("FROM WATCH - VIDEOSOURCE :", videosource);
  }, [videosource]);

  const onInstanceCreated = (player) => {
    if (player) {
      if (Number(timestamp) != 0 && !playbackFinished) {
        player.on("play", (_e) => {
          player.currentTime(timestamp);
        });
      }
      player.on("pause", (_e) => {});
      player.on("progress", (_e) => {
        duration = Math.round(player.currentTime());
      });
      player.on("ended", (_e) => {
        playbackCompleted = 1;
      });
    }
  };

  const { t } = useTranslation();

  if (videosource != "") {
    return (
      <div className="watch">
        <div className="back">
          {/* <ArrowBackIosNewOutlined onClick={routeChange} />
          <span onClick={routeChange}>Back</span> */}
          <Link style={{color:"white"}} reloadDocument to="/videochannel"> {t("rbkey_BckLBL")} </Link>
        </div>

        <AzureMP
          onInstanceCreated={(player) => onInstanceCreated(player)}
          className="VideoPlayer"
          adaptRatio={[16, 9]}
          skin="amp-flush"
          src={[
            {
              src: videosource,
              // src: "https://meshapp.azurefd.net/pfl-blob-container/5fab7f704543436ec881d736/4a0ffa94-c3fc-4551-a407-6c6a4e6646ce/e8304b65-112e-4b81-b15e-2b94f3ec1799.mp4",
              // src: "https://dscfmjelmsmedia-jpea.streaming.media.azure.net/227c3aea-5bc3-48cd-ae15-cab494c21148/small.ism/manifest",
              // src: "https://dscfmjelmsmedia-jpea.streaming.media.azure.net/f7490007-7cee-4d0f-9807-e04e60596b29/200311LMS%20TRAILER-v1.0BGM%20only.ism/manifest",
              // type: "application/vnd.ms-sstr+xml",
            },
          ]}
          options={{
            autoplay: true,
            controls: true,
            width: "600",
            height: "400",
          }}
        />

        {/* <iframe width="1264" height="480" src="https://www.youtube.com/embed/zSWdZVtXT7E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      </div>
    );
  } else {
    return (
      <>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "2% 0 0 0",
          }}
        >
          No URL Found
        </p>
        ;
      </>
    );
  }
};

export default Watch;

import React, { useState, useEffect } from "react";
import "./ML_Videochannel.css";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Audio } from "react-loader-spinner";
import { GrCertificate } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import NotFound from "../../Assets/Components/NotFound";

export const ML_Elearning = (props) => {
  var nominationStatus;
  const [posts, setposts] = useState([]);
  const [postSave, setpostSave] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [postsData, setpostsData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();

  var noOfRecords;
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "  ");
  }
  const filteredPosts = posts.filter((item, key) => {
    if (item.VC_ILT_CRS_LNCH_NM != null && item.VC_ILT_CD != null) {
      if (item.VC_ILT_CRS_LNCH_NM == item.VC_ILT_CD) {
        return item.VC_ILT_CRS_LNCH_NM.toLowerCase().includes(
          String(props.search).toLowerCase()
        );
      } else {
        return (
          item.VC_ILT_CRS_LNCH_NM.toLowerCase().includes(
            String(props.search).toLowerCase()
          ) ||
          item.VC_ILT_CD.toLowerCase().includes(
            String(props.search).toLowerCase()
          )
        );
      }
    }
    //
  });

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "desc",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setposts(res.data);
        setpostSave(res.data);
        setLoaded(true);
        console.log("loading", loading);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
  };
  let iconStyles = {
    color: "white",
    fontSize: "1.5em",
    margin: 0,
    textAlign: "right",
    right: "2px",
    alignItems: "right",
    textColor: "red",
    fill: "white",
  };

  if (posts.length === 0 && !loaded) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  } else if (posts.length === 0 && loaded) {	
    return <NotFound />;
  }
  //
  function handleClickShowMore() {
    setShowMore(!showMore);
    if (showMore === true) {
      noOfRecords = 100;
    } else {
      noOfRecords = 12;

      axios
        .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
          params: {
            searchType: "",
            searchText: "",
            orderBy: "VC_ILT_CRS_LNCH_NM",
            order: "ASC",
            pageNumber: 1,
            numberOfRecordsPerPage: noOfRecords,
            corporateId:
              sessionStorage.getItem("userDomain") !== null
                ? sessionStorage.getItem("userDomain")
                : DOMAIN_ID,
            userId: sessionStorage.getItem("userId"),
            combineAssignedCourses: "",
            learningType: "",
            sessionId: "en-US",
            rbKeyValueProgam: "Program",
            rbKeyValueCourse: "eLearning",
            rbKeyValueILT: "Classroom Training",
            iltId: "",
            courseLaunchId: "",
          },
        })
        .then((res) => {
          setpostsData(res.data);          	
          setLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });	
        setLoaded(true);
    }
  }

  if (props.search == "" || props.search == null) {
    return (
      <div
        style={{ margin: "30px", backgroundColor: "#111f3b", marginBottom: "0px" }}
      >
        <Slider {...settings}>
          {postSave.map((item, index) => {
            if ((item.NMNTN_STTS = "C")) {
              nominationStatus = "Completed";
            } else if ((item.NMNTN_STTS = "P")) {
              nominationStatus = "Pending";
            } else if ((item.NMNTN_STTS = "S")) {
              nominationStatus = "Started";
            } else {
              nominationStatus = "Pending";
            }

            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
            let tempURL;
            if (item.VC_UPLDD_FL_NM != null) {
              tempURL =
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
                sasToken;
            } else {
              tempURL =
                "https://img.freepik.com/free-vector/e-learning-icons-flat_1284-3950.jpg?w=2000";
            }
            //
            let stringForDescription = removeTags(item.VC_ILT_CRS_LNCH_DSCRPTN);
            //
            return (
              <div className="wrapper" key={index}>
                <Link
                  className="link"
                  to="/detailsML"
                  state={{
                    course_id: item.NM_CRS_LNCH_ID,
                    type: item.NM_LRNNG_TYP,
                    course_name: item.VC_ILT_CRS_LNCH_NM,
                    start_date: item.DT_STRT_DT,
                    end_date: item.DT_END_DT,
                    credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                    progress: item.NM_PRGRSS,
                    description: stringForDescription,
                    isAvailableForever: item.BT_IS_AVLBL_FRVR,
                    certificateID: item.NM_CRTFCT_ID,
                    VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                    VC_USR_NM: item.VC_USR_NM,
                    DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                    imgSrc: tempURL,
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM,
                    certificate_id: item.NM_CRTFCT_ID,
                    certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                  }}
                >
                  <img
                    style={{ width: "100%", height: "125px",objectFit: "cover" }}
                    src={tempURL}
                    alt=""
                  />
                </Link>
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "smaller",
                    marginBottom: 0,
                  }}
                >
                  <ProgressBar
                    now={item.NM_PRGRSS}
                    label={`${item.NM_PRGRSS}%`}
                    className="progressBar"
                  />

                  {item.NM_CRTFCT_ID > 0 && (
                    <>
                      {item.NM_PRGRSS == 100 && (
                        <GrCertificate
                          style={iconStyles}
                          title="Certificate"
                          onClick={() => {
                            if (item.NM_CRTFCT_ID > 0) {
                              navigate("/certificatepage", {
                                state: {
                                  VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                                  VC_USR_NM: item.VC_USR_NM,
                                  DT_CMPLTN_DT:
                                    item.DT_CMPLTN_DT == null
                                      ? ""
                                      : item.DT_CMPLTN_DT.substring(0, 10),
                                  certificate_id: item.NM_CRTFCT_ID,
                                  certificate_prnt_flg:
                                    item.BT_LRNR_PRNT_CRTFCT,
                                },
                              });
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </p>

                {/* <div className="title" style={{ display: "flex" }}>
                  <p title={item.VC_ILT_CRS_LNCH_NM}>
                    {item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                  </p>{" "}
                  |{" "}
                  <p title={item.VC_ILT_CD}>
                    {item.VC_ILT_CD?.substring(0, 11)}...
                  </p>
                </div> */}
                 <div className="title" style={{ display: "flex" }}>
                     <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                     </p>{" "}
                    </div>

                <p className="description">
                  {stringForDescription.toString().substring(0, 40)}
                  <Link
                    className="directlink"
                    to="/detailsML"
                    state={{
                      course_id: item.NM_CRS_LNCH_ID,
                      type: item.NM_LRNNG_TYP,
                      course_name: item.VC_ILT_CRS_LNCH_NM,
                      start_date: item.DT_STRT_DT,
                      end_date: item.DT_END_DT,
                      credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                      progress: item.NM_PRGRSS,
                      description: stringForDescription,
                      isAvailableForever: item.BT_IS_AVLBL_FRVR,
                      certificateID: item.NM_CRTFCT_ID,
                      VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                      VC_USR_NM: item.VC_USR_NM,
                      DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                      imgSrc: tempURL,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                      certificate_id: item.NM_CRTFCT_ID,
                      certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                    }}
                  >
                    ...
                    {t("rbkey_Showmore_LBL")}
                  </Link>
                </p>
              </div>
            );
          })}
        </Slider>

        <div
          style={{
            textAlign: "right",
            paddingRight: "5px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <button
            style={{
              color: "White",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "rgb(78, 78, 78)",
              fontSize: "small",
            }}
            onClick={() => {
              navigate("/mylearning/elearning");
            }}
          >
            {t("rbkey_VwAllLBL")}
          </button>
        </div>
      </div>
    );
  }

  if (!filteredPosts?.length) {
    return <NotFound />;
  }

  return (
    <div
      style={{ margin: "30px", backgroundColor: "#111f3b", marginBottom: "0px" }}
    >
      <Slider {...settings}>
        {filteredPosts.map((item, index) => {
          if ((item.NMNTN_STTS = "C")) {
            nominationStatus = "Completed";
          } else if ((item.NMNTN_STTS = "P")) {
            nominationStatus = "Pending";
          } else if ((item.NMNTN_STTS = "S")) {
            nominationStatus = "Started";
          } else {
            nominationStatus = "Pending";
          }

          const sasToken = SAS_TOKEN;
          const containerName = CONTAINER_NAME;
          const storageAccountName = STORAGE_ACCOUNT_NAME;
          let tempURL;
          if (item.VC_UPLDD_FL_NM != null) {
            tempURL =
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
              sasToken;
          } else {
            tempURL =
              "https://img.freepik.com/free-vector/e-learning-icons-flat_1284-3950.jpg?w=2000";
          }
          //
          let stringForDescription = removeTags(item.VC_ILT_CRS_LNCH_DSCRPTN);
          //
          return (
            <div className="wrapper" key={index}>
              <Link
                className="link"
                to="/detailsML"
                state={{
                  course_id: item.NM_CRS_LNCH_ID,
                  type: item.NM_LRNNG_TYP,
                  course_name: item.VC_ILT_CRS_LNCH_NM,
                  start_date: item.DT_STRT_DT,
                  end_date: item.DT_END_DT,
                  credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                  progress: item.NM_PRGRSS,
                  description: stringForDescription,
                  isAvailableForever: item.BT_IS_AVLBL_FRVR,
                  certificateID: item.NM_CRTFCT_ID,
                  VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                  VC_USR_NM: item.VC_USR_NM,
                  DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                  imgSrc: tempURL,
                  VC_ILT_CD: item.VC_ILT_CD,
                  topic_name: item.VC_ILT_KND_NM,
                  certificate_id: item.NM_CRTFCT_ID,
                  certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                }}
              >
                <img
                  style={{ width: "100%", height: "125px",objectFit: "cover" }}
                  src={tempURL}
                  alt=""
                />
              </Link>
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "smaller",
                  marginBottom: 0,
                }}
              >
                <ProgressBar
                  now={item.NM_PRGRSS}
                  label={`${item.NM_PRGRSS}%`}
                  className="progressBar"
                />
                {item.NM_CRTFCT_ID > 0 && (
                  <>
                    {item.NM_PRGRSS == 100 && (
                      <GrCertificate
                        style={iconStyles}
                        title="Certificate"
                        onClick={() => {
                          if (item.NM_CRTFCT_ID > 0) {
                            navigate("/certificatepage", {
                              state: {
                                VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                                VC_USR_NM: item.VC_USR_NM,
                                DT_CMPLTN_DT:
                                  item.DT_CMPLTN_DT == null
                                    ? ""
                                    : item.DT_CMPLTN_DT.substring(0, 10),
                                certificate_id: item.NM_CRTFCT_ID,
                                certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                              },
                            });
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </p>

              {/* <div className="title" style={{ display: "flex" }}>
                <p title={item.VC_ILT_CRS_LNCH_NM}>
                  {item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                </p>{" "}
                |{" "}
                <p title={item.VC_ILT_CD}>
                  {item.VC_ILT_CD?.substring(0, 11)}...
                </p>
              </div> */}

<div className="title" style={{ display: "flex" }}>
                     <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                     </p>{" "}
                    </div>

              <p className="description">
                {stringForDescription.toString().substring(0, 40)}
                <Link
                  className="directlink"
                  to="/detailsML"
                  state={{
                    course_id: item.NM_CRS_LNCH_ID,
                    type: item.NM_LRNNG_TYP,
                    course_name: item.VC_ILT_CRS_LNCH_NM,
                    start_date: item.DT_STRT_DT,
                    end_date: item.DT_END_DT,
                    credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                    progress: item.NM_PRGRSS,
                    description: stringForDescription,
                    isAvailableForever: item.BT_IS_AVLBL_FRVR,
                    certificateID: item.NM_CRTFCT_ID,
                    VC_USR_NM: item.VC_USR_NM,
                    DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                    imgSrc: tempURL,
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM,
                    certificate_id: item.NM_CRTFCT_ID,
                    certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                  }}
                >
                  ...
                  {t("rbkey_Showmore_LBL")}
                </Link>
              </p>
            </div>
          );
        })}
      </Slider>

      <div
        style={{
          textAlign: "right",
          paddingRight: "5px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <button
          style={{
            color: "White",
            border: "none",
            borderRadius: "5px",
            backgroundColor: "rgb(78, 78, 78)",
            fontSize: "small",
          }}
          onClick={() => {
            navigate("/mylearning/elearning");
          }}
        >
          {t("rbkey_VwAllLBL")}
        </button>
      </div>
    </div>
  );
};

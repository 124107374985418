import React from "react";

function NotFound({ message }) {
  return (
    <div
      style={{
        margin: "10px 2.5%",
        display: "grid",
        placeItems: "center",
        height: "100px",
      }}
    >
      {message ? message : "No Results Found"}
    </div>
  );
}

export default NotFound;

import React, { useState } from "react";
import { ImHome } from "react-icons/im";
import { HOME_URL } from "./../../ELearning/Components/Common/Constants.js";
export const UnderConstruction = () => {
  return (
    <div className="ucBorder">
      <div className="underConstruction">
        <h1>Back to Main Application</h1>
        <a href={HOME_URL} title="Back To Main Application">
          <ImHome />
        </a>
      </div>
    </div>
  );
};
export default UnderConstruction;

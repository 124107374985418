import { offer_styles } from "./OfferList.scss";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import
{
  PUBLIC_URL,
  PUBLIC_URL_API2 ,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "../Common/Constants.js";
import Navbar from "../../Pages/NavBar";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const OfferList = (props) => {
  debugger;
  const [offerData, setofferData] = useState();
  let filteredData = [];
  let tempURL = "";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
  };

  useEffect(() => {
    console.log("offerData : ", offerData);
  }, [offerData]);

  useEffect(() => {
    axios
      .get(
        PUBLIC_URL + "/api/ClassRoomTraining/ILT/getAllOfrsOnILTId",
        // .get("https://localhost:44317/api/ClassRoomTraining/ILT/getAllOfrsOnILTId",
        {
          params: {
            ILTId: parseInt(sessionStorage.getItem("CourseId")),
          },
        }
      )
      .then((res) => {
        debugger;
        if (res.data != undefined && res.data.length > 0) {
          setofferData(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const tileClickHandler = (requestItem) => {
    debugger;

    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 10000,
          corporateId: parseInt(sessionStorage.getItem("domainId")),
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        debugger;
        // setILTData(res.data);

        filteredData = res.data.filter((item) => {
          console.log("item.NM_ILT_CRS_ID : ", item.NM_ILT_CRS_ID);
          return item.NM_ILT_CRS_ID === requestItem.NM_ILT_ID;
        });

        if (filteredData.length > 0) {
          //Added by Saurav
          const sasToken = SAS_TOKEN;
          const containerName = CONTAINER_NAME;
          const storageAccountName = STORAGE_ACCOUNT_NAME;
          tempURL =
            "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
          if (
            filteredData.length > 0 &&
            filteredData[0].VC_UPLDD_FL_NM != null
          ) {
            tempURL =
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${filteredData[0].NM_ILT_ID}/${filteredData[0].VC_UPLDD_FL_NM}?` +
              sasToken;
          } else {
            tempURL =
              "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
          }
          navigate("/detailsML", {
            state: {
              course_id: filteredData[0].NM_ILT_CRS_ID,
              type: filteredData[0].NM_LRNNG_TYP,
              course_name: filteredData[0].VC_ILT_CRS_LNCH_NM,
              start_date: filteredData[0].DT_STRT_DT,
              end_date: filteredData[0].DT_END_DT,
              credit_hours: filteredData[0].NM_ILT_CRS_CRDT_HRS,
              progress: filteredData[0].NM_PRGRSS,
              description: filteredData[0].VC_ILT_CRS_LNCH_DSCRPTN,
              isAvailableForever: filteredData[0].BT_IS_AVLBL_FRVR,
              imgSrc: tempURL,
              VC_ILT_CD: filteredData[0].VC_ILT_CD,
              topic_name: filteredData[0].VC_ILT_CRS_LNCH_NM,
            },
          });
        } else {
          swal({
            title: "Error",
            text: "You're not enrolled in this Training. Kindly go to Available learning and Enroll.",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="bg-image">
        <Navbar />
        <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
          <div display="inline-block" style={{ marginLeft: "10px" }}>
            {/* {t("rbkey_ClssrmTrnngsLBL")} */}
            Please Select From Below Offers
          </div>
        </div>

        {/* {offerData?.map((item, index) => {
          return (
            <>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="200"
                    image="https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg"
                    alt=""
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.VC_CRTFCT_CRS_TTL} | {item.VC_ILT_CD}
                      
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                  
                </Typography> */}
        {/* </CardContent>
                </CardActionArea>
              </Card>
            </>
          );
        })} */}

        {/* <Container>
          <Row>
            <Col>
              {offerData[0]?.map((item) => {
                return item.VC_ILT_CD;
              })}
            </Col>
          </Row>
        </Container> */}
        <div style={{ margin: "30px"}}>
        <Slider {...settings} style={{ marginLeft: "2.5% !important" }}>
          {offerData?.map((item, index) => {
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
            let tempURL;
            if (item.VC_UPLDD_FL_NM != null) {
              tempURL =
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
                sasToken;
            } else {
              tempURL =
                "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
            }

            return (
              <div className="wrapper" key={index}>
                {/* <Link
                  className="link"
                  onClick={tileClickHandler}
                  to="/detailsML"
                  state={{
                    course_id: filteredData[0]?.NM_ILT_CRS_ID,
                    type: filteredData[0]?.NM_LRNNG_TYP,
                    course_name: filteredData[0]?.VC_ILT_CRS_LNCH_NM,
                    start_date: filteredData[0]?.DT_STRT_DT,
                    end_date: filteredData[0]?.DT_END_DT,
                    credit_hours: filteredData[0]?.NM_ILT_CRS_CRDT_HRS,
                    progress: filteredData[0]?.NM_PRGRSS,
                    description: filteredData[0]?.VC_ILT_CRS_LNCH_DSCRPTN,
                    isAvailableForever: filteredData[0]?.BT_IS_AVLBL_FRVR,
                    imgSrc: tempURL,
                    VC_ILT_CD: filteredData[0]?.VC_ILT_CD,
                    topic_name: filteredData[0]?.VC_ILT_CRS_LNCH_NM,
                  }}
                > */}
                <img
                  style={{ width: "100%", height: "125px" }}
                  onClick={() => tileClickHandler(item)}
                  src={tempURL}
                  alt=""
                />
                {/* </Link> */}
                <div className="title" style={{ display: "flex" }}>
                  <p title={item.VC_CRTFCT_CRS_TTL}>
                    {item.VC_CRTFCT_CRS_TTL?.substring(0, 11)}...
                  </p>{" "}
                  |{" "}
                  <p title={item.VC_ILT_CD}>
                    {item.VC_ILT_CD?.substring(0, 11)}...
                  </p>
                </div>
                {/* <p className="description">
                  {item.VC_DSCRPTN?.substring(0, 40)}
                  <Link
                    className="directlink"
                    to="/details"
                    state={{
                      course_id: item.NM_ILT_ID,
                      type: "Classroom Training",
                      course_name: item.VC_ILT_CRS_LNCH_NM,
                      start_date: item.DT_STRT_DT,
                      end_date: item.DT_END_DT,
                      credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                      progress: item.NM_PRGRSS,
                      description: item.VC_ILT_CRS_LNCH_DSCRPTN,
                      isAvailableForever: item.BT_IS_AVLBL_FRVR,
                      imgSrc: tempURL,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                    }}
                  ></Link>
                </p> */}
              </div>
            );
          })}
        </Slider>
        </div>
      </div>
    </div>
  );
};

export default OfferList;

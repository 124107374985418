import React, { useEffect, useState } from "react";
import "./Feedback.css";
// import { Col, Container, Row } from "react-bootstrap";
// import Box from "@mui/material/Box";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import feedbackImage from "../../Assets/Images/feedback.jpg";
import axios from "axios";
import {
  PUBLIC_URL,
  LMS_DEV,
  LMS_QA,
  PUBLIC_URL_API2,
} from "./../Common/Constants.js";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../Pages/NavBar";
import {
  STORAGE_ACCOUNT_NAME,
  CONTAINER_NAME,
  SAS_TOKEN,
} from "../Common/Constants.js";
import { withStyles } from "@material-ui/core/styles";
import MuiMenuItem from "@material-ui/core/MenuItem";

export const Feedback = () => {
  const [feedBackData, setfeedBackData] = useState();
  const [feedbackAnswerData, setfeedbackAnswerData] = useState();
  const [textboxValue, settextboxValue] = useState({});
  const [textareaValue, settextareaValue] = useState({});
  const [dropdownValue, setdropdownValue] = useState({});
  const [radioBtnValue, setradioBtnValue] = useState({});
  const [disbaledFlag, setdisbaledFlag] = useState(false);
  const query = new URLSearchParams(window.location.search);

  let answerDetails = [];
  let localFeedbackData;

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // console.log(query);
  // console.log(query.get("authCode"), "authcode");
  // console.log(query.get("username"), "username");
  // console.log(query.get("ILTId"), "ILTId");
  // console.log(query.get("UserId"), "UserId");

  if (query.get("authCode")) {
    const authCode = query.get("authCode");
    sessionStorage.setItem("authCode", authCode);
  }

  if (query.get("case")) {
    const case_1 = query.get("case");
    sessionStorage.setItem("case", case_1);
  }

  if (query.get("ILTId")) {
    const ILTId = query.get("ILTId");
    sessionStorage.setItem("ILTId", ILTId);
  }

  if (query.get("UserId")) {
    const UserId = query.get("UserId");
    sessionStorage.setItem("UserId", UserId);
  }

  if (query.get("username")) {
    const username = query.get("username");
    sessionStorage.setItem("username", username);
  }

  const MenuItem = withStyles({
    root: {
      justifyContent: "flex-start",
    },
  })(MuiMenuItem);

  useEffect(() => {
    axios
      .get(
        PUBLIC_URL_API2 + "/api/ClassRoomTraining/ILT/getFeedbackFormData",

        {
          params: {
            ILTId:
              sessionStorage.getItem("ILTId") == null
                ? location.state.iltId
                : sessionStorage.getItem("ILTId"),
            sessionId: 0,
          },
        }
      )
      .then((res) => {
        setfeedBackData(res.data);
        localFeedbackData = res.data;
      })

      .then(() => {
        axios
          .get(
            PUBLIC_URL_API2 +
              "/api/ClassRoomTraining/ILT/getFeedbackAnswerData",
            {
              params: {
                ILTId:
                  sessionStorage.getItem("ILTId") == null
                    ? location.state.iltId
                    : sessionStorage.getItem("ILTId"),
                UserId: sessionStorage.getItem("userId"),
                sessionId: 0,
              },
            }
          )
          .then((res) => {
            setfeedbackAnswerData(res.data);
            if (res.data[1] != undefined && res.data[1].length > 0) {
              setdisbaledFlag(true);
              let asnwerElement = [];
              res.data[1].forEach((element) => {
                asnwerElement = res.data[0].filter((item) => {
                  return item.NM_FLD_ID == element.NM_FLD_ID;
                });
                if (asnwerElement.length > 0) {
                  switch (asnwerElement[0].CH_FLD_TYP) {
                    case "T": {
                      settextboxValue((prev) => {
                        return {
                          ...prev,
                          [element.NM_FLD_ID]: element.VC_FLD_VL,
                        };
                      });
                      break;
                    }

                    case "D": {
                      debugger;
                      if (localFeedbackData[1] != undefined) {
                        let valueToBeSet = localFeedbackData[1].filter(
                          (subItem) => {
                            if (
                              element.VC_FLD_TXT == subItem.VC_FLD_VL &&
                              element.NM_FLD_ID == subItem.NM_FLD_ID
                            ) {
                              return subItem.NM_VL_ID;
                            }
                          }
                        );
                        setdropdownValue((prev) => {
                          return {
                            ...prev,
                            [element.NM_FLD_ID]: parseInt(
                              valueToBeSet[0].NM_VL_ID
                            ),
                          };
                        });
                      }

                      break;
                    }

                    case "A": {
                      settextareaValue((prev) => {
                        return {
                          ...prev,
                          [element.NM_FLD_ID]: element.VC_FLD_VL,
                        };
                      });
                      break;
                    }

                    case "R":
                      {
                        debugger;
                        if (localFeedbackData[1] != undefined) {
                          let valueToBeSet = localFeedbackData[1].filter(
                            (subItem) => {
                              if (
                                subItem.VC_FLD_TXT == element.VC_FLD_VL &&
                                subItem.NM_FLD_ID == element.NM_FLD_ID
                              ) {
                                return subItem.NM_VL_ID;
                              }
                            }
                          );
                          if (valueToBeSet[0].NM_VL_ID != undefined) {
                            setradioBtnValue((prev) => {
                              return {
                                ...prev,
                                [element.NM_FLD_ID]: valueToBeSet[0].NM_VL_ID,
                              };
                            });
                          } else {
                            setradioBtnValue({});
                          }
                        }
                      }
                      break;

                    default:
                      break;
                  }
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log("from feedback feedback data:", feedBackData);
    console.log("from feedback feedback answer data:", feedbackAnswerData);
  }, [feedBackData, feedbackAnswerData]);

  useEffect(() => {
    console.log("DROPDOWN : ", dropdownValue);
    console.log("RADIO BTN VALUE : ", radioBtnValue);
  }, [dropdownValue, radioBtnValue]);

  const saveClickHandler = () => {
    debugger;
    let instructorId;
    if (
      feedBackData[3][0].NM_INSTRCTR_ID != undefined &&
      feedBackData[3][0].NM_INSTRCTR_ID > 0
    ) {
      instructorId = feedBackData[3][0].NM_INSTRCTR_ID;
    }
    for (let i = 0; i < feedBackData[0].length; i++) {
      if (feedBackData[0][i].CH_FLD_TYP == "T") {
        answerDetails.push({
          FieldId: feedBackData[0][i].NM_FLD_ID,
          InstructorId: instructorId,
          FieldValue: textboxValue[feedBackData[0][i].NM_FLD_ID],
        });
      } else if (feedBackData[0][i].CH_FLD_TYP == "D") {
        answerDetails.push({
          FieldId: feedBackData[0][i].NM_FLD_ID,
          InstructorId: instructorId,
          FieldValue: dropdownValue[feedBackData[0][i].NM_FLD_ID].toString(),
        });
      } else if (feedBackData[0][i].CH_FLD_TYP == "A") {
        answerDetails.push({
          FieldId: feedBackData[0][i].NM_FLD_ID,
          InstructorId: instructorId,
          FieldValue: textareaValue[feedBackData[0][i].NM_FLD_ID],
        });
      } else if (feedBackData[0][i].CH_FLD_TYP == "R") {
        answerDetails.push({
          FieldId: feedBackData[0][i].NM_FLD_ID,
          InstructorId: instructorId,
          FieldValue: radioBtnValue[feedBackData[0][i].NM_FLD_ID].toString(),
        });
      }
    }

    console.log("REQUEST BODY FROM SAVE BTN: ", answerDetails);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    axios({
      method: "post",
      url: PUBLIC_URL + "/api/ClassRoomTraining/ILT/saveFeedbackFormData",
      headers: headers,
      data: {
        UserId: parseInt(sessionStorage.getItem("userId")),
        ILTId:
          sessionStorage.getItem("ILTId") == null
            ? location.state.iltId
            : sessionStorage.getItem("ILTId"),
        FormId: feedBackData[0][0].NM_FRM_ID,
        SessionId: 0,
        answerDetails,
      },
    }).then(
      (response) => {
        navigate("/mylearning");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fieldChangeHandler = (e) => {
    debugger;
    if (e != undefined) {
      let inputType = e.target.id.split("_");
      let id = inputType[1];
      switch (inputType[0]) {
        case "textbox": {
          settextboxValue((prev) => {
            return { ...prev, [id]: e.target.value };
          });
          break;
        }
        case "textarea": {
          settextareaValue((prev) => {
            return { ...prev, [id]: e.target.value };
          });
          break;
        }
        case "radio": {
          break;
        }

        default:
          break;
      }
    }
  };

  const fieldSelecter = (item) => {
    debugger;
    if (item != undefined) {
      switch (item.CH_FLD_TYP) {
        case "T": {
          return (
            <div>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id={`textbox_${item.NM_FLD_ID}`}
                  label=""
                  value={textboxValue[item.NM_FLD_ID]}
                  onChange={fieldChangeHandler}
                  variant="outlined"
                  style={{ width: "415px" }}
                  disabled={disbaledFlag}
                />
              </Box>
            </div>
          );
          break;
        }
        case "D": {
          // code block
          return (
            <div>
              <Box sx={{ minWidth: 120, maxWidth: "50%" }}>
                <FormControl fullWidth>
                  {!disbaledFlag ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id={`dropdown_${item.NM_FLD_ID}`}
                      value={dropdownValue[item.NM_FLD_ID]}
                      onChange={(e) => {
                        setdropdownValue((prev) => {
                          return { ...prev, [item.NM_FLD_ID]: e.target.value };
                        });
                      }}
                      disabled={disbaledFlag}
                    >
                      {feedBackData[1].map((dropdownOption) => {
                        return (
                          dropdownOption.NM_FLD_ID == item.NM_FLD_ID && (
                            <MenuItem
                              value={dropdownOption.NM_VL_ID}
                              selected={
                                disbaledFlag &&
                                dropdownValue == dropdownOption.NM_VL_ID
                                  ? true
                                  : false
                              }
                            >
                              {dropdownOption.VC_FLD_TXT}
                            </MenuItem>
                          )
                        );
                      })}
                    </Select>
                  ) : (
                    <>
                      {feedbackAnswerData[1].map((answer) => {
                        return (
                          answer.NM_FLD_ID == item.NM_FLD_ID && (
                            <label>{answer.VC_FLD_VL}</label>
                          )
                        );
                      })}
                    </>
                  )}
                </FormControl>
              </Box>
            </div>
          );
          break;
        }
        case "A": {
          // code block
          return (
            <div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <textarea
                    id={`textarea_${item.NM_FLD_ID}`}
                    name="textArea"
                    rows="4"
                    cols="50"
                    value={textareaValue[item.NM_FLD_ID]}
                    onChange={fieldChangeHandler}
                    disabled={disbaledFlag}
                  ></textarea>
                </div>
              </Box>
            </div>
          );
          break;
        }
        case "R": {
          // code block
          return (
            <div>
              <FormControl>
                {!disbaledFlag ? (
                  <div>
                    {feedBackData[1].map((dropdownOption) => {
                      return (
                        dropdownOption.NM_FLD_ID == item.NM_FLD_ID && (
                          <div>
                            <input
                              type="radio"
                              id={`radio_${item.NM_FLD_ID}`}
                              name={`radio_${item.NM_FLD_ID}`}
                              value={radioBtnValue}
                              defaultChecked={
                                disbaledFlag &&
                                radioBtnValue ==
                                  dropdownOption.NM_VL_ID.toString()
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                debugger;
                                setradioBtnValue((prev) => {
                                  return {
                                    ...prev,
                                    [item.NM_FLD_ID]: dropdownOption.NM_VL_ID,
                                  };
                                });
                              }}
                            />
                            <label htmlFor={`radio_${item.NM_FLD_ID}`}>
                              {dropdownOption.VC_FLD_TXT}
                            </label>
                          </div>
                        )
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {feedbackAnswerData[1].map((answer) => {
                      return (
                        answer.NM_FLD_ID == item.NM_FLD_ID && (
                          <label>{answer.VC_FLD_VL}</label>
                        )
                      );
                    })}
                  </>
                )}
              </FormControl>
            </div>
          );
          break;
        }
        default:
          // code block
          return null;
      }
    }
  };

  const backClickHandler = () => {
    navigate("/mylearning");
  };

  return (
    <div>
      <Navbar />

      <div className="grid-container" style={{ gridTemplateColumns: "3fr" }}>
        <div className="grid-item">
          <img
            style={{ width: "25%", height: "10%" }}
            src={feedbackImage}
            alt="feedbackImage"
          />
        </div>
        <div className="grid-item">
          <div>
            <span>
              {t("rbkey_ClssrmTrnngNmLBL")} :{" "}
              {feedBackData == undefined ? "NA" : feedBackData[0][0].VC_ILT_NM}
            </span>
          </div>

          <div>
            <span>
              {t("rbkey_LgnCdBrcktUsrNmLBL")} :{" "}
              {`${sessionStorage.getItem(
                "userFirstName"
              )} ${sessionStorage.getItem("userLastName")}`}
            </span>
          </div>
        </div>
      </div>

      <div className="grid-container" style={{ gridTemplateColumns: "3fr" }}>
        <div className="grid-item"></div>
        <div className="grid-item">
          {feedBackData == undefined
            ? null
            : feedBackData[0]?.map((item) => {
                return (
                  <div
                    className="grid-container"
                    style={{ gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div className="grid-item">
                      <span>{item?.VC_FLD_LBL}</span>
                    </div>
                    <div className="grid-item-type">{fieldSelecter(item)}</div>
                  </div>
                );
              })}
        </div>
      </div>

      <div
        className="grid-container"
        style={{ gridTemplateColumns: "4fr 1fr 4fr" }}
      >
        <div className="grid-item"></div>
        <div className="grid-item">
          {!disbaledFlag ? (
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={saveClickHandler}>
              {t("rbkey_Sbmt")}
              </Button>
            </Stack>
          ) : (
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={backClickHandler}>
                Done
              </Button>
            </Stack>
          )}
        </div>
        <div className="grid-item">
          {!disbaledFlag && (
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={backClickHandler}>
                Cancel
              </Button>
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

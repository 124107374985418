import React from "react";
import { UnderConstruction } from "../../Pages/UnderConstruction";
import Navbar from "../../Pages/NavBar.jsx";
import truck from "../../Assets/Images/truck.png";

export const Dashboard = () => {
  return (
    <div
    style={{
      width: "100%",
      backgroundImage: `url(${truck})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh",
      backgroundfilter: "blur(5px)",
    }}
    >
      <Navbar/>
      {/* <div style={{align: "centre",paddingTop:"50px"}}> */}
      <UnderConstruction/>
      {/* </div> */}
    </div>
  );
};

import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { useNavigate, useLocation } from "react-router-dom";
import "./Certificatefeature.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import logo from "../../Assets/Images/FusoSifnImg5.png";
import axios from "axios";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "../../Components/Common/Constants.js";

const Certificatefeature = () => {
  const location = useLocation();
  const ref = React.createRef();
  const [certificateDetails, setCertificateDetails] = useState([]);
  const [fileNm, setFileNm] = useState("");
  //const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // content: () => componentRef.current,
    content: () => ref.current,
  });
  const backgroundID = location.state.certificate_id;

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/GetCertificateDetails", {
        params: {
          certificateId: location.state.certificate_id,
          courselaunchId: location.state.course_id,
          userID: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        setCertificateDetails(res.data);
        setFileNm(res.data?.Table[0]?.VC_FL_NM);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const sasToken = SAS_TOKEN;
  const containerName = CONTAINER_NAME;
  const storageAccountName = STORAGE_ACCOUNT_NAME;
  let imgURL;
  imgURL =
    `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCertificateDirectory/${backgroundID}/${fileNm}?` +
    sasToken;
  console.log(imgURL);

  return (
    <>
      <div style={{ alignItems: "center", paddingLeft: "20%" }}>
        <Card
          sx={{
            alignItems: "center",
            width: "75%",
            height: "fit-content",
            paddingLeft: "12.5%",
            paddingRight: "12.5%",
          }}
          ref={ref}
        >
          {/* <CardMedia
            sx={{ height: 500 }}
            image={imgURL}
            title="certificate"
          /> */}
          <img
            style={{ width: "100%", height: "50%", paddingTop: "5%" }}
            src={imgURL}
            alt=""
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h5" component="div">
              Certificate
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Name: {location.state.VC_USR_NM}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Course Name: {location.state.VC_ILT_CRS_LNCH_NM}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Completion Date: {location.state.DT_CMPLTN_DT.substring(0, 10)}
            </Typography>
          </CardContent>
          {/* <CardActions></CardActions> */}
        </Card>
      </div>
      <div style={{ marginLeft: "36%" }}>
        {location.state.certificate_prnt_flg ? (
          <Button
            //className="buttonType"
            onClick={handlePrint}
            style={{ marginRight: 30, marginLeft: "10%", marginTop: 10 }}
            variant="contained"
          >
            Print
          </Button>
        ) : (
          <Button
            //className="buttonType"
            onClick={handlePrint}
            style={{ marginRight: 30, marginLeft: "10%", marginTop: 10 }}
            variant="contained"
            disabled
          >
            Print
          </Button>
        )}

        {/* <Button variant="contained">Contained</Button> */}
        <Pdf targetRef={ref} filename="certificate.pdf">
          {({ toPdf }) => (
            <Button
              //className="buttonType"
              onClick={toPdf}
              style={{ marginRight: 30, marginLeft: 10, marginTop: 10 }}
              variant="contained"
            >
              Download
            </Button>
          )}
        </Pdf>
      </div>
    </>
  );
};

export default Certificatefeature;

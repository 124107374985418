import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import Feedback from "./Feedback.jsx";
import { Audio } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

export const SubmitAssessment = (props) => {
  const [showFinalResults, setFinalResults] = useState(false);
  // const [assessmentReport, setAssessmentReport] = useState([]);
  const { t } = useTranslation();
  const [resultMsg, setResultMsg] = useState(
    t("rbkey_PssdMsg")
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState(false);
  const setResult = React.useRef(null);
  const assessmentReport = React.useRef(null);
  const assessmentReport1 = React.useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  function onClickSubmit() {
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SubmitAssessment",

      data: {
        courseLaunchId: location.state.course_id,
        nodeId: location.state.node_id,
        userId: sessionStorage.getItem("userId"),
      },
    })
      .then((response) => {
        updateScore();
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "post",
      url: PUBLIC_URL + "/api/Program/MyLearning/P/TrackCourse",
      data: {
        courseLaunchId: location.state.course_id,
        nodeId: parseInt(`${location.state.node_id}`),
        userId: sessionStorage.getItem("userId"),
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
    setFinalResults(true);
  }

  function updateScore() {
    setIsLoaded(false);
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssessmentReport", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        assessmentReport.current = res.data.Table;
        assessmentReport1.current = res.data.Table1;
        console.log(res.data);
        console.log(res.data.Table);
        console.log(res.data.Table1);
        console.log(res.data.Table[1]);
        console.log(res.data.Table1[0].FL_TTL_MRKS);
        if (res.data.length !== 0 && res.data !== undefined) {
          if (
            res.data.Table.length > 0 &&
            res.data.Table[0].FL_MRKS_OBTND !== null &&
            res.data.Table[0].FL_MRKS_OBTND !== undefined &&
            res.data.Table[0].FL_TTL_MRKS !== null &&
            res.data.Table[0].FL_TTL_MRKS !== undefined
          ) {
            const obtainedMrks =
              (res.data.Table[0].FL_MRKS_OBTND /
                res.data.Table[0].FL_TTL_MRKS) *
              100;
            if (
              res.data.Table[0].length !== 0 &&
              res.data.Table[0].NM_MN_PSSNG_MRKS === null
            ) {
              setResultMsg(t("rbkey_PssdMsg"));
            } else if (
              res.data.Table[0].length !== 0 &&
              obtainedMrks < res.data.Table[0].NM_MN_PSSNG_MRKS && res.data.Table[0].BT_IS_ST_CMPLTD_WHN_PSSD == true
            ) {
              setResultMsg(t("rbkey_FldMsg"));
            } else {
              setResultMsg(t("rbkey_PssdMsg"));
            }
          } else if (
            res.data.Table1.length > 0 &&
            res.data.Table1[0].FL_MRKS_OBTND !== null &&
            res.data.Table1[0].FL_MRKS_OBTND !== undefined &&
            res.data.Table1[0].FL_TTL_MRKS !== null &&
            res.data.Table1[0].FL_TTL_MRKS !== undefined
          ) {
            const obtainedMrks =
              (res.data.Table1[0].FL_MRKS_OBTND /
                res.data.Table1[0].FL_TTL_MRKS) *
              100;

            if (
              res.data.Table1[0].length !== 0 &&
              res.data.Table1[0].NM_MN_PSSNG_MRKS !== null
            ) {
              setResultMsg(t("rbkey_PssdMsg"));
            } else if (
              res.data.Table1[0].length !== 0 &&
              res.data.Table1[0].NM_MN_PSSNG_MRKS !== undefined &&
              res.data.Table1[0].NM_MN_PSSNG_MRKS !== null &&
              obtainedMrks < res.data.Table1[0].NM_MN_PSSNG_MRKS
              && res.data.Table[0].BT_IS_ST_CMPLTD_WHN_PSSD == true
            ) {
              setResultMsg(t("rbkey_FldMsg"));
            } else {
              setResultMsg(t("rbkey_PssdMsg"));
            }

            if (res.data.Table[0].NM_MN_PSSNG_MRKS === null) {
              setResultMsg(t("rbkey_PssdMsg"));
            }
          } else {
            setResultMsg(t("rbkey_PssdMsg"));
          }
        }
        setIsLoaded(true);
        console.log(isLoaded);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }
  function onClickDone() {
    navigate("/detailsML", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
        node_id: location.state.node_id,
        certificateID: location.state.certificateID,
        DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
      },
    });
  }

  const getFeedbackDet = async () => {
    await axios({
      method: "post",
      url:
        PUBLIC_URL +
        "/api/ELearning/GetFeedbackPreviewRequired?CourseLaunchId=" +
        location.state.course_id +
        "&NodeId=" +
        location.state.node_id,
      data: {
        courseLaunchId: location.state.course_id,
        nodeId: location.state.node_id,
      },
    })
      .then((res) => {
        if (res.data.Table !== null && res.data.Table !== undefined) {
          setResult.current = res.data.Table;
          setShowFeedback(setResult?.current[0]?.BT_ASSSSMNT_RPRT_RQRD);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getFeedbackDet();
  }, [location.state.course_id]);

  function onClickCancel() {
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id: location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  }
  return (
    <div>
      {showFinalResults && showFeedback && <Feedback />}

      {showFinalResults && !showFeedback && isLoaded && (
        <>
          {" "}
          <div style={{ textAlign: "center" }}>
            <div style={{ textAlign: "center", padding: "2%" }}>
              {resultMsg !== null && resultMsg !== undefined && resultMsg}
            </div>
            <div style={{ textAlign: "center", padding: "2%" }}>
              Result : {
                assessmentReport.current[0]?.FL_AGGRGT_MRKS_OBTND == null
                ? assessmentReport1.current[0]?.FL_AGGRGT_MRKS_OBTND == null
                  ? "0"
                  : assessmentReport1.current[0]?.FL_AGGRGT_MRKS_OBTND
                : assessmentReport.current[0]?.FL_AGGRGT_MRKS_OBTND
              } %
              {/* Total Marks :{" "}
              {assessmentReport.current[0]?.FL_MRKS_OBTND == null
                ? assessmentReport1.current[0]?.FL_MRKS_OBTND == null
                  ? "0"
                  : assessmentReport1.current[0]?.FL_MRKS_OBTND
                : assessmentReport.current[0]?.FL_MRKS_OBTND}{" "}
              /{" "}
              {assessmentReport.current[0] !== null &&
              assessmentReport.current[0] !== undefined
                ? assessmentReport.current[0]?.FL_TTL_MRKS
                : assessmentReport1.current[0]?.FL_MRKS_OBTND !== null &&
                  assessmentReport1.current[0]?.FL_MRKS_OBTND !== undefined
                ? assessmentReport1.current[0]?.FL_MRKS_OBTND
                : "0"} */}
            </div>
            <Button variant="contained" onClick={onClickDone}>
              Done
            </Button>
          </div>
        </>
      )}

      {showFinalResults && !showFeedback && !isLoaded && (
        <>
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="50" width="65" color="grey" arialLabel="Loading" />
          </div>
        </>
      )}

      {!showFinalResults && (
        <div>
          <div style={{ textAlign: "center", padding: "2%" }}>
            You are about to submit the assessment.Continue?
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ marginRight: "2px" }}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onClickSubmit}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default SubmitAssessment;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import truck from "../../Assets/Images/truck.png";
import Navbar from "../../Pages/NavBar.jsx";
import axios from "axios";
import "./ML_Videochannel.css";
import { TfiAngleDoubleDown, TfiAngleDoubleUp } from "react-icons/tfi";
import { Audio } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import NotFound from "../../Assets/Components/NotFound";
import feedbackIcon from "../../Assets/Images/feedbackIcon.jpg";
import { useNavigate } from "react-router-dom";

export const ML_All_ClassroomTraining = (props) => {
  var nominationStatus;
  const { searchField } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);

  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [postsData, setPostsData] = useState([]);
  const [searchValue, setSearchValue] = useState([]);

  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [searchValueOnEnter, setSearchValueOnEnter] = useState([]);
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  const filteredPosts = posts.filter((item, key) => {
    return (
      item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      ) ||
      item.VC_ILT_CD?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      )
    );
  });

  const pageCount = Math.ceil(filteredPosts.length / 16);

  useEffect(() => {
    if (page > pageCount && pageCount) {
      setPage(pageCount);
    }
  }, [pageCount, page]);

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 200,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
        setPostsData(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const feedbackClickHandler = (e) => {
    navigate("/feedback", {
      state: {
        iltId: e.target.id,
      },
    });
  };

  
  if (posts.length === 0 && !loading) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  } else if (posts.length === 0 && loading) {
    <NotFound />;
  }
  const courses = [];
  const courseList = posts.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    if (typeof course.VC_ILT_CD === "string") {
      courses.push(course.VC_ILT_CD);
    }
  });
  function sortName() {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 200,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function sortDate() {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 200,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(res.data);
        setPostsData(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClickShowMore() {
    setShowMore(!showMore);
  }
  const handleKeyDown = (e, value) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
    } else if (e.key === "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setcurrentID("");
    SearchItemData(value);
  };

  function SearchItemData(searchtext) {}
  const handleEnterKey = (event, value) => {
    if (event.key === "Enter") {
      setSearchValue(searchValueOnEnter);
      SearchItemData(searchValue);
    }
  };

  return (
    <div className="bg-image">
      <Navbar />
      <Link className="backButton" to="/mylearning">
        {t("rbkey_BckLBL")}
      </Link>
      <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
        <div display="inline-block" style={{ marginLeft: "10px" }}>
          <p title={t("rbkey_ILTMSG")}>{t("rbkey_ClssrmTrnngsLBL")}</p>
        </div>
        <div
          display="inline-block"
          style={{
            marginLeft: "15%",
            color: "white",
            marginRight: "5px",
            marginTop: "0px",
          }}
        >
          <Dropdown className="dropdown" size="sm">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="dropdown"
              size="sm"
            >
              {" "}
              {t("rbkey_SrtByLBL")}{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={sortName}>
                {" "}
                {t("rbkey_NmLBL")}
              </Dropdown.Item>
              <Dropdown.Item onClick={sortDate}>
                {t("rbkey_DtLBL")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Stack
          spacing={2}
          width="250px"
          style={{ marginLeft: "350px", marginTop: "-1px" }} //Added by Saurav
        >
          <input
            placeholder="Search"
            value={searchValueOnEnter}
            onKeyDown={handleEnterKey}
            onChange={(e) => setSearchValueOnEnter(e.target.value)}
          />
        </Stack>

        <div
          display="inline-block"
          style={{
            fontSize: "small",
            fontWeight: "100",
            marginRight: "0",
            marginLeft: "25px",
          }}
        >
          {`${t("rbkey_PgLBL")} ${Math.min(
            page,
            Math.ceil(filteredPosts.length / 16)
          )} of ${pageCount}`}
        </div>
      </div>
      {!filteredPosts.length && <NotFound />}
      {showMore ? (
        <div>
          <div className="tileWrap">
            {posts
              .filter((item, key) => {
                return (
                  item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  ) ||
                  item.VC_ILT_CD?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  )
                );
              })
              .slice(16 * page - 16, 16 * page)
              .map((item, index) => {
                if (item.NMNTN_STTS === "C") {
                  nominationStatus = "Completed";
                } else if (item.NMNTN_STTS === "P") {
                  nominationStatus = "Pending";
                } else if (item.NMNTN_STTS === "S") {
                  nominationStatus = "Started";
                } else {
                  nominationStatus = "Pending";
                }
                //
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
                let tempURL;
                if (item.VC_UPLDD_FL_NM != null) {
                  tempURL =
                    `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
                    sasToken;
                } else {
                  tempURL =
                    "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
                }
                //
                let stringForDescription = removeTags(
                  item.VC_ILT_CRS_LNCH_DSCRPTN
                );
                //
                return (
                  <div className="container" key={index}>
                    <Link
                      className="link"
                      to="/detailsML"
                      state={{
                        course_id: item.NM_ILT_CRS_ID,
                        type: item.NM_LRNNG_TYP,
                        description: stringForDescription,
                        certificateID: item.NM_CRTFCT_ID,
                        imgSrc: tempURL,
                        VC_ILT_CD: item.VC_ILT_CD,
                        topic_name: item.VC_ILT_KND_NM,
                        iltId: item.NM_ILT_CRS_ID,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "125px",
                          objectFit: "cover",
                        }}
                        src={tempURL}
                        alt=""
                      />
                    </Link>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        marginBottom: 0,
                      }}
                    >
                      <ProgressBar
                        now={item.NM_PRGRSS}
                        label={`${item.NM_PRGRSS}%`}
                        className="progressBar"
                      />
                    </p>

                    {item.BT_FDBCK_RQRD &&
                      item.BT_FDBCK_ILT_LVL &&
                      !item.BT_BLNDD &&
                      item.CH_ILT_STTS == "X" &&
                      item.NM_FRM_ID != undefined &&
                      item.NM_FRM_ID > 0 && (
                        <img
                          id={item.NM_ILT_CRS_ID}
                          src={feedbackIcon}
                          alt="feedback"
                          style={{
                            height: "20px",
                            width: "20px",
                            margin: "6px 0 0 1px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            feedbackClickHandler(e);
                          }}
                        />
                      )}

                    {/* <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 13)}...
                      </p>{" "}
                      |{" "}
                      <p title={item.VC_ILT_CD}>
                        {item.VC_ILT_CD?.substring(0, 13)}...
                      </p>
                    </div> */}
                    <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                        {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                      </p>{" "}
                    </div>
                    <p className="description">
                      {stringForDescription.toString().substring(0, 40)}
                      <Link
                        className="directlink"
                        to="/detailsML"
                        state={{
                          course_id: item.NM_ILT_CRS_ID,
                          type: item.NM_LRNNG_TYP,
                          description: stringForDescription,
                          certificateID: item.NM_CRTFCT_ID,
                          imgSrc: tempURL,
                          VC_ILT_CD: item.VC_ILT_CD,
                          topic_name: item.VC_ILT_KND_NM,
                          iltId: item.NM_ILT_CRS_ID,
                        }}
                      >
                        ...
                        {t("rbkey_Showmore_LBL")}
                      </Link>
                    </p>
                  </div>
                );
              })}
          </div>
          {!!filteredPosts.length && (
            <div className="paginationCntr">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={Math.min(page, Math.ceil(filteredPosts.length / 16))}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Stack>
            </div>
          )}
        </div>
      ) : (
        <div className="tileWrap">
          {posts
            .filter((item, key) => {
              return (
                item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                ) ||
                item.VC_ILT_CD?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                )
              );
            })
            .slice(0, 8)
            .map((item, index) => {
              if (item.NMNTN_STTS === "C") {
                nominationStatus = "Completed";
              } else if (item.NMNTN_STTS === "P") {
                nominationStatus = "Pending";
              } else if (item.NMNTN_STTS === "S") {
                nominationStatus = "Started";
              } else {
                nominationStatus = "Pending";
              }
              //
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
              let tempURL;
              if (item.VC_UPLDD_FL_NM != null) {
                tempURL =
                  `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
                  sasToken;
              } else {
                tempURL =
                  "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
              }
              //
              let stringForDescription = removeTags(
                item.VC_ILT_CRS_LNCH_DSCRPTN
              );
              //
              return (
                <div className="container" key={index}>
                  <Link
                    className="link"
                    to="/detailsML"
                    state={{
                      course_id: item.NM_ILT_CRS_ID,
                      type: item.NM_LRNNG_TYP,
                      description: stringForDescription,
                      certificateID: item.NM_CRTFCT_ID,
                      imgSrc: tempURL,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                      iltId: item.NM_ILT_CRS_ID,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "125px",
                        objectFit: "cover",
                      }}
                      src={tempURL}
                      alt=""
                    />
                  </Link>

                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "smaller",
                      marginBottom: 0,
                    }}
                  >
                    <ProgressBar
                      now={item.NM_PRGRSS}
                      label={`${item.NM_PRGRSS}%`}
                      className="progressBar"
                    />
                  </p>

                  {item.BT_FDBCK_RQRD &&
                    item.BT_FDBCK_ILT_LVL &&
                    !item.BT_BLNDD &&
                    item.CH_ILT_STTS == "X" &&
                    item.NM_FRM_ID != undefined &&
                    item.NM_FRM_ID > 0 && (
                      <img
                        id={item.NM_ILT_CRS_ID}
                        src={feedbackIcon}
                        alt="feedback"
                        style={{
                          height: "20px",
                          width: "20px",
                          margin: "6px 0 0 1px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          feedbackClickHandler(e);
                        }}
                      />
                    )}

                  {/* <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 13)}...
                    </p>{" "}
                    |{" "}
                    <p title={item.VC_ILT_CD}>
                      {item.VC_ILT_CD?.substring(0, 13)}...
                    </p>
                  </div> */}
                  <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                    </p>{" "}
                  </div>
                  <p className="description">
                    {stringForDescription.toString().substring(0, 40)}
                    <Link
                      className="directlink"
                      to="/detailsML"
                      state={{
                        course_id: item.NM_ILT_CRS_ID,
                        type: item.NM_LRNNG_TYP,
                        description: stringForDescription,
                        certificateID: item.NM_CRTFCT_ID,
                        imgSrc: tempURL,
                        VC_ILT_CD: item.VC_ILT_CD,
                        topic_name: item.VC_ILT_KND_NM,
                        iltId: item.NM_ILT_CRS_ID,
                      }}
                    >
                      ...
                      {t("rbkey_Showmore_LBL")}
                    </Link>
                  </p>
                </div>
              );
            })}
        </div>
      )}
      {!!filteredPosts.length && (
        <div
          style={{
            textAlign: "center",
            paddingRight: "5px",
            borderRadius: "5px",
            paddingBottom: "20px",
          }}
        >
          <button className="showMore" onClick={handleClickShowMore}>
            {showMore ? (
              <div className="showMore">
                {t("rbkey_showless_LBL")}
                <TfiAngleDoubleUp />
              </div>
            ) : (
              <div className="showMore">
                {t("rbkey_Showmore_LBL")}
                <TfiAngleDoubleDown />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

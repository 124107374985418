import "./Assignment.scss";
import "../../Common/Common.css";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { useRef } from "react";
import { Audio } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import { CAlert } from "@coreui/react";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
} from "./../../Common/Constants.js";

const Assignment = (props) => {
  const [ELdata, setELdata] = useState();
  const [ProgramData, setProgramData] = useState();
  const [notes, setnotes] = useState();
  const [selectedFileAsString, setselectedFileAsString] = useState("");
  const [assignment_id, setassignment_id] = useState();
  const [errorMessage, seterrorMessage] = useState();
  const [selectedFile, setselectedFile] = useState(null);
  const [selectedFileName, setselectedFileName] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [contentURL, setcontentURL] = useState("");
  const [isFileAlreadyThere, setisFileAlreadyThere] = useState(false);
  const [AssignmentRemainingData, setAssignmentRemainingData] = useState();
  const [existingFileURL, setexistingFileURL] = useState();
  const [popupVisible, setpopupVisible] = useState(false);
  const [errorMessageColor, seterrorMessageColor] = useState();
  const [isSubmitVisible, setIsSubmitVisible] = useState(true);
  const [isLoaded, setisLoaded] = useState(false);
  const aRef = useRef(null);
  const sasToken = SAS_TOKEN;
  // "sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-10-19T21:54:09Z&st=2023-03-10T13:54:09Z&spr=https&sig=ewC1192AQzJwNJt2O8jqr5Rg3%2Fu%2FggqmbFVea7LdVsk%3D";
  const containerName = CONTAINER_NAME;
  const storageAccountName = STORAGE_ACCOUNT_NAME;

  const getELDetails = async () => {
    await axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssignment", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setELdata(res.data);
        setassignment_id(res.data?.Table[0]?.NM_CNTNT_ID);
        console.log(res.data?.Table[0]?.NM_CNTNT_ID);

        let assnmntId = res.data?.Table[0]?.NM_CNTNT_ID;
        axios
          .get(
            PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssignmentAnsDetail",
            {
              params: {
                courseLaunchId: location.state.course_id,
                nodeId: location.state.node_id,
                userId: sessionStorage.getItem("userId"),
              },
            }
          )
          .then((res) => {
            debugger;
            if (res.data.Table !== undefined) {
              setnotes(res.data.Table[0]?.VC_USR_NTS);
              setAssignmentRemainingData(res.data);
              if (res.data.Table.length > 0) {
                setIsSubmitVisible(false);
              }
              if (res.data !== undefined) {
                let tempURL = `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRAssignmentDirectory/${assnmntId}/${
                  location.state.course_id
                }/${location.state.node_id}/${sessionStorage.getItem(
                  "userId"
                )}/${res.data?.Table[0]?.VC_ASSGNMNT_FL_NM}?${sasToken}`;
                setexistingFileURL(tempURL);
                console.log(assignment_id);
                console.log(assnmntId, "res.data?.Table[0]?.NM_CNTNT_ID");
                console.log(tempURL);
                console.log(existingFileURL);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProgramDetails = async () => {
    await axios
      .get(PUBLIC_URL + "/api/Program/MyLearning/P/GetAssignment", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        setProgramData(res.data);
        setassignment_id(res.data?.Table[0]?.NM_CNTNT_ID);
        console.log(res.data?.Table[0]?.NM_CNTNT_ID);
        axios
          .get(
            PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssignmentAnsDetail",
            {
              params: {
                courseLaunchId: location.state.course_id,
                nodeId: location.state.node_id,
                userId: sessionStorage.getItem("userId"),
              },
            }
          )
          .then((res) => {
            setAssignmentRemainingData(res.data);
            setnotes(res.data.Table[0]?.VC_USR_NTS);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (location.state.type === "eLearning") {
      getELDetails();
    } else if (location.state.type === "Program") {
      getProgramDetails();
    }
  }, []);

  useEffect(() => {
    if (
      AssignmentRemainingData !== undefined &&
      AssignmentRemainingData?.Table?.VC_ASSGNMNT_FL_NM !== null &&
      AssignmentRemainingData?.Table?.VC_ASSGNMNT_FL_NM !== ""
    ) {
      setisFileAlreadyThere(true);
    } else {
      setisFileAlreadyThere(false);
    }
  }, [AssignmentRemainingData]);

  const uploadFileToBLob = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile);
    formData.append("FileName", selectedFileName);
    formData.append(
      "UploadPath",
      "/WCR/WCRAssignmentDirectory/" +
        assignment_id +
        "/" +
        `${location.state.course_id}` +
        "/" +
        `${location.state.node_id}` +
        "/" +
        `${sessionStorage.getItem("userId")}` +
        "/"
    );
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    try {
      const res = await axios.post(
        PUBLIC_URL + "/api/ELearning/MyLearning/EL/UploadFileToBlob",
        // "https://localhost:44317/api/ELearning/MyLearning/EL/UploadFileToBlob",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    if (notes == "" || selectedFile == null) {
      seterrorMessage(
        "Please select a valid file and enter comment in the notes"
      );
      seterrorMessageColor("danger");
      setpopupVisible(true);
      return false;
    } else {
      setpopupVisible(true);
      seterrorMessage("success");
      seterrorMessage("File is uploaded");
      uploadFileToBLob();
      if (location.state.type === "eLearning") {
        axios
          .post(PUBLIC_URL + "/api/ELearning/MyLearning/EL/SubmitAssignment", {
            courseLaunchId: parseInt(`${location.state.course_id}`),
            userId: sessionStorage.getItem("userId"),
            nodeId: parseInt(`${location.state.node_id}`),
            notes: notes,
            uploadedFile: selectedFileName,
            fileData: selectedFileAsString,
          })
          .then(function (response) {})
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            PUBLIC_URL + "/api/Program/MyLearning/P/SubmitAssignment",
            {
              courseLaunchId: parseInt(location.state.course_id),
              userId: parseInt(sessionStorage.getItem("userId")),
              nodeId: parseInt(location.state.node_id),
              notes: notes,
              uploadedFile: selectedFileName,
              fileData: selectedFileAsString,
            }
            // {
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   },
            // }
          )
          .then(function (response) {})
          .catch(function (error) {
            console.log(error);
          });
      }

      //added method to update the progress bar
      axios({
        method: "post",
        url: PUBLIC_URL + "/api/Program/MyLearning/P/TrackCourse",
        data: {
          courseLaunchId: location.state.course_id,
          nodeId: parseInt(`${location.state.node_id}`),
          userId: sessionStorage.getItem("userId"),
        },
      }).then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
      navigate("/detailsML", {
        state: {
          course_id: location.state.course_id,
          type: location.state.type,
          course_name: location.state.course_name,
          start_date: location.state.start_date,
          end_date: location.state.end_date,
          credit_hours: location.state.credit_hours,
          progress: location.state.progress,
          description: location.state.description,
          node_id: location.state.node_id,
          VC_ILT_CD: location.state.VC_ILT_CD,
          imgSrc: location.state.imgSrc,
          topic_name: location.state.topic_name,
          certificateID: location.state.certificateID,
          DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
        },
      });
      return true;
    }
  };

  const handleFileChange = (e) => {
    const regex = /^[A-Za-z0-9.]+$/;
    if (!regex.test(e.target.files[0].name)) {
      seterrorMessage(
        "Please remove all the special characters and spaces from the file"
      );
      seterrorMessageColor("danger");
      setpopupVisible(true);
      setselectedFile(null);
      setselectedFileName("");

      return false;
    } else {
      setpopupVisible(false);
      setselectedFile(e.target.files[0]);
      setselectedFileName(e.target.files[0].name);
      return true;
    }
  };

  const handleCancel = (e) => {
    setnotes("");
    setselectedFile(null);
    setselectedFileName("");
    aRef.current.value = null;
  };

  useEffect(() => {
    if (AssignmentRemainingData !== undefined) {
      if (
        assignment_id !== undefined &&
        AssignmentRemainingData !== null &&
        AssignmentRemainingData.Table !== undefined
      ) {
        let tempURL = `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRAssignmentDirectory/${assignment_id}/${
          location.state.course_id
        }/${location.state.node_id}/${sessionStorage.getItem("userId")}/${
          AssignmentRemainingData?.Table[0]?.VC_ASSGNMNT_FL_NM
        }?${sasToken}`;
        setexistingFileURL(tempURL);
      }
    }
  }, [assignment_id, AssignmentRemainingData]);

  useEffect(() => {
    setisLoaded(true);
    setTimeout(() => {
      setisLoaded(false);
    }, 3000);
  }, []);

  useEffect(() => {
    let tempURL =
      "https://pscfmje-lmshoriz.azurewebsites.net/WCR/WCRContentDirectory/";
    let fileName = "";
    if (ELdata?.Table[0]?.VC_UPLDD_FL_NM != null) {
      fileName = ELdata?.Table[0]?.VC_UPLDD_FL_NM;
    } else {
      fileName = ProgramData?.Table[0]?.VC_UPLDD_FL_NM;
    }
    tempURL = tempURL + assignment_id + "/" + fileName;
    setcontentURL(tempURL);
  }, [assignment_id, ProgramData, ELdata]);

  useEffect(() => {
    console.log("location state :", location.state);
  }, [location.state]);

  return (
    <>
      {isLoaded ? (
        <div
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="60" width="75" color="grey" arialLabel="Loading" />
          </div>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col>
              <CAlert
                color={errorMessageColor}
                dismissible
                visible={popupVisible}
                onClose={() => setpopupVisible(false)}
              >
                {errorMessage}
              </CAlert>

              <Row className="p-2">
                <Col>
                  <ArrowBack
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/detailsML", {
                        state: {
                          course_id: location.state.course_id,
                          type: location.state.type,
                          course_name: location.state.course_name,
                          start_date: location.state.start_date,
                          end_date: location.state.end_date,
                          credit_hours: location.state.credit_hours,
                          progress: location.state.progress,
                          description: location.state.description,
                          node_id: location.state.node_id,
                          VC_ILT_CD: location.state.VC_ILT_CD,
                          imgSrc: location.state.imgSrc,
                          topic_name: location.state.topic_name,
                          certificateID: location.state.certificateID,
                          DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
              <Card>
                <Card.Header as="h5" className="CardHeader">
                  Assignment
                </Card.Header>
                <Card.Body className="dvPreReadCardBody">
                  <Row>
                    <Col>
                      <Row className="p-2">
                        <Col sm={2}>
                          <span>ELearning Name : </span>
                        </Col>
                        <Col sm={10}>
                          <span>{location?.state?.course_name}</span>
                        </Col>
                      </Row>

                      <Row className="p-2">
                        <Col sm={2}>
                          <span>Assignment Name : </span>
                        </Col>
                        <Col sm={10}>
                          {location.state.type === "eLearning" ? (
                            <span>{ELdata?.Table[0]?.VC_CNTNT_NM}</span>
                          ) : (
                            <span>{ProgramData?.Table[0]?.VC_CNTNT_NM}</span>
                          )}
                        </Col>
                      </Row>

                      <Row className="p-2">
                        <Col sm={2}>
                          <span>Description : </span>
                        </Col>
                        <Col sm={10}>
                          {location.state.type === "eLearning" ? (
                            <span>{ELdata?.Table[0]?.VC_CNTNT_DSCRPTN}</span>
                          ) : (
                            <span>
                              {ProgramData?.Table[0]?.VC_CNTNT_DSCRPTN}
                            </span>
                          )}
                        </Col>
                      </Row>

                      <Row className="p-2">
                        <Col sm={2}>
                          <span>File : </span>
                        </Col>
                        <Col sm={10}>
                          <>
                            {location.state.type === "eLearning" ? (
                              <a href={contentURL} target="_blank">
                                {ELdata?.Table[0]?.VC_UPLDD_FL_NM}
                              </a>
                            ) : (
                              <a href={contentURL} target="_blank">
                                {ProgramData?.Table[0]?.VC_UPLDD_FL_NM}
                              </a>
                            )}
                          </>
                        </Col>
                      </Row>

                      <Row className="p-2">
                        <Col sm={2}>
                          <span>Notes : </span>
                        </Col>
                        <Col sm={10}>
                          <div>
                            <TextareaAutosize
                              disabled={!isSubmitVisible}
                              aria-label="empty textarea"
                              placeholder="Notes"
                              style={{ width: 700 }}
                              minRows={3}
                              maxRows={5}
                              value={notes}
                              onChange={(e) => {
                                setnotes(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="p-2">
                        <Col sm={2}>
                          <span>Answer File : </span>
                        </Col>
                        <Col sm={10}>
                          {isFileAlreadyThere &&
                          AssignmentRemainingData.Table.length > 0 &&
                          AssignmentRemainingData !== null &&
                          AssignmentRemainingData !== undefined ? (
                            <>
                              {existingFileURL !== null &&
                                existingFileURL !== undefined && (
                                  <a href={existingFileURL} target="_blank">
                                    {
                                      AssignmentRemainingData?.Table[0]
                                        ?.VC_ASSGNMNT_FL_NM
                                    }
                                  </a>
                                )}
                            </>
                          ) : (
                            <>
                              <Row className="p-2">
                                <Col>
                                  <input
                                    type="file"
                                    onChange={(e) => {
                                      return handleFileChange(e);
                                    }}
                                    ref={aRef}
                                  />
                                </Col>
                              </Row>
                              <Row className="p-2">
                                <Col>
                                  <div style={{ width: 700 }}>
                                    <Box
                                      sx={{
                                        bgcolor: "#bbb5b5",
                                        padding: [1, 1, 1, 1],
                                      }}
                                    >
                                      <li className="li_style">
                                        The maximum file size for upload is
                                        300mb
                                      </li>
                                      <li className="li_style">
                                        The file types allowed are :{" "}
                                      </li>
                                      <ul>
                                        <li className="li_style">
                                          Documents :
                                          doc/docx/ppt/pptx/xls/xlsx/txt/pdf
                                        </li>
                                        <li className="li_style">
                                          Images : jpg/jpeg/gif/png/bmp/tif
                                        </li>
                                        <li className="li_style">
                                          Video : mp4
                                        </li>
                                        <li className="li_style">
                                          Audio : mp3
                                        </li>
                                        <li className="li_style">Zip : zip</li>
                                      </ul>
                                    </Box>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col sm={4}></Col>
                    <Col sm={1}>
                      {/* {!isFileAlreadyThere && ( */}
                      {isSubmitVisible && (
                        <Button
                          className="btn"
                          variant="contained"
                          onClick={(e) => {
                            return handleSubmit(e);
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </Col>
                    <Col sm={1}>
                      <Button
                        className="btn"
                        variant="contained"
                        onClick={(e) => {
                          if (!isFileAlreadyThere) {
                            return handleCancel(e);
                          } else {
                            navigate("/detailsML", {
                              state: {
                                course_id: location.state.course_id,
                                type: location.state.type,
                                course_name: location.state.course_name,
                                start_date: location.state.start_date,
                                end_date: location.state.end_date,
                                credit_hours: location.state.credit_hours,
                                progress: location.state.progress,
                                description: location.state.description,
                                node_id: location.state.node_id,
                                VC_ILT_CD: location.state.VC_ILT_CD,
                                imgSrc: location.state.imgSrc,
                                topic_name: location.state.topic_name,
                                certificateID: location.state.certificateID,
                                DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
                              },
                            });
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col sm={3}></Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Assignment;

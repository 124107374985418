import React, { useEffect, useState } from "react";
import "./featured.scss";
import Logo from "../../../../src/VideoChannel/eC_0102_m.jpg";
import { useNavigate } from "react-router-dom";
import Slideshow from "../slideshow/Slideshow";

const Featured = ({ items }) => {
  const [toSlide, settoSlide] = useState([]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < 4; i++) {
      tempArray.push(items[Math.floor(Math.random() * items.length)]);
      settoSlide(tempArray);
    }
  }, [items]);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/watch`;
    navigate(path);
  };
  return (
    <div className="featured">
      <img src={Logo} alt="" />

      <div className="info">
        {toSlide !== null && (
          <>
            <Slideshow slides={toSlide} />
          </>
        )}
      </div>
    </div>
  );
};

export default Featured;

import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NavBar from "./NavBar";
import "./Home.scss";
import truck from "../Assets/Images/truck.png";
import { FaUserCircle } from "react-icons/fa";
import { ImHome } from "react-icons/im";
import { HOME_URL } from "./../../ELearning/Components/Common/Constants.js";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HomePage() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${truck})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          backgroundfilter: "blur(5px)",
        }}
      >
        <p className="userinfo" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaUserCircle />
          </div>
          <></> {sessionStorage.getItem("userFirstName")} {sessionStorage.getItem("userLastName")}
          <div style={{ marginLeft: "85%" }}>
            <a
              href={HOME_URL}
              style={{
                color: "white",
                textAlign: "right",
                paddingLeft: "85%",
                fontWeight: "bold",
              }}
              title="Back To Main Application"
            >
              <ImHome />
            </a>
          </div>
        </p>
      </div>
    </div>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";
import {
  BLOB_LANG,
  STORAGE_ADDRESS,
  LANG_SAS_TOKEN,
} from "./../../../ELearning/Components/Common/Constants.js";

const fallbackLng = ["en"];
const resources = {
  en: {
    translation: {
      rbkey_SlcRsrcLanguagesLBL: "Select Language",
      rbkey_RcntlyAddLBL: "Recently Added",
      rbkey_WtchItAgn: "Watch it again",
      rbkey_Sales: "Sales",
      rbkey_ECanterSles: "E-Canter Sales",
      rbkey_CntnueWatching: "Continue Watching",
      rbkey_CtgrsLBL: "Categories",
      rbkey_SrchLBL: "Search",
      rbkey_SlctLBL1: "--SELECT--",
      rbkey_SeeAll: "See All",
    },
  },
  ar: {
    translation: {
      select_language: "اختار اللغة",
      "Recently Added": "مرحبًا بك في رد الفعل",
      "Watch it again": "هذا مثال",
      Sales: "من فضلك ادخل الاسم",
      "E-Canter Sales": "E-فضلك ادخل",
      Categories: "فئات",
    },
  },
  fr: {
    translation: {
      select_language: "Choisir la langue",
      "Recently Added": "Récemment ajouté",
      "Watch it again": "Regardez-le à nouveau",
      Sales: "Ventes",
      "E-Canter Sales": "Ventes E-Canter",
      Categories: "Catégories",
    },
  },
  ja: {
    translation: {
      rbkey_SlcRsrcLanguagesLBL: "言語を選択",
      rbkey_RcntlyAddLBL: "最近追加された",
      rbkey_WtchItAgn: "もう一度見る",
      rbkey_Sales: "販売",
      rbkey_ECanterSles: "Eキャンター販売",
      rbkey_CtgrsLBL: "カテゴリー",
      rbkey_SrchLBL: "探す",
      rbkey_CntnueWatching: "見続ける",
      rbkey_SlctLBL1: "--選択する--",
      rbkey_SeeAll: "すべてを見る",
    },
  },
};

const loadResources = async (locale) => {
  let lang = locale;
  let setLang = BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  if (lang === "en") {
    setLang = BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  } else if (lang === "ja") {
    setLang = BLOB_LANG + "/ja-JP/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  } else if (lang === "fr") {
    setLang =
    BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  } else if (lang === "ar") {
    setLang =
    BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  } else if (lang === "zh") {
    setLang = BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  } 
  else if (lang === "es") {
    setLang = BLOB_LANG + "/es-ES/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  }
  else {
    setLang = BLOB_LANG + "/en-US/LanguageAcronym.json?" + LANG_SAS_TOKEN;
  }

  return await axios(setLang)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const backendOptions = {
  loadPath: "{{lng}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("-");
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};
const getLang = sessionStorage.getItem("selectedLanguage");
const query = new URLSearchParams(window.location.search);
const langFromURL = query.get("lang");

let [lang] = [];
if (getLang !== null) {
  [lang] = getLang.split("-");
} else {
  lang = langFromURL;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: backendOptions,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import assessmentStyles from "./Assessment.module.scss";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import truck from "../../../Assets/Images/truck.png";
import Navbar from "../../../Pages/NavBar";
import swal from "sweetalert";
import { blue } from "@material-ui/core/colors";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
} from "./../../Common/Constants.js";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

let resumeTime;

const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[800],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    //margin: theme.spacing(1),
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
  },
});

const Assessment = (props) => {
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [myQuestionList, setMyQuestionList] = useState([]);
  const [myQuestionDtls, setMyQuestionDtls] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  let resumeQstn;
  let resumeQstnId;
  let resumeQstnTyp;
  useEffect(() => {
    const AssmtData = axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssessmentDetails", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
      })
      .then((res) => {
        let temp = res.data.Table.concat(
          res.data.Table1,
          res.data.Table2,
          res.data.Table3
        );
        setLoading(true);
        setAssessmentDetails(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    // getData();
  }, []);

  const handleResume = async () => {
    debugger;
    //added method to update the progress bar
    axios({
      method: "post",
      url: PUBLIC_URL + "/api/Program/MyLearning/P/TrackCourse",
      data: {
        courseLaunchId: location.state.course_id,
        nodeId: parseInt(`${location.state.node_id}`),
        userId: sessionStorage.getItem("userId"),
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );

    getDataforResume();
  };

  const handleCancel = async () => {
    navigate("/detailsML", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
        certificateID: location.state.certificateID,
        DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
      },
    });
  };
  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/EL/QuestionAttemptStatus", {
        params: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
          QuestionId: null,
        },
      })
      .then((res) => {
        setMyQuestionDtls(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getDataforResume = () => {
    axios
      .get(
        PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssessmentQuestionList",
        {
          params: {
            courseLaunchId: location.state.course_id,
            nodeId: location.state.node_id,
            userId: sessionStorage.getItem("userId"),
          },
        }
      )
      .then((res) => {
        debugger;
        setQuestionList(res.data);
        setMyQuestionList(res.data);
        res.data.Table.map((item, index) => {
          if (item.BT_IS_CRRNT_QSTN_ATTMPT == true) {
            resumeQstn = item.NM_LST_QSTN_ATTMPT;
            resumeQstnId = item.NM_QSTN_ID;
            resumeQstnTyp = item.NM_QSTN_TYP;
          }
        });
        debugger;
        //getResumeTime(resumeQstnId);

        console.log("myQuestionDtls", myQuestionDtls);

        const filteredPosts = myQuestionDtls.Table.filter((item, key) => {
          return item.NM_QSTN_ID == resumeQstnId;
        });

       
        
        if(filteredPosts.length>0)
        {
        const resultTime = filteredPosts[0].NM_LFT_ASSSSMNT_ATTMPT_TM;
        resumeTime = new Date(resultTime * 1000).toISOString().slice(11, 19);
        }
        else{
          resumeTime=assessmentDetails[0]?.CH_TM_ALLWD
        }
        if (
          res.data !== null &&
          res.data !== undefined &&
          res.data.Table.length > 0 &&
          resumeQstn != undefined
        ) {
          navigate("/quiz", {
            state: {
              course_id: location.state.course_id,
              node_id: location.state.node_id,
              type: location.state.type,
              question_id: resumeQstnId,
              question_type: resumeQstnTyp,
              questionList: res.data,
              noOfQuestions: assessmentDetails[0]?.NM_TTL_CNT,
              time: resumeTime,
              course_name: location.state.course_name,
              start_date: location.state.start_date,
              end_date: location.state.end_date,
              credit_hours: location.state.credit_hours,
              progress: location.state.progress,
              description: location.state.description,
              VC_ILT_CD: location.state.VC_ILT_CD,
              imgSrc: location.state.imgSrc,
              topic_name: location.state.topic_name,
              button_type: "resume",
              resume_question: resumeQstn,
              remainingTime: resumeTime,
            },
          });
        } else {
          swal({
            title: "Error",
            text: "assessment was not started",
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStart = () => {
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SetAssessmentStatus",

      data: {
        courseLaunchId: location.state.course_id,
        nodeId: location.state.node_id,
        userId: sessionStorage.getItem("userId"),
      },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });

    getData();
  };

  const getData = async () => {
    await axios
      .get(
        PUBLIC_URL + "/api/ELearning/MyLearning/EL/GetAssessmentQuestionList",
        {
          params: {
            courseLaunchId: location.state.course_id,
            nodeId: location.state.node_id,
            userId: sessionStorage.getItem("userId"),
          },
        }
      )
      .then((res) => {
        setQuestionList(res.data);
        setMyQuestionList(res.data);

        if (
          res.data !== null &&
          res.data !== undefined &&
          res.data.Table.length > 0
        ) {
          navigate("/quiz", {
            state: {
              course_id: location.state.course_id,
              node_id: location.state.node_id,
              type: location.state.type,
              question_id: res.data.Table[0]?.NM_QSTN_ID,
              question_type: res.data.Table[0]?.NM_QSTN_TYP,
              questionList: res.data,
              noOfQuestions: assessmentDetails[0]?.NM_TTL_CNT,
              time: assessmentDetails[0]?.CH_TM_ALLWD,
              course_name: location.state.course_name,
              start_date: location.state.start_date,
              end_date: location.state.end_date,
              credit_hours: location.state.credit_hours,
              progress: location.state.progress,
              description: location.state.description,
              VC_ILT_CD: location.state.VC_ILT_CD,
              imgSrc: location.state.imgSrc,
              topic_name: location.state.topic_name,
              button_type: "start",
              remainingTime: assessmentDetails[0]?.CH_TM_ALLWD,
            },
          });
        } else {
          swal({
            title: "Error",
            text: "Questions are not mapped to this assessment!",
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const classes = useStyles();

  return (
    <div>
      {" "}
      <Navbar />
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${truck})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "86vh",
          backgroundfilter: "blur(5px)",
          minHeight: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "70%",
            marginLeft: "15%",
            marginTop: "3%",
          }}
        >
          <Container fluid>
            <Row>
              <Col>
                <Card maxWidth="sm">
                  <Card.Header as="h5" className={assessmentStyles.headercard}>
                    Assessment
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={6}>
                            <span>Course Name : </span>
                          </Col>
                          <Col sm={6}>
                            <span>
                              {assessmentDetails[0]?.VC_CRS_LNCH_NM !== null &&
                              assessmentDetails[0]?.VC_CRS_LNCH_NM !== undefined
                                ? assessmentDetails[0]?.VC_CRS_LNCH_NM
                                : location.state.course_name}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Assessment Name : </span>
                          </Col>
                          <Col sm={6}>
                            <span>{assessmentDetails[0]?.VC_ND_NM}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Description : </span>
                          </Col>
                          <Col sm={6}>
                            <span>
                              {assessmentDetails[0]?.VC_CNTNT_DSCRPTN !==
                                null &&
                              assessmentDetails[0]?.VC_CNTNT_DSCRPTN !==
                                undefined
                                ? assessmentDetails[0]?.VC_CNTNT_DSCRPTN
                                : location.state.description}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Total Question Count : </span>
                          </Col>
                          <Col sm={6}>
                            <span>{assessmentDetails[0]?.NM_TTL_CNT}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Duration : </span>
                          </Col>
                          <Col sm={6}>
                            <span>{assessmentDetails[0]?.CH_TM_ALLWD}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Total Marks : </span>
                          </Col>
                          <Col sm={6}>
                            <span>{assessmentDetails[0]?.FL_TTL_MRKS}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Negative Marking : </span>
                          </Col>
                          <Col sm={6}>
                            <span>{assessmentDetails[2]?.FL_ASSSSMNT_NGTV_MRKNG_PRCNTG!==0?"Yes":"No"}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <span>Minimum Marks(%) : </span>
                          </Col>
                          <Col sm={6}>
                            <span>
                              {assessmentDetails[2]?.NM_MN_PSSNG_MRKS}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="row">
                      {" "}
                      <div className="col-md-4"></div>
                      <div className="col-md-1">
                        <ColorButton
                          variant="contained"
                          color="primary"
                          className={classes.margin}
                          onClick={handleStart}
                        >
                          Start
                        </ColorButton>

                        {/* <Button
                          variant="contained"
                          color="primary"
                           sx={{
                            marginLeft: "5px",
                            marginRight: "5px"
                          }}
                          onClick={handleStart}
                        >
                          Start
                        </Button> */}
                      </div>
                      <div
                        className="col-md-2  buttonStyle"
                        style={{ textAlign: "center", marginLeft: "25px" }}
                      >
                        {" "}
                        <ColorButton
                          variant="contained"
                          color="primary"
                          className={classes.margin}
                          onClick={handleResume}
                        >
                          Resume
                        </ColorButton>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            
                          }}
                          onClick={handleResume}
                        >
                          Resume
                        </Button> */}
                      </div>
                      <div className="col-md-1">
                        {" "}
                        <ColorButton
                          variant="contained"
                          color="primary"
                          className={classes.margin}
                          onClick={handleCancel}
                        >
                          Cancel
                        </ColorButton>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button> */}
                        <div className="col-md-4"></div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Assessment;

import React from "react";
import "./ListItemML.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { PUBLIC_URL, PUBLIC_URL_API2, DOMAIN_ID, formatTime,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN
} from "../Common/Constants.js";
import Navbar from "../../Pages/NavBar";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import NotFound from "../../Assets/Components/NotFound";
import BasicModel from "../../Components/ListItemML/popdescription/BasicModal";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiTableHead from "@material-ui/core/TableHead";
import Paper from "@mui/material/Paper";
import Card from "react-bootstrap/Card";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function ClassRoomRowMUI(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const { t } = useTranslation();
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.VC_SSN_NM}
        </TableCell>

        <TableCell align="right" style={{ maxWidth: 200 }}>
          <p title={removeTags(row.VC_DSCRPTN)}>
            {row.VC_DSCRPTN != null
              ? removeTags(row.VC_DSCRPTN).substring(0, 20) + "..."
              : "NA"}
          </p>
        </TableCell>
        <TableCell align="right">
          {formatTime(row?.DT_SSN_DT, "date")}
        </TableCell>
        <TableCell align="right">
          {formatTime(row?.DT_STRT_TM, "time")}
        </TableCell>
        <TableCell align="right">
          {" "}
          {formatTime(row?.DT_END_TM, "time")}
          {/* {row?.DT_END_TM?.split("T")[1]} */}
        </TableCell>
        <TableCell align="right">{row.VC_VN_NM}</TableCell>
        <TableCell align="right">{row.VC_INSTRCTR_NM}</TableCell>
        <TableCell align="right">{row.VC_FL_NM}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const OfferDetails = (props) => {
  const [offerData, setofferData] = useState();
  const [nominationData, setNominationData] = useState();
  const [sltcdOffer, setSltcdOffer] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [offrName, set] = React.useState([]);
  const [EL_P_ExpiredFlag, setEL_P_ExpiredFlag] = useState(false);
  const [classroomData, setclassroomData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [status, setStatus] = useState();
  const [showEnroll, setShowEnroll] = useState(true);
  const [enrolled, setEnrolled] = useState(false);
  const [offerCode, setOfferCode] = useState();
  const [tempURL, settempURL] = useState();
  const { t } = useTranslation();
  let currDate;
  const sasToken = SAS_TOKEN;
  const containerName = CONTAINER_NAME;
  const storageAccountName = STORAGE_ACCOUNT_NAME;
  const [currentNominationStatus, setcurrentNominationStatus] = useState();

  const handleEnroll = (event, id) => {
    
    var End_date = classroomData.Table[0].DT_END_DT?.split("T")[0];
    const current = new Date();
    var year = current.getFullYear();
    var month = current.getMonth() + 1;
    var day = current.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const currDate = `${day}/${month}/${year}`;


    let array=End_date.split("-");
    if(array[0]<year)
    {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }

    if(array[0]==year && array[1]<month) 
    {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }

    if(array[0]==year && array[1]==month && array[2]<day) 
    {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }
    axios
    .post(
      PUBLIC_URL +
        "/api/ClassRoomTraining/AvailableLearning/ILTSelfNomination",
      {
        learnerId: sessionStorage.getItem("userId"),
        iltId: sessionStorage.getItem("offerId"),
        isLoginUser: true,
        strWorkflowType: "",
        isBlended: true,
      }
    )
    .then((response) => {
      navigate("/detailsML", {
        state: {
          course_id: location.state.iltId,
          type: "Classroom Training",
          course_name: classroomData.Table[0].VC_ILT_NM,
          start_date: classroomData.Table[0].DT_STRT_DT,
          end_date: classroomData.Table[0].DT_END_DT,
          credit_hours: classroomData.Table[0].NM_CRDT_HRS,
          progress: 0,
          description: classroomData.Table[0].VC_DSCRPTN,
          topic_name: classroomData.Table[0].VC_ILT_KND_NM,
          imgSrc: tempURL,
          VC_ILT_CD: offerCode,
          childId: sessionStorage.getItem("offerId"),
          isDetail: "true",
          iltId: parseInt(sessionStorage.getItem("offerId")),
        },
      });
    });
  };

  const getNominationStatus = (NMNTN_STTS) => {
    
    if (NMNTN_STTS === "P") {
      setcurrentNominationStatus(t("rbkey_PndngLBL"));
    } else if (NMNTN_STTS === "W") {
      setcurrentNominationStatus(t("rbkey_WtngLBL"));
    } else if (NMNTN_STTS === "C") {
      setcurrentNominationStatus(t("rbkey_CnfrmdLBL"));
    } else if (NMNTN_STTS === "X") {
      setcurrentNominationStatus(t("rbkey_VcblryCanceledLBL"));
    } else if (NMNTN_STTS === "R") {
      setcurrentNominationStatus(t("rbkey_RjctdLBL"));
    } else if (NMNTN_STTS === "M") {
      setcurrentNominationStatus(t("rbkey_PndngWthRprtngAthrtyLBL"));
    } else if (NMNTN_STTS === "D") {
      setcurrentNominationStatus(t("rbkey_RjctdByRprtngAthrtyLBL"));
    } else if (NMNTN_STTS === "E") {
      setcurrentNominationStatus(t("rbkey_AwtdCnfrmtnLBL"));
    } else if (NMNTN_STTS === "S") {
      setcurrentNominationStatus(t("rbkey_NmntdByRprtngAthrtyLBL"));
    } else if (NMNTN_STTS === "I") {
      setcurrentNominationStatus(t("rbkey_IgnrdLBL"));
    } else if (NMNTN_STTS === "B") {
      setcurrentNominationStatus(t("rbkey_DclndLBL"));
    } else if (NMNTN_STTS === "Y") {
      setcurrentNominationStatus(t("rbkey_CncltnPendingLbl"));
    } else if (NMNTN_STTS === "L") {
      setcurrentNominationStatus("Launched");
    }
  };

  const handleChange = (iLTId) => {
    
        
    //if (nominationData != undefined){
    nominationData.map((item, index)=>{
      if(item.NM_ILT_ID==iLTId[0] && (item.NMNTN_STTS === "X" || item.NMNTN_STTS === null))
      {
        setShowEnroll(true)
      }
      else if (item.NM_ILT_ID==iLTId[0] && (item.NMNTN_STTS === "C" || item.NMNTN_STTS === "P"))
      {
        setShowEnroll(false);
        setEnrolled(true)
      }
    })
  

    let arr = new Array();
    nominationData.map((item, index) => {
      arr[index] = item.NMNTN_STTS;
    });

    if (arr.includes("C")) {
      setShowEnroll(false);
        setEnrolled(true)
      //setShowEnroll(false);
    }

    if (arr.includes("P")) {
      setShowEnroll(false);
        setEnrolled(true)
      //setShowEnroll(false);
    }
    setSltcdOffer(true);
    sessionStorage.setItem("offerId", iLTId[0]);
    const filteredData= offerData.filter((item, key) => {
          return item.NM_ILT_ID == iLTId[0];
        });
    setOfferCode(filteredData.VC_ILT_CD);
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILTDetail", {
        params: {
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          iLTId: iLTId[0],
          userId: sessionStorage.getItem("userId"),
          culture: "en-US",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          setEL_P_ExpiredFlag(true);
        } else {
          setclassroomData(res.data);
          let url;
          if (res.data.Table[0]?.VC_UPLDD_FL_NM != null) {
            url =
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${res.data.Table[0]?.NM_ILT_ID}/${res.data.Table[0]?.VC_UPLDD_FL_NM}?` +
              sasToken;
              settempURL(url)
        } else {
          url =
              "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
              settempURL(url)
          }
          //getStatus(res.data.Table2[0].CH_ILT_STTS);
          getNominationStatus(res.data.Table3[0].NMNTN_STTS);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      
  };
  useEffect(() => {
    setEnrolled(false)
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/ILT/getAllOfrsOnILTId", {
        params: {
          ILTId: location.state.iltId,
        },
      })
      .then((res) => {
        if (res.data != undefined && res.data.length > 0) {
                 
          const filteredOffers = res.data[0].filter((item, key) => {
            var End_date = item.DT_END_DT?.split("T")[0];
            const current = new Date();
            var year = current.getFullYear();
            var month = current.getMonth() + 1;
            var day = current.getDate();
          if (month < 10) {
            month = "0" + month;
           }
          if (day < 10) {
            day = "0" + day;
          }
          const currDate = `${day}/${month}/${year}`;


          let array=End_date.split("-");
          if(array[0]>year  )
          {
            return item.NM_ILT_ID;
            // if(array[0]==year && array[1]>month) 
            // {
            //   if(array[0]==year && array[1]==month && array[2]>day) 
            //   {
            //     return item.NM_ILT_ID;
            //   }

            // }
      
          }
          else if (array[0]==year && array[1]>month)
          {
            return item.NM_ILT_ID;
          }
          else if(array[0]==year && array[1]==month && array[2]>day)
          {
            return item.NM_ILT_ID;
          }

          });
          
          setofferData(filteredOffers);
          
          if(res.data[1] != undefined && res.data[1].length!=0 )
          {
            setNominationData([]);
            res.data[1].map((item)=>{
              if(item.NM_LRNR_ID==sessionStorage.getItem("userId"))
              {
                setNominationData(res.data[1]);
              }

            }) 
      
          }
          else
          {
            setNominationData([]);
          }
          
        }
      })
      .catch((err) => {
        console.log(err);
      });

     
  }, []);

  const MenuItem = withStyles({
    root: {
      justifyContent: "flex-start",
    },
  })(MuiMenuItem);

  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
   
  const classes = useStyles();
  const TableHead = withStyles((theme) => ({
    root: {
      //width: "100%"
    },
  }))(MuiTableHead);

  const TableHeaderCell = withStyles((theme) => ({
    root: {
      maxWidth: "150px",
      minWidth: "150px",
      width:"150px"
    },
  }))(TableCell);

  return (
    <div>
      <Navbar />
      <Button onClick={() => navigate(-1)}>{t("rbkey_BckLBL")}</Button>
      {!sltcdOffer ? (
        <div className="backgroundStyle">
          <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
            <div display="inline-block" style={{ marginLeft: "10px" }}>
              {t("rbkey_SlctoffrMSG")}
            </div>
          </div>
          <div style={{ marginLeft: "2.5%" }}>
            <FormControl sx={{ m: 1, width: 1200, mt: 3 }}>
              <Select
                multiple
                displayEmpty
                value={offrName}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{t("rbkey_SlctEvntLBL")}</em>;
                  }

                  return selected.join(", ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em style={{width:"100%"}}>
                  <Table>
                  <TableHead>
            <TableRow>
            <TableHeaderCell align="left"
              style={{maxWidth: "250px",
              minWidth: "250px",
              width:"250px",
              }} >{t("rbkey_BtchLBL")}</TableHeaderCell>
              <TableHeaderCell align="left"
              style={{maxWidth: "250px",
              minWidth: "250px",
              width:"250px",
              paddingLeft:"2%"}} >{t("rbkey_OffrCdLbl")}</TableHeaderCell>
              <TableHeaderCell align="left" style={{paddingLeft:"1.5%"}} > {t("rbkey_StrtTmLBL")}</TableHeaderCell>
              <TableHeaderCell align="left" style={{paddingLeft:"2%"}} > {t("rbkey_EndTmLBL")}</TableHeaderCell>
              <TableHeaderCell align="left" style={{paddingLeft:"2%"}} >{t("rbkey_VnLBL")}</TableHeaderCell>
            </TableRow>
          </TableHead>
                    {/* <TableRow width="100" >
                    <TableCell align="left" style={{ width: 25, paddingRight : 10  }}>
                    {t("rbkey_SlctEvntLBL")}
                    </TableCell>
                    <TableCell align="left"  style={{ width: 25  }}> 
                    {t("rbkey_StrtTmLBL")}
                    </TableCell>
                    <TableCell align="left" style={{ width: 25  }}>
                    {t("rbkey_EndTmLBL")}
                    </TableCell>
                    <TableCell align="left" style={{width: 25  }}>
                     {t("rbkey_VnLBL")}
                    </TableCell>
                    </TableRow> */}
                    </Table> 
                    </em>
                </MenuItem>
                {/* {offerData?.length != 0  ? (<MenuItem> */}
                  {offerData?.map((item) => (
                  <MenuItem key={item.NM_ILT_ID} value={item.NM_ILT_ID}>
                     <Table>
                    
          <TableBody>
                    <TableRow>
                    {/* <TableCell align="left" size = "small" style={{ maxWidth: 25 }}>
                    {item.VC_ILT_CD}
                    </TableCell> */}
                     <TableHeaderCell align="left"
                     style={{maxWidth: "250px",
                     minWidth: "250px",
                     width:"250px"}} 
                     title={item.VC_ILT_NM}>
                      {/* {item.VC_ILT_NM} */}
                      {item.VC_ILT_NM?.substring(0, 30)}
                        {item.VC_ILT_NM?.length > 30 ? "..." : ""}
                     </TableHeaderCell>
                    <TableHeaderCell align="left"
                     style={{maxWidth: "250px",
                     minWidth: "250px",
                     width:"250px"}} 
                     title={item.VC_ILT_CD}>
                      {/* {item.VC_ILT_CD} */}
                      {item.VC_ILT_CD?.substring(0, 30)}
                        {item.VC_ILT_CD?.length > 30 ? "..." : ""}
                      </TableHeaderCell>
                    <TableHeaderCell align="left" > {formatTime(item.DT_STRT_DT, "date")}</TableHeaderCell>
                    <TableHeaderCell align="left" >{formatTime(item.DT_END_DT, "date")}</TableHeaderCell>
                    <TableHeaderCell align="left"
                     title={item.VC_VN_NM} > 
                    {/* {item.VC_VN_NM} */}
                    {item.VC_VN_NM?.substring(0, 18)}
                    {item.VC_VN_NM?.length > 18 ? "..." : ""}
                    </TableHeaderCell>
                    {/* <TableCell align="left" size = "small" style={{width:25 }}>
                    {formatTime(item.DT_STRT_DT, "date")}
                    </TableCell>
                    <TableCell align="left" size = "small" style={{width:25}}>
                    {formatTime(item.DT_END_DT, "date")}
                    </TableCell>
                    <TableCell align="left" size = "small" style={{ maxWidth: 25 }}>
                    {item.VC_VN_NM}
                    </TableCell> */}
                    </TableRow>
                    </TableBody>
                    </Table> 

                   
                  </MenuItem>
                ))}
                {/* </MenuItem>):(<div ><NotFound /></div>) } */}
                {offerData?.length != 0  ? (<MenuItem></MenuItem>):(<div ><NotFound /></div>) }
                
              </Select>
            </FormControl>
          </div>
        </div>
      ) : (
        <div>
         
          <>
          <Container fluid>
          <Card>
          <Card.Header as="h5" className="CardHeader">
          {t("rbkey_BtchLBL")}
          </Card.Header>
           <Card.Body>
            <Row style={{ padding: "0 0 2% 0" }}>
              <Col sm={2}>
                <img className="offerLogo" src={tempURL} />
              </Col>
              <Col sm={8}>
                <div style={{ padding: "0 0 2% 0" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {classroomData.Table?.map((item) => item.VC_ILT_NM)}
                  </span>
                </div>
                <Row style={{ padding: "0 0 2% 0" }}>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {t("rbkey_launchDate_LBL")} :{" "}
                    </span>
                    <span>
                      {classroomData.Table?.map((item) =>
                        // item?.DT_STRT_DT?.split("T")[0]
                        formatTime(item?.DT_STRT_DT, "date")
                      )}
                    </span>
                  </Col>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {t("rbkey_availableTill_LBL")} :{" "}
                    </span>
                    <span>
                      {classroomData.Table?.map((item) =>
                        //item?.DT_END_DT?.split("T")[0]
                        formatTime(item?.DT_END_DT, "date")
                      )}
                    </span>
                  </Col>
                </Row>
                <Row style={{ padding: "0 0 2% 0" }}>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {t("rbkey_TrnngCdLbl")} :{" "}
                    </span>
                    {classroomData.Table?.map((item) =>
                      item?.VC_ILT_CD === undefined
                        ? location.state.VC_ILT_CD
                        : item?.VC_ILT_CD
                    )}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {t("rbkey_CrdtHrsLBL")} :{" "}
                    </span>
                    <span>
                      {classroomData.Table?.map((item) => item.NM_CRDT_HRS)}
                    </span>
                  </Col>
                </Row>
                <Row style={{ padding: "0 0 2% 0" }}>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      No. of sessions:{" "}
                    </span>
                    {classroomData.Table1?.length}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {t("rbkey_TpcLBL")} :{" "}
                    </span>
                    {classroomData.Table?.map((item) =>
                      item.VC_ILT_KND_NM !== null
                        ? item.VC_ILT_KND_NM
                        : location.state.topic_name === null
                        ? "NA"
                        : location.state.topic_name
                    )}
                  </Col>
                </Row>
                <Row style={{ padding: "0 0 2% 0" }}>
                                  <Col sm={6}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {t("rbkey_CrrntNmntnSttsLBL")} :{" "}
                                    </span>
                                    <span>{currentNominationStatus}</span>
                                    <br />
                                  </Col>
                                
                                </Row>
                <Row style={{ padding: "0 0 2% 0" }}>
                  <Col>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {t("rbkey_DscrptnLBL")} :{" "}
                    </span>
                    {classroomData.Table?.map((item) =>
                      item.VC_DSCRPTN !== null
                        ? removeTags(item.VC_DSCRPTN)
                        : location.state.description === null
                        ? "NA"
                        : location.state.description
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={2}>
                {
                  showEnroll ? (
                    <>
                      
                          <Button
                            onClick={(event, id) => handleEnroll(event, id)}
                            variant="contained"
                          >
                            {t("rbkey_enrollLBL")}
                          </Button>
                        
                    </>
                  )
                  :
                  (
                    <>
                    {enrolled ? (
                    <Button
                      disabled
                      variant="contained"
                    >
                      {t("rbkey_enrollLBL")}
                      {/* {enrolled} */}
                    </Button>
                    ):(
                      <></>
                    )}
                  
              </>
                  )
                
                }
                
              </Col>
            </Row>

            
            <Row>
                          <Col>
                            <TableContainer component={Paper}>
                              <Table aria-label="collapsible table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "Bold" }}>
                                      {t("rbkey_SssnNmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_DscrptnLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_DtLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_StrtTmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_EndTmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_VnLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_InstrctrNmLBL")}
                                    </TableCell>
                                    <TableCell
                                      style={{ fontWeight: "Bold" }}
                                      align="right"
                                    >
                                      {t("rbkey_AttchmntLBL")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {classroomData?.Table1?.map((row) => (
                                    <ClassRoomRowMUI
                                      key={row.NM_SSN_ID}
                                      row={row}
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Col>
                        </Row>
                        </Card.Body>
                        </Card>
                        </Container>
          </>
        </div>
      )}
    </div>
  );
};

export default OfferDetails;
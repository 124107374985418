import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import React from "react";
import "./watchML.scss";
import { AzureMP } from "react-azure-mp";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WatchML = (props) => {
  const location = useLocation();

  if (location.moreFlag) {
    location.setmoreFlag(false);
  }

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/mylearning`;
    navigate(path);
  };

  const { t } = useTranslation();

  return (
    <div className="watch">
      <div className="back">
        <ArrowBackIosNewOutlined onClick={() => navigate(-1)} />
        <span onClick={() => navigate(-1)}>{t("rbkey_BckLBL")}</span>
      </div>
      <AzureMP
        className="VideoPlayer"
        adaptRatio={[16, 9]}
        skin="amp-flush"
        src={[
          {
            src: location.state.VC_CNT_URL,
          },
        ]}
        options={{
          autoplay: true,
          controls: true,
          width: "600",
          height: "400",
        }}
      />
    </div>
  );
};

export default WatchML;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

export const MultipleChoice = (props) => {
  const location = useLocation();
  const [selectedAns, setSelectedAns] = useState("");
  const [questionDetails, setQuestionDetails] = useState([]);
  const num = location.state.questionNo;
  const navigate = useNavigate();
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const { t } = useTranslation();
  // const [itemSelected, setitemSelected] = useState([]);

  // let itemSelected = [];

  const optionClicked = (isCorrect) => {
    if (questionDetails.data.Table[0]?.VC_ANSWR_TXT === isCorrect) {
    } else {
    }
  };

  useEffect(() => {
    console.log("its MCQ------------------------");
    setSelectedAns("");
    setQuestionDetails([]);
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/ELearning/MyLearning/EL/GetAssessmentQuestionDetails",

      data: {
        // questionId: questionList?.Table[num]?.NM_QSTN_ID,
        questionId: location.state.question_id,
        userId: sessionStorage.getItem("userId"),
        nodeId: location.state.node_id,
        courseLaunchId: location.state.course_id,
        userResponse: null,
      },
    })
      .then((response) => {
        console.log(response);
        setQuestionDetails(response);
        if (
          response.data.Table1 != undefined &&
          response.data.Table1.length > 0
        ) {
debugger;
          if(location.state.button_type=="start" && response.data.Table7.length != 0 && response.data.Table7 != undefined)
          {
            if(response.data.Table7[0]?.BT_IS_ATTMPTD==true)
            setSelectedAns(response.data.Table1[0].NM_ANSWR_ID);
          }
          else if (location.state.button_type=="resume"){
            setSelectedAns(response.data.Table1[0].NM_ANSWR_ID);
          }
          else{ setSelectedAns("")
          }
         
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);

  useEffect(() => {
    console.log("props : ", props);
    console.log("location.state : ", location.state);
  }, [location, props]);

  const handleClickSave = () => {
    if (selectedAns === null) {
      swal({
        title: "Error",
        text: "Please select an option to save!",
        icon: "error",
      });
      return;
    }
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveMCQAnswer",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
        qustionId: location.state.question_id,
        choice: selectedAns,
        markQuestion: true,
        leftAssessmentAttemptTime: "",
      },
      // data: {
      //   userCourseModel: {
      //     UserId: sessionStorage.getItem("userId"),
      //     NodeId: location.state.node_id,
      //     CourseLaunchId: location.state.course_id,
      //   },
      //   QustionId: location.state.question_id,
      //   Choice: itemSelected.toString(),
      //   MarkQuestion: true,
      //   leftAssessmentAttemptTime: "",
      // },
    })
      .then((response) => {
        // setQuestionDetails(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        choice: true,
        finalizeAssessment: true,
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    setisCancelClickedFromAssessmentTypes(true);
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id: location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };

  // const handleRadioChange = (event) => {
  //   setSelectedAns((prev) => {
  //     if (prev != null) {
  //       item.NM_ANSWR_ID;
  //     }
  //   });
  //   props.isQuestionAnswered(true);
  //   console.log(item.NM_ANSWR_ID);
  // };

  // useEffect(() => {
  //   console.log("itemSelected : ", itemSelected);
  // }, [itemSelected]);

  return (
    <div>
      {questionDetails !== null &&
      questionDetails !== undefined &&
      questionDetails?.data !== undefined ? (
        <h3 className={assessmentStyles.questiontext}>
          {questionDetails?.data?.Table[0]?.VC_QSTN_TXT}{" "}
        </h3>
      ) : (
        <></>
      )}

      {questionDetails.data?.Table?.map((item, index) => (
        <div key={index}>
          <ul key={index}>
            <li
              onClick={() => optionClicked(item.VC_ANSWR_TXT)}
              value={item.NM_ANSWR_ID}
              key={index}
            >
              <input
                type="radio"
                name="answer"
                style={{ marginRight: "5px" }}
                defaultChecked={
                  
                 (questionDetails?.data?.Table1[0]?.NM_ANSWR_ID ===
                  item.NM_ANSWR_ID && location.state.button_type=="resume") || (questionDetails?.data?.Table1[0]?.NM_ANSWR_ID ===
                    item.NM_ANSWR_ID && location.state.button_type=="start" && questionDetails?.data?.Table7.length >0 && questionDetails?.data?.Table7[0].BT_IS_ATTMPTD==true)
                    ? true
                    : false
                }
                // onChange={handleRadioChange}
                onChange={() => {
                  debugger;
                  setSelectedAns(item.NM_ANSWR_ID);
                  // setitemSelected((prev) => [...prev, item.NM_ANSWR_ID]);
                  props.isQuestionAnswered(true);
                  console.log(item.NM_ANSWR_ID);
                }}
                key={index}
              />

              {item.VC_ANSWR_TXT}
            </li>
          </ul>
        </div>
      ))}

      {/* {questionDetails.data.Table.map((item, index) => {
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="msq_checkbox_name"
            id=`checkbox_${index}`
            value={}
            onChange={}
          />
          <label htmlFor="msq_checkbox_id"></label>
        </div>;
      })} */}

      {/* actual */}

      <div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>
      <div
        style={{
          marginLeft: "40%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
        {t("rbkey_CnclLBL")}
        </Button>

        <Button
          variant="contained"
          sx={{ marginLeft: "5px" }}
          onClick={handleClickSave}
        >
          {t("rbkey_SvLBL")}
        </Button>
      </div>
    </div>
  );
};
export default MultipleChoice;

import React, { useState, useEffect } from "react";
import "./AV_Videochannel.css";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants";
import NotFound from "../../Assets/Components/NotFound";

export const AV_ClassroomTraining = (props) => {
  const [posts, setPosts] = useState([]);
  const [postsSave, setPostsSave] = useState([]);

  const { t } = useTranslation();
  const [postsData, setPostsData] = useState([]); //Added by Saurav
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const filteredPosts = posts.filter((item, key) => {
    if (item.VC_ILT_NM != null && item.VC_ILT_CD != null) {
      return (
        item.VC_ILT_NM.toLowerCase().includes(
          String(props.search).toLowerCase()
        ) ||
        item.VC_ILT_CD.toLowerCase().includes(
          String(props.search).toLowerCase()
        )
      );
    }
  });

  const navigate = useNavigate();
  var noOfRecords;
  useEffect(() => {
    axios
      .get(
        PUBLIC_URL + "/api/ClassRoomTraining/AvailableLearning/ILTDetailList",
        {
          params: {
            corporateId:
              sessionStorage.getItem("userDomain") !== null
                ? sessionStorage.getItem("userDomain")
                : DOMAIN_ID,
          },
        }
      )
      .then((res) => {
        let temp = res.data.Table.concat(res.data.Table1);
        setPosts(temp);
        setPostsSave(temp);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
  };
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  if (posts.length === 0 && !loading) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  } else if (posts.length === 0 && loading) {	
    return <NotFound />;
  }

  function handleClickShowMore() {
    setShowMore(!showMore);
    if (showMore === true) {
      noOfRecords = 100;
    } else {
      noOfRecords = 12;

      axios
        .get(
          PUBLIC_URL + "/api/ClassRoomTraining/AvailableLearning/ILTDetailList",
          {
            params: {
              corporateId:
                sessionStorage.getItem("userDomain") !== null
                  ? sessionStorage.getItem("userDomain")
                  : DOMAIN_ID,
                  orderBy: "DT_STRT_DT",
            },
          }
        )
        .then((res) => {
          let temp = res.data.Table.concat(res.data.Table1);
          setPosts(temp);
          setPostsSave(temp);
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  if (props.search === "" || props.search == null) {
    return (
      <div style={{ margin: "30px", backgroundColor: "#111f3b" }}>
        <Slider {...settings}>
          {postsSave.map((item, index) => {
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
            let tempURL;
            if (item.VC_UPLDD_FL_NM != null) {
              tempURL =
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
                sasToken;
            } else {
              tempURL =
                "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
            }
            //
            let stringForDescription = removeTags(item.VC_DSCRPTN);
            //
            return (
              <div className="wrapper" key={index}>
                <Link
                  className="link"
                  to="/details"
                  state={{
                    course_id: item.NM_ILT_ID,

                    type: "Classroom Training",
                    course_name: item.VC_ILT_NM,
                    start_date: item.DT_STRT_DT,
                    end_date: item.DT_END_DT,
                    credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                    progress: item.NM_PRGRSS,
                    description: stringForDescription,
                    isAvailableForever: item.BT_IS_AVLBL_FRVR,
                    imgSrc: tempURL,
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM, //Added by Saurav
                  }}
                >
                  <img
                    style={{ width: "100%", height: "125px",objectFit: "cover" }}
                    src={tempURL}
                    alt=""
                  />
                </Link>
                {/* <div className="title" style={{ display: "flex" }}>
                  <p title={item.VC_ILT_NM}>
                    {item.VC_ILT_NM?.substring(0, 11)}...
                  </p>{" "}
                  |{" "}
                  <p title={item.VC_ILT_CD}>
                    {item.VC_ILT_CD?.substring(0, 11)}...
                  </p>
                </div> */}
                 <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_NM}>
                        {item.VC_ILT_NM?.substring(0, 30)}
                        {item.VC_ILT_NM?.length > 30 ? "..." : ""}
                      </p>
                     </div>
                <p className="description">
                  {stringForDescription.toString().substring(0, 40)}
                  <Link
                    className="directlink"
                    to="/details"
                    state={{
                      course_id: item.NM_ILT_ID,
                      type: "Classroom Training",
                      course_name: item.VC_ILT_NM,
                      start_date: item.DT_STRT_DT,
                      end_date: item.DT_END_DT,
                      credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                      progress: item.NM_PRGRSS,
                      description: stringForDescription,
                      isAvailableForever: item.BT_IS_AVLBL_FRVR,
                      imgSrc: tempURL,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                    }}
                  >
                    ...
                    {t("rbkey_Showmore_LBL")}
                  </Link>
                </p>
              </div>
            );
          })}
        </Slider>
        <div
          style={{
            textAlign: "right",
            paddingRight: "5px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <button
            style={{
              color: "White",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "rgb(78, 78, 78)",
              fontSize: "small",
            }}
            onClick={() => {
              navigate("/availablelearning/classroomtraining");
            }}
          >
            {t("rbkey_VwAllLBL")}
          </button>
        </div>
      </div>
    );
  }

  if (!filteredPosts?.length) {
    return <NotFound />;
  }

  return (
    <div style={{ margin: "30px", backgroundColor: "#111f3b" }}>
      <Slider {...settings}>
        {filteredPosts.map((item, index) => {
          const sasToken = SAS_TOKEN;
          const containerName = CONTAINER_NAME;
          const storageAccountName = STORAGE_ACCOUNT_NAME;
          let tempURL;
          if (item.VC_UPLDD_FL_NM != null) {
            tempURL =
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
              sasToken;
          } else {
            tempURL =
              "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
          }
          //
          let stringForDescription = removeTags(item.VC_DSCRPTN);
          //
          return (
            <div className="wrapper" key={index}>
              <Link
                className="link"
                to="/details"
                state={{
                  course_id: item.NM_ILT_ID,

                  type: "Classroom Training",
                  course_name: item.VC_ILT_CRS_LNCH_NM,
                  start_date: item.DT_STRT_DT,
                  end_date: item.DT_END_DT,
                  credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                  progress: item.NM_PRGRSS,
                  description: stringForDescription,
                  isAvailableForever: item.BT_IS_AVLBL_FRVR,
                  imgSrc: tempURL,
                  VC_ILT_CD: item.VC_ILT_CD,
                  topic_name: item.VC_ILT_KND_NM,
                }}
              >
                <img
                  style={{ width: "100%", height: "125px" ,objectFit: "cover"}}
                  src={tempURL}
                  alt=""
                />
              </Link>
              {/* <div className="title" style={{ display: "flex" }}>
                <p title={item.VC_ILT_NM}>
                  {item.VC_ILT_NM?.substring(0, 11)}...
                </p>{" "}
                |{" "}
                <p title={item.VC_ILT_CD}>
                  {item.VC_ILT_CD?.substring(0, 11)}...
                </p>
              </div> */}
               <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_NM}>
                        {item.VC_ILT_NM?.substring(0, 30)}
                        {item.VC_ILT_NM?.length > 30 ? "..." : ""}
                      </p>
                     </div>
              <p className="description">
                {stringForDescription.toString().substring(0, 40)}
                <Link
                  className="directlink"
                  to="/details"
                  state={{
                    course_id: item.NM_ILT_ID,
                    type: "Classroom Training",
                    course_name: item.VC_ILT_CRS_LNCH_NM,
                    start_date: item.DT_STRT_DT,
                    end_date: item.DT_END_DT,
                    credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                    progress: item.NM_PRGRSS,
                    description: stringForDescription,
                    isAvailableForever: item.BT_IS_AVLBL_FRVR,
                    imgSrc: tempURL,
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM,
                  }}
                >
                  ...
                  {t("rbkey_Showmore_LBL")}
                </Link>
              </p>
            </div>
          );
        })}
      </Slider>

      <div
        style={{
          textAlign: "right",
          paddingRight: "5px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <button
          style={{
            color: "White",
            border: "none",
            borderRadius: "5px",
            backgroundColor: "rgb(78, 78, 78)",
            fontSize: "small",
          }}
          onClick={() => {
            navigate("/availablelearning/classroomtraining");
          }}
        >
          {t("rbkey_VwAllLBL")}
        </button>
      </div>
    </div>
  );
};

import { React, useState, useEffect } from "react";
import image from "../Assets/Images/login-bg.jpg";
import Card from "react-bootstrap/Card";
import { useContext } from "react";
import { Contexts } from "../../contexts/Contexts";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import Popup from "./Popup/Popup";
import Footerfuso from "./Footerfuso/Footerfuso";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
  PUBLIC_URL,
  PUBLIC_URL_API2,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Components/Common/Constants.js";
import { ContactSupportOutlined } from "@mui/icons-material";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
const LoginPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tokenGenerated, settokenGenerated] = useState(false);
  const [searchParams, setsearchParams] = useSearchParams();
  const [val, setVal] = useState();
  const [optionData, setoptionData] = useState([]);
  const [elData, setElData] = useState([]);
  const [iltData, setILTData] = useState([]);
  const [pgmData, setPgmData] = useState([]);
  const [query, setQuery] = useState(searchParams.get("query"));
  const [crptId, setcrptId] = useState(
    parseInt(sessionStorage.getItem("domainId"))
  );
  let validUser = false;
  // const [validUser, setValidUser] = useState(false);
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  const { setlangMap } = useContext(Contexts);

  let filteredData = [];

  const navigate = useNavigate();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const variable = "Select Market";
  const [isTogglevalue, setIsTogglevalue] = useState(variable);

  const ToggleHandler = (e) => {
    setIsTogglevalue(e.Dropdown.Menu.Item.select);
  };

  const initialValues = { username: "", password: "", user: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // useEffect(() => {
  //   axios
  //     .get(PUBLIC_URL + "/api/Authenticate/GetAssignedUser", {
  //       params: {
  //         userId: sessionStorage.getItem("userId"),
  //         corporateId: crptId,
  //         courseId: sessionStorage.getItem("CourseId"),
  //         courseType: sessionStorage.getItem("CourseType"),
  //       },
  //     })
  //     .then((res1) => {
  //       if (res1.data.result == "Success") {
  //         setValidUser(true);
  //       } else {
  //         setValidUser(false);
  //       }
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    console.log("valid user FROM LOGIN : ", validUser);
  }, [validUser]);

  const loginHandler = (e) => {
    e.preventDefault();
    debugger;

    axios({
      method: "post",
      url: PUBLIC_URL + "/api/Authenticate/UserLogin",
      data: {
        // userID: "string",
        // password: "string",
        // corpID: "string",
        // maxConcurrentUsers: 0,
        // allowEmailLogin: true,

        corporateId: crptId,
        userName: formValues.username,
        password: formValues.password,
      },
    })
      .then((response) => {
        settokenGenerated(true);
        if (
          response.data.token != null &&
          response.data.token != "" &&
          response.data.token != undefined
        ) {
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("userId", response.data.returnDetails[3]);
          sessionStorage.setItem(
            "userFirstName",
            response.data.returnDetails[4]
          );
          sessionStorage.setItem(
            "userLastName",
            response.data.returnDetails[5]
          );
        }
      })

      .then(() => {
        axios({
          method: "GET",
          url: PUBLIC_URL + "/api/Authenticate/GetDomainLanguages",

          params: {
            CorporateId: sessionStorage.getItem("domainId"),
          },
        })
          .then((response) => {
            debugger;
            console.log(response.data);

            let result = {};

            response.data.Table.forEach((lang) => {
              let language = lang.VC_LNGG_CD.split("-").at(0);

              let label = { label: lang.VC_LNGG_NM };

              result[language] = label;
            });

            // let tempArray = [];

            // response.data.Table.forEach((element) => {
            //   tempArray.push(
            //     `{"${element.VC_LNGG_CD.split("-")[0]}":{"label":"${
            //       element.VC_LNGG_NM
            //     }"}}`
            //   );

            // let tempObj = {};
            // tempObj[`${element.VC_LNGG_CD.split("-")[0]}`] = {
            //   label: `${element.VC_LNGG_NM}`,
            // };
            // tempArray.push(JSON.stringify(tempObj));
            // });

            console.log(result);
            sessionStorage.setItem("domainLanguages", JSON.stringify(result));
            // sessionStorage.setItem("domainLanguages", '{"en":{"label":"English"},"ja":{"label":"Japanese"}}');

            setlangMap(sessionStorage.getItem("domainLanguages"));
          })
          .catch((err) => {
            console.log(err);
          });
      })

      .then(() => {
        axios
          .get(PUBLIC_URL + "/api/Authenticate/GetAssignedUser", {
            params: {
              userId: sessionStorage.getItem("userId"),
              corporateId: crptId,
              courseId: sessionStorage.getItem("CourseId"),
              courseType: sessionStorage.getItem("CourseType"),
            },
          })
          .then((res1) => {
            if (res1.data.result == "Success") {
              // setValidUser(true);
              validUser = true;
            } else {
              // setValidUser(false);
              validUser = false;
            }
          })

          .catch((err) => {
            console.log(err);
          });
      })

      .then(() => {
        const courseLaunchId = parseInt(sessionStorage.getItem("CourseId"));

        if (sessionStorage.getItem("CourseType") == "ELearning") {
          axios
            .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
              params: {
                searchType: "",
                searchText: "",
                orderBy: "VC_ILT_CRS_LNCH_NM",
                order: "ASC",
                pageNumber: 1,
                numberOfRecordsPerPage: 1000,
                corporateId: crptId,
                userId: sessionStorage.getItem("userId"),
                combineAssignedCourses: "",
                learningType: "",
                sessionId: "en-US",
                rbKeyValueProgam: "Program",
                rbKeyValueCourse: "eLearning",
                rbKeyValueILT: "Classroom Training",
                iltId: "",
                courseLaunchId: "",
              },
            })
            .then((res) => {
              debugger;
              setElData(res.data);

              // filteredData = res.data.filter((item) => {
              //   return sessionStorage.getItem("CourseId") == item.NM_CRS_LNCH_ID;
              // });

              filteredData = res.data.filter(
                (item) => item.NM_CRS_LNCH_ID === courseLaunchId
              );

              //Added by Saurav
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
              let tempURL =
                "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";

              if (
                filteredData.length > 0 &&
                filteredData[0].VC_UPLDD_FL_NM != null
              ) {
                tempURL =
                  `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${filteredData[0].NM_ILT_ID}/${filteredData[0].VC_UPLDD_FL_NM}?` +
                  sasToken;
              } else {
                tempURL =
                  "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";
              }

              debugger;

              if (validUser == true) {
                navigate("/detailsML", {
                  state: {
                    course_id: filteredData[0].NM_CRS_LNCH_ID,
                    type: filteredData[0].NM_LRNNG_TYP,
                    course_name: filteredData[0].VC_ILT_CRS_LNCH_NM,
                    start_date: filteredData[0].DT_STRT_DT,
                    end_date: filteredData[0].DT_END_DT,
                    imgSrc: tempURL, //Added by Saurav
                    credit_hours: filteredData[0].NM_ILT_CRS_CRDT_HRS,
                    progress: filteredData[0].NM_PRGRSS,
                    description: removeTags(
                      filteredData[0].VC_ILT_CRS_LNCH_DSCRPTN
                    ),
                    isAvailableForever: filteredData[0].BT_IS_AVLBL_FRVR,
                    VC_ILT_CD: filteredData[0].VC_ILT_CD,
                    topic_name: filteredData[0].VC_ILT_KND_NM,
                    certificateID: filteredData[0].NM_CRTFCT_ID,
                    DT_CMPLTN_DT: filteredData[0].DT_CMPLTN_DT,
                    certificate_id: filteredData[0].NM_CRTFCT_ID,
                  },
                });
              } else {
                navigate("/details", {
                  state: {
                    type: "eLearning",
                    validationforuser: false,
                    validationforusermessage:
                      "You have not taken this course, Please select a valid course from available learning",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (sessionStorage.getItem("CourseType") == "Program") {
          axios
            .get(PUBLIC_URL + "/api/Program/MyLearning/Program", {
              params: {
                searchType: "",
                searchText: "",
                orderBy: "VC_ILT_CRS_LNCH_NM",
                order: "ASC",
                pageNumber: 1,
                numberOfRecordsPerPage: 1000,
                corporateId: crptId,
                userId: sessionStorage.getItem("userId"),
                combineAssignedCourses: "",
                learningType: "",
                sessionId: "en-US",
                rbKeyValueProgam: "Program",
                rbKeyValueCourse: "eLearning",
                rbKeyValueILT: "Classroom Training",
                iltId: "",
                courseLaunchId: "",
              },
            })
            .then((res) => {
              debugger;
              setPgmData(res.data);
              // filteredData = res.data.filter((item) => {
              //   return sessionStorage.getItem("CourseId") == item.NM_CRS_LNCH_ID;
              // });

              filteredData = res.data.filter(
                (item) => item.NM_CRS_LNCH_ID === courseLaunchId
              );

              //Added by Saurav
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
              let tempURL =
                "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";

              if (
                filteredData.length > 0 &&
                filteredData[0].VC_UPLDD_FL_NM != null
              ) {
                tempURL =
                  `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${filteredData[0].NM_ILT_ID}/${filteredData[0].VC_UPLDD_FL_NM}?` +
                  sasToken;
              } else {
                tempURL =
                  "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";
              }

              if (validUser == true) {
                navigate("/detailsML", {
                  state: {
                    course_id: filteredData[0].NM_CRS_LNCH_ID,
                    type: filteredData[0].NM_LRNNG_TYP,
                    course_name: filteredData[0].VC_ILT_CRS_LNCH_NM,
                    start_date: filteredData[0].DT_STRT_DT,
                    end_date: filteredData[0].DT_END_DT,
                    credit_hours: filteredData[0].NM_ILT_CRS_CRDT_HRS,
                    progress: filteredData[0].NM_PRGRSS,
                    description: removeTags(
                      filteredData[0].VC_ILT_CRS_LNCH_DSCRPTN
                    ),
                    isAvailableForever: filteredData[0].BT_IS_AVLBL_FRVR,
                    imgSrc: tempURL, //Added by Saurav
                    VC_ILT_CD: filteredData[0].VC_ILT_CD,
                    topic_name: filteredData[0].VC_ILT_KND_NM,
                    certificateID: filteredData[0].NM_CRTFCT_ID,
                    DT_CMPLTN_DT: filteredData[0].DT_CMPLTN_DT,
                    certificate_id: filteredData[0].NM_CRTFCT_ID,
                  },
                });
              } else {
                navigate("/details", {
                  state: {
                    type: "Program",
                    validationforuser: false,
                    validationforusermessage:
                      "You have not taken this course, Please select a valid course from available learning",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          sessionStorage.getItem("CourseType") == "Classroom Training"
        ) {
          axios
          .get(PUBLIC_URL + "/api/ClassRoomTraining/ILTDetails", {
            params: {
              userId: sessionStorage.getItem("userId"),
              iLTId: courseLaunchId,
              sessionId: "en-US",
            },
            })
            .then((res) => {
              setILTData(res.data);

            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
                let tempURL;
            if (res.data.Table[0].VC_UPLDD_FL_NM != null) {
              tempURL =
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${courseLaunchId}/${res.data.Table[0].VC_UPLDD_FL_NM}?` +
                sasToken;
            } else {
              tempURL =
                "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
            }
            navigate("/details", {
              state: {
                    course_id: courseLaunchId,
                    type: "Classroom Training",
                    course_name: res.data.Table[0].VC_ILT_NM,
                    start_date: res.data.Table[0].DT_STRT_DT,
                    end_date: res.data.Table[0].DT_END_DT,
                    credit_hours: res.data.Table[0].NM_ILT_CRS_CRDT_HRS,
                    progress: res.data.Table[0].NM_PRGRSS,
                    description: res.data.Table[0].VC_DSCRPTN,
                    isAvailableForever: res.data.Table[0].BT_IS_AVLBL_FRVR,
                    topic_name: res.data.Table[0].VC_ILT_KND_NM, //Added by Saurav
                    imgSrc: tempURL,
                    VC_ILT_CD: res.data.Table[0].VC_ILT_CD,
              },
            });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // if (
    //   sessionStorage.getItem("token") != null &&
    //   sessionStorage.getItem("token") != "" &&
    //   sessionStorage.getItem("token") != undefined
    // ) {

    // else
    //   navigate("/detailsML", {
    //     state: {
    //       course_id: props.props.cr_id,
    //       course_type: sessionStorage.getItem("CourseType"),
    //     },
    //   });
    // }
  };

  let post1 = [];
  useEffect(() => {
    axios
      .get(PUBLIC_URL_API2 + "/api/Authenticate/GetDomainList")

      .then((res) => {
        post1 = res.data.Table;
        setoptionData(post1);
      })

      .then(() => {
        debugger;
        if (post1.length > 0 && post1 != undefined) {
          let selectedDomain = post1.filter(
            (item) =>
              item.NM_CRPRT_ID === parseInt(sessionStorage.getItem("domainId"))
          );
          if (selectedDomain.length > 0) {
            setVal(selectedDomain[0].VC_CRPRT_NM);
          }
        }
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  var i;
  var Datacapture = new Array();

  for (i = 0; i < optionData.length; i++) {
    Datacapture[i] = optionData[i].VC_CRPRT_NM;
  }

  var select = document.getElementById("select");

  var arr = new Array();
  for (var i = 0; i < optionData.length; i++) {
    arr[i] = optionData[i].VC_CRPRT_NM;
  }
  for (var i = 0; i < arr.length; i++) {
    var option = document.createElement("OPTION"),
      txt = document.createTextNode(arr[i]);
    option.appendChild(txt);
  }

  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    }
    if (values.username && values.password) {
      if (
        values.username !== formValues.username &&
        values.password !== formValues.password
      ) {
        errors.user = "Incorrect Username or Password";
      }
    }

    return errors;
  };

  useEffect(() => {
    console.log("option data : ", optionData);
    console.log("value is : ", val);
    console.log("corporate id : ", crptId);
  }, [optionData, val, crptId]);

  useEffect(() => {
    let tempArray = optionData.filter((item) => {
      return item.VC_CRPRT_NM === val;
    });
    if (tempArray != undefined && tempArray.length > 0) {
      let localCrptId = tempArray[0].NM_CRPRT_ID;
      setcrptId(localCrptId);
      sessionStorage.setItem("domainId", localCrptId);
      sessionStorage.setItem("userDomain", localCrptId);
    }
  }, [val]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0px -80px",
          height: "90.45vh",
        }}
      >
        <div className="leftalign">
          <input
            type="button"
            style={{
              color: "white",
              backgroundColor: "rgba(36, 36, 36, 0.01)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "relative",
              borderWidth: 0,
              fontWeight: "less-bold",
              marginRight: "3px",
            }}
            value="CONTACT US"
            onClick={togglePopup}
          />
          <button
            style={{
              color: "orange",
              backgroundColor: "rgba(36, 36, 36, 0.01)",
              borderWidth: 0,
              marginRight: "2px",
              fontWeight: "bold",
            }}
          >
            -A
          </button>

          <button
            style={{
              color: "orange",
              backgroundColor: "rgba(36, 36, 36, 0.01)",
              borderWidth: 0,
              marginRight: "2px",
              fontWeight: "bold",
            }}
          >
            A
          </button>
          <button
            style={{
              color: "orange",
              backgroundColor: "rgba(36, 36, 36, 0.01)",
              borderWidth: 0,
              fontWeight: "bold",
            }}
          >
            +A
          </button>
          <select
            name="languageselect"
            id="language"
            label="Select Language"
            style={{ width: "75px" }}
          >
            <option value="English">English</option>
            <option value="Japanese">Japanese</option>
          </select>
        </div>
        {isOpen && (
          <Popup
            content={
              <div>
                <h1>
                  <b>Contact Us</b>
                </h1>
                <p>
                  <h5>www.google.com</h5>
                </p>
                <button onClick={togglePopup}>Close</button>
              </div>
            }
            handleClose={togglePopup}
          />
        )}

        <Card
          style={{
            //width: "35rem",
            //height: "24.5rem",
            width: "45%",
            height: "66%",
            backgroundColor: "rgba(0,0,0,0.7)",
            position: "absolute",
            left: "51%",
            top: "44%",
            transform: "translate(-50%, -50%)",
            opacity: "0.8",
            columnGap: "40px",
            zIndex: "0",
          }}
        >
          <Card.Body>
            <Card.Title
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              FUSO HORIZON
            </Card.Title>
            <p
              style={{ color: "white", textAlign: "center", marginBottom: -2 }}
            >
              {val}
            </p>
            <Card.Subtitle
              style={{
                color: "white",
                fontSize: "14px",
                textAlign: "left",
                paddingTop: "10px",
                fontWeight: 500,
                paddingBottom: 10,
              }}
            >
              Please enter your User ID and password to login{" "}
            </Card.Subtitle>
            <hr style={{ marginBottom: 0 }} />

            <p
              style={{
                color: "red",
                marginTop: 0,
                marginBottom: 0,
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {formErrors.user}
            </p>

            <Card.Text>
              <select
                style={{
                  width: "89%",
                  height: 18,
                  borderRadius: 2,
                  marginBottom: 15,
                  marginLeft: 25,
                  borderWidth: 0,
                }}
                value={val}
                onChange={(e) => {
                  setVal(e.target.value);
                }}
              >
                {" "}
                {arr.map((item) => (
                  <option> {item}</option>
                ))}
              </select>
              <input
                type="text"
                style={{
                  width: "89%",
                  height: 28,
                  borderRadius: 2,
                  marginLeft: 25,
                  borderWidth: 0,
                  backgroundColor: "white",
                }}
                placeholder="User ID"
                name="username"
                value={formValues.username}
                onChange={handleChange}
              ></input>
              <p style={{ color: "red", marginTop: 0, marginLeft: 25 }}>
                {formErrors.username}
              </p>
              <input
                type="password"
                style={{
                  // width: 477,
                  // height: 25,
                  width: "89%",
                  height: 28,
                  borderRadius: 2,
                  marginBottom: 5,
                  backgroundColor: "white",
                  marginLeft: 25,
                  borderWidth: 0,
                }}
                placeholder="Password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
              ></input>
              <p style={{ color: "red", marginBottom: 5, marginLeft: 25 }}>
                {formErrors.password}
              </p>

              <button
                style={{
                  width: 75,
                  height: 35,
                  borderRadius: 5,
                  marginTop: 10,
                  marginBottom: 10,
                  color: "white",
                  backgroundColor: "red",
                  fontWeight: "bold",
                  marginLeft: 25,
                  opacity: "0.9",
                }}
                onClick={loginHandler}
              >
                LOGIN
              </button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <Footerfuso />
    </div>
  );
};

export default LoginPage;

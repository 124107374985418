import "./Home.scss";
import NavbarVC from "../../components/navbar/navbar";
import Featured from "../../components/featured/Featured";
import { List } from "../../components/list/List";
import { useContext, useEffect, useState } from "react";
import Category from "../../components/Category/Category";
import { Contexts } from "../../../contexts/Contexts";
import NotFound from "../../../ELearning/Assets/Components/NotFound";
import { useTranslation } from "react-i18next";
import Navbar from "../../../ELearning/Pages/NavBar";
import { Audio } from "react-loader-spinner";
import { PUBLIC_URL_API2 } from "./../../../ELearning/Components/Common/Constants.js";
import { ContactSupportOutlined } from "@mui/icons-material";
import {Link} from "react-router-dom";

const Home = (props) => {
  const [toDisplay, settoDisplay] = useState("");
  const [items, setItems] = useState([]);
  const [saleitems, setsaleItems] = useState([]);
  const [ecantersalesitems, setecantersalesitems] = useState([]);
  const [seeAllFlag, setseeAllFlag] = useState(false);
  const [categoryItems, setcategoryItems] = useState();
  const [title, setTitle] = useState();
  const [elseCategoryItems, setElseCategoryItems] = useState();
  const [elseCategoryItems1, setElseCategoryItems1] = useState();
  const [continueWatchingItems, setcontinueWatchingItems] = useState([]);
  const [watchItAgainItems, setwatchItAgainItems] = useState([]);
  const { dropdownItems } = useContext(Contexts);
  const { searchField } = useContext(Contexts);
  const { languageSelected } = useContext(Contexts);
  const [isLoaded, setisLoaded] = useState(false);
  const { options } = useContext(Contexts);
  const { setoptions } = useContext(Contexts);
  let row1CatId = 0;
  let row2CatId = 0;
  let row1CatName = "";
  let row2CatName = "";

  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.token}` },
  };

  if (options.length > 0) {
    row1CatId = options[1]?.NM_CATEGORY_ID;
    row2CatId = options[2]?.NM_CATEGORY_ID;
    row1CatName = options[1]?.VC_CATEGORY_NAME;
    row2CatName = options[2]?.VC_CATEGORY_NAME;
  }

  // this is for setting the model in seeall
  useEffect(() => {
    setData();
    console.log(saleitems);
  }, [props.state, title]);

  const setData = () => {
    console.log(props.state);
    if (props.state === "Sales") {
      setcategoryItems(saleitems);
    } else if (props.state === "E-Canter Sales") {
      setcategoryItems(ecantersalesitems);
    } else if (props.state === "eCanter electrics") {
      setcategoryItems(saleitems);
    } else if (props.state === "Safety Devices") {
      setcategoryItems(saleitems);
    } else if (props.state === "Customer Service") {
      setcategoryItems(saleitems);
    } else if (props.state === "Parts Specialist") {
      setcategoryItems(saleitems);
    } else if (props.state === "Service Advisor") {
      setcategoryItems(saleitems);
    } else if (props.state === "Watch it again") {
      setcategoryItems(watchItAgainItems);
    } else if (props.state === "Continue Watching") {
      setcategoryItems(continueWatchingItems);
    } else {
      console.log("else items");
      if (elseCategoryItems !== undefined) {
        console.log(elseCategoryItems);
        if (searchField !== "" && searchField !== null) {
          setcategoryItems(elseCategoryItems.includes(searchField));
        } else {
          setcategoryItems(elseCategoryItems);
        }
      }
    }
  };

  const updateElseCategoryItems = (elseCategoryItems) => {
    if (elseCategoryItems !== undefined) {
      console.log(elseCategoryItems);
      setcategoryItems(elseCategoryItems);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(
        PUBLIC_URL_API2 +
          "/api/Podcast/" +
          sessionStorage.getItem("userId") +
          `/` +
          languageSelected,
        config
      );
      const json = await result.json();
      let filteredArray = json.filter((subItem) => {
        if(subItem.VC_VSTRM_URL != null)
          {
        return (
          subItem.NM_CRPRT_ID === parseInt(sessionStorage.getItem("domainId")  )
        );
      }
      });
      setItems(filteredArray);
    };
    fetchData();
  }, [languageSelected]);

  useEffect(() => {
    const url =
      PUBLIC_URL_API2 +
      "/api/Podcast/Podcast4Category/" +
      row1CatId +
      "/" +
      sessionStorage.getItem("userId") +
      `/` +
      languageSelected;
    const fetchDataForSales = async () => {
      const result = await fetch(url, config);

      const json = await result.json();
      let filteredArray = json.filter((subItem) => {
        return (
          subItem.VC_VSTRM_URL != null
        );
      });
      setsaleItems(filteredArray);
      setElseCategoryItems(json);
      console.log(json);
    };
    fetchDataForSales();
  }, [languageSelected, options]);

  useEffect(() => {
    const fetchDataForContinueWatching = async () => {
      const result = await fetch(
        PUBLIC_URL_API2 +
          "/api/Podcast/Resume/" +
          sessionStorage.getItem("userId") +
          `/` +
          languageSelected,
        config
      );
      const json = await result.json();
      let filteredArray = json.filter((subItem) => {
        return (
          subItem.NM_CRPRT_ID === parseInt(sessionStorage.getItem("domainId"))
        );
      });
      setcontinueWatchingItems(filteredArray);
    };
    fetchDataForContinueWatching();
  }, [languageSelected]);

  useEffect(() => {
    const fetchDataForWatchItAgain = async () => {
      const result = await fetch(
        PUBLIC_URL_API2 +
          "/api/Podcast/RecentlyPlayed/" +
          sessionStorage.getItem("userId") +
          `/` +
          languageSelected,
        config
      );
      const json = await result.json();
      let filteredArray = json.filter((subItem) => {
        return (
          subItem.NM_CRPRT_ID === parseInt(sessionStorage.getItem("domainId"))
        );
      });
      setwatchItAgainItems(filteredArray);
    };
    fetchDataForWatchItAgain();
  }, [languageSelected]);

  useEffect(() => {
    const url =
      PUBLIC_URL_API2 +
      "/api/Podcast/Podcast4Category/" +
      row2CatId +
      "/" +
      sessionStorage.getItem("userId") +
      `/` +
      languageSelected;
    const fetchDataforECanterSales = async () => {
      const result = await fetch(url, config);
      const json = await result.json();
      let filteredArray = json.filter((subItem) => {
        return (
          subItem.VC_VSTRM_URL != null
        );
      });
      setecantersalesitems(filteredArray);
    };
    fetchDataforECanterSales();
  }, [languageSelected, options]);

  useEffect(() => {
    if (props.state !== "") {
      settoDisplay(props.state);
    } else {
      settoDisplay("");
    }
  }, [props]);

  const { t } = useTranslation();

  useEffect(() => {
    setisLoaded(true);
    setTimeout(() => {
      setisLoaded(false);
    }, 5000);
  }, []);
  return (
    <>
      {isLoaded ? (
        <div
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="60" width="75" color="grey" arialLabel="Loading" />
          </div>
        </div>
      ) : (
        <div className="home">
          <Navbar />
          <NavbarVC
            type="Categories"
            state={props.state}
            setState={props.setState}
            searchItems={items}
            setElseCategoryItems={(json) => updateElseCategoryItems(json)}
            setElseCategoryItems1={(title) => setTitle(title)}
          />
          <Featured items={items} />

          {!seeAllFlag ? (
            <>
              {toDisplay === "" ? (
                <>
                  <List
                    type={t("rbkey_RcntlyAddLBL")}
                    items={items.filter((item, key) => {
                      if (searchField === "" || searchField === null) {
                        return item;
                      } else if (
                        item.VC_PDCST_NM.toLowerCase().includes(
                          searchField.toLowerCase()
                        )
                      ) {
                        return item;
                      }
                    })}
                    showNotFound={true}
                  />
                  {continueWatchingItems != null &&
                    continueWatchingItems != undefined && (
                      <>
                        <List
                          type={t("rbkey_CntnueWatching")}
                          items={continueWatchingItems.filter((item, key) => {
                            if (searchField === "" || searchField === null) {
                              return item;
                            } else if (
                              item.VC_PDCST_NM.toLowerCase().includes(
                                searchField.toLowerCase()
                              )
                            ) {
                              return item;
                            }
                          })}
                          state={props.state}
                          setState={props.setState}
                          setseeAllFlag={setseeAllFlag}
                          showNotFound={false}
                        />
                      </>
                    )}

                  {watchItAgainItems !== null &&
                    watchItAgainItems !== undefined && (
                      <>
                        <List
                          type={t("rbkey_WtchItAgn")}
                          items={watchItAgainItems.filter((item, key) => {
                            if (searchField === "" || searchField === null) {
                              return item;
                            } else if (
                              item.VC_PDCST_NM.toLowerCase().includes(
                                searchField.toLowerCase()
                              )
                            ) {
                              return item;
                            }
                          })}
                          state={props.state}
                          setState={props.setState}
                          setseeAllFlag={setseeAllFlag}
                          showNotFound={false}
                        />
                      </>
                    )}

                  {saleitems !== null &&
                    saleitems !== undefined &&
                    saleitems.length > 0 && 
                    row1CatName!=undefined &&
                    (
                      <List
                        id="row2"
                        type={
                          row1CatName !== null && row1CatName !== undefined
                            ? row1CatName.toString()
                            : row1CatName
                        }
                        items={
                          saleitems !== null && saleitems !== undefined
                            ? saleitems?.filter((item, key) => {
                                if (
                                  searchField === "" ||
                                  searchField === null
                                ) {
                                  return item;
                                } else if (
                                  item.VC_PDCST_NM.toLowerCase().includes(
                                    searchField.toLowerCase()
                                  )
                                ) {
                                  return item;
                                }
                              })
                            : saleitems
                        }
                        state={props.state}
                        setState={props.setState}
                        setseeAllFlag={setseeAllFlag}
                        showNotFound={false}
                      />
                      
                    )}
                  {ecantersalesitems !== null &&
                    ecantersalesitems !== undefined &&
                    ecantersalesitems.length > 0 && (
                      <List
                        id="row3"
                        type={
                          row2CatName !== null && row2CatName !== undefined
                            ? row2CatName.toString()
                            : row2CatName
                        }
                        items={
                          ecantersalesitems !== null &&
                          ecantersalesitems !== undefined
                            ? ecantersalesitems?.filter((item, key) => {
                                if (
                                  searchField === "" ||
                                  searchField === null
                                ) {
                                  return item;
                                } else if (
                                  item.VC_PDCST_NM.toLowerCase().includes(
                                    searchField.toLowerCase()
                                  )
                                ) {
                                  return item;
                                }
                              })
                            : ecantersalesitems
                        }
                        state={props.state}
                        setState={props.setState}
                        setseeAllFlag={setseeAllFlag}
                        showNotFound={false}
                      />
                    )}

                  {/* dyanamic list which has top 2 from the category dropdown starts */}

                  {/* <List
                      type={props.state}
                      items={dropdownItems.filter((item, key) => {
                        if (searchField === "" || searchField === null) {
                          return item;
                        } else if (
                          item.VC_PDCST_NM.toLowerCase().includes(
                            searchField.toLowerCase()
                          )
                        ) {
                          return item;
                        }
                      })}
                      state={props.state}
                      setState={props.setState}
                      setseeAllFlag={setseeAllFlag}
                      showNotFound={true}
                    /> */}

                  {/* dyanamic list which has top 2 from the category dropdown ends */}
                </>
              ) : (
                <>
                  {dropdownItems.length > 0 ? (
                    <List
                      type={props.state}
                      items={dropdownItems.filter((item, key) => {
                        if (searchField === "" || searchField === null) {
                          return item;
                        } else if (
                          item.VC_PDCST_NM.toLowerCase().includes(
                            searchField.toLowerCase()
                          )
                        ) {
                          return item;
                        }
                      })}
                      state={props.state}
                      setState={props.setState}
                      setseeAllFlag={setseeAllFlag}
                      showNotFound={true}
                    />
                  ) : (
                    <NotFound message={t("rbkey_NoDataFound")} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
            <Link style={{color:"white", paddingLeft:"10px"}} reloadDocument to="/videochannel"> {t("rbkey_BckLBL")} </Link>
              <Category
                items={
                  title !== null && title !== undefined
                    ? categoryItems?.filter((item) => {
                        return item.VC_PDCST_NM?.includes(title);
                      })
                    : categoryItems
                }
                type={props.state}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Home;

import React from "react";
import "./ContactUs.module.scss";
import Navbar from "../../Pages/NavBar";
import truck from "../../Assets/Images/truck.png";
import { makeStyles } from "@material-ui/core/styles";
import { UnderConstruction } from "../../Pages/UnderConstruction";
import classNames from "classnames";
import contactusStyles from "./ContactUs.module.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({

    fixedImage: {
        width: "100%",
        backgroundColor: "#111f3b",
        //backgroundImage: `url(${truck})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        backgroundfilter: "blur(5px)",
        marginTop: "-1px",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          //backgroundImage: `url(${truck})`,
          backgroundColor: "#111f3b",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          backgroundfilter: "blur(5px)",
          marginTop: "1px",
        },
      },
    
}));

export const ContactUs = () => {
  const classes = useStyles(); 
   const { t } = useTranslation();
  return (
    <div  className={classes.fixedImage}>
    <Navbar />
    <div className={contactusStyles.ribbon}>{t("rbkey_CntctUsLBL")}</div>
    <div className={contactusStyles.message}>
    <p style={{paddingLeft:"2.5%", color:"white"}}> {t("rbkey_CntctUsMsg")} </p> 
     <a href="mailto:FUSO_academy@daimlertruck.com" style={{paddingLeft:"2.5%", color:"#00c0ff"}}>FUSO_academy@daimlertruck.com</a>
     </div>
    </div>

   
  );
};

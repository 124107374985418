import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import FillUps from "./FillUps";
import Match from "./Match";
import TrueFalse from "./TrueFalse";
import MultipleSelect from "./MultipleSelect";
import MultipleChoice from "./MultipleChoice";
import SubmitAssessment from "./SubmitAssessment";
import DescriptiveQuestion from "./DescriptiveQuestion";
import { useNavigate, useLocation } from "react-router-dom";
import { BsAlarmFill } from "react-icons/bs";
import axios from "axios";
import { useRef } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import { useTranslation } from "react-i18next";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
} from "./../../Common/Constants.js";
let number = 1;
let questionType;
let index = 0;

const Quiz = (props) => {
  const [showFinalResults, setFinalResults] = useState(false);
  const [score, setScore] = useState(0);
  const [num, setNum] = useState(1);
  const [questionId, setQuestionId] = useState();
  const [showTrueFalse, setshowTrueFalse] = useState(false);
  const [showMultipleChoice, setshowMultipleChoice] = useState(false);
  const [showMultipleSelect, setshowMultipleSelect] = useState(false);
  const [showFillup, setshowFillup] = useState(false);
  const [showDescriptive, setshowDescriptive] = useState(false);
  const [showMatch, setshowMatch] = useState(false);
  const [isLastQue, setIsLastQue] = useState(false);
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();
  let resumeTime;

  let { isCancelClickedFromAssessmentTypes } = useContext(Contexts);

  useEffect(() => {
    debugger;
    //console.log("button",location.state.button_type,location.state.resume_question,location.state.time,location.state)
    if (location.state.time == null || location.state.time == "00:00:00") {
      setShowTimer(false);
    }
    if (location.state.resume_question != undefined) {
      if (location.state.button_type == "start") {
        setNum(1);
        index = 0;
        number = 1;
        console.log("start", index, num, number);
      } else {
        setNum(location.state.resume_question);
        index = location.state.resume_question - 1;
        number = location.state.resume_question;
      }
    }
  }, []);

  useEffect(() => {
    if (isCancelClickedFromAssessmentTypes) {
      number = 1;
      questionType = null;
      index = 0;
    }
  }, [isCancelClickedFromAssessmentTypes]);

  useEffect(() => {
    setQuestionId(location?.state?.questionList?.Table[index]?.NM_QSTN_ID);
    console.log(questionId, "qsnId");

    questionType = location.state.question_type;
    console.log(questionType);
    if (questionType === 1) {
      setshowTrueFalse(true);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 2) {
      setshowTrueFalse(false);
      setshowFillup(true);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 3) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(true);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 4) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(true);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 5) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(true);
      setshowDescriptive(false);
    } else if (questionType === 7) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(true);
    }

    // if (location.state.noOfQuestions ==)
  }, []); // added the square brackets here
  // Saurav edited from here
  let getTime = (time) => {
    let retTime = "";

    if (time !== null && time !== undefined) {
      for (let i = 0; i < time.length; i++) {
        if (time[i] != " ") retTime += time[i];
      }
      retTime += ":00";
    }
    return retTime;
  };
  let convertIntoSeconds = (time) => {
    let sec = 0;
  if (time !== null && time !== undefined) {
    sec += ((time[0] - "0") * 10 + (time[1] - "0")) * 3600;
    sec += ((time[3] - "0") * 10 + (time[4] - "0")) * 60;
    sec += (time[6] - "0") * 10 + (time[7] - "0");
  }
    return sec;
  
  };

  let getPercentageCompleted = () => {
    let totalTimeInSec = convertIntoSeconds(getTime(location.state.time));
    let secRemaining = convertIntoSeconds(timer);
    let p = (secRemaining * 100) / totalTimeInSec;
    return p;
  };
  let convertIntoHoursAndMin = (timer) => {
    location.state.remainingTime = timer;
    return timer;
  };
  const Ref = useRef(null);

  // The state for our timer
  // const [timer, setTimer] = useState("00:00:00");
  const [timer, setTimer] = useState(
    location.state.remainingTime === null
      ? "00:00:00"
      : location.state.remainingTime
  );
  const [timePercent, setPercent] = useState(100.0);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    debugger;
    if (location.state.button_type == "resume") {
      setTimer(location.state.time);
    }
    // if(location.state.button_type=="resume")
    // {
    //   location.state.time=resumeTime;
    //   location.state.remainingTime=resumeTime

    // }
    else setTimer(getTime(location.state.time));

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(
      deadline.getSeconds() + convertIntoSeconds(getTime(location.state.time))
    );
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    setPercent(getPercentageCompleted());
  }, []); // added the square brackets here
  // it ended here.

  const handleClickPrev = () => {
    debugger;
    if (!isQuestionAnswered) {
      // if (questionType === 3) {
      //   swal({
      //     title: "Error",
      //     text: "Please select an option to save!",
      //     icon: "error",
      //   });
      //   return;
      // }
    }
    //number = number - 1;
    number = num - 1;
    // setnumber((prevValue) => prevValue -1);

    //to set the index of question
    // if (index > 0) {
    //   index = index - 1;
    // }
    index = number - 1;

    if (num >= 2) {
      setNum(num - 1);
    }

    if (number === location.state.noOfQuestions) {
      setIsLastQue(true);
    } else {
      setIsLastQue(false);
    }

    questionType = location.state.questionList.Table[index].NM_QSTN_TYP;

    setQuestionId(location.state.questionList.Table[index].NM_QSTN_ID);

    location.state.question_id =
      location.state.questionList.Table[index].NM_QSTN_ID;
    location.state.question_type =
      location.state.questionList.Table[index].NM_QSTN_TYP;

    if (num <= location.state.noOfQuestions) {
      setFinalResults(false);
    } else {
      setFinalResults(true);
    }
    if (questionType === 1) {
      setshowTrueFalse(true);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 2) {
      setshowTrueFalse(false);
      setshowFillup(true);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 3) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(true);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 4) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(true);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 5) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(true);
      setshowDescriptive(false);
    } else if (questionType === 7) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(true);
    }
    console.log(
      showTrueFalse,
      showFillup,
      showMultipleChoice,
      showMultipleSelect,
      showMatch,
      showDescriptive,
      location.state.question_type,
      questionType
    );
  };

  const handleClickNext = () => {
    debugger;
    console.log("number", number, index, num);
    if (!isQuestionAnswered) {
      // if (questionType === 3) {
      //   swal({
      //     title: "Error",
      //     text: "Please select an option to save!",
      //     icon: "error",
      //   });
      //   return;
      // }
    }

    //number = number + 1;
    number = num + 1;
    // setnumber((prevValue) => prevValue + 1);
    setNum(num + 1);

    //to set the index of question
    //index = index + 1;
    index = num;

    // console.log("number", number);

    if (number === location.state.noOfQuestions) {
      setIsLastQue(true);
    } else {
      setIsLastQue(false);
    }

    if (num + 1 <= location.state.noOfQuestions) {
      questionType = location.state.questionList.Table[index].NM_QSTN_TYP;

      setQuestionId(location.state.questionList.Table[index].NM_QSTN_ID);

      location.state.question_id =
        location.state.questionList.Table[num].NM_QSTN_ID;
      location.state.question_type =
        location.state.questionList.Table[num].NM_QSTN_TYP;

      setFinalResults(false);
    } else {
      setFinalResults(true);
    }
    if (questionType === 1) {
      setshowTrueFalse(true);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 2) {
      setshowTrueFalse(false);
      setshowFillup(true);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 3) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(true);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 4) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(true);
      setshowMatch(false);
      setshowDescriptive(false);
    } else if (questionType === 5) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(true);
      setshowDescriptive(false);
    } else if (questionType === 7) {
      setshowTrueFalse(false);
      setshowFillup(false);
      setshowMultipleChoice(false);
      setshowMultipleSelect(false);
      setshowMatch(false);
      setshowDescriptive(true);
    }
    console.log(
      showTrueFalse,
      showFillup,
      showMultipleChoice,
      showMultipleSelect,
      showMatch,
      showDescriptive,
      location.state.question_type,
      questionType,
      showFinalResults
    );
  };

  if (showFinalResults === false) {
    if (showMatch === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span> */}
                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
                {/* <div
                  style={{
                    maxHeight: 50,
                    maxWidth: 50,
                    marginLeft: 940,
                    marginTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  <CircularProgressbar
                    value={timePercent}
                    // text={`${percentage}%`}
                    text={convertIntoHoursAndMin(timer)}
                    sx={{ maxHeight: 50, maxWidth: 50 }}
                  />
                </div> */}
              </div>
            </div>

            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <Match
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (showTrueFalse === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{marginRight: 55 }}>
                  Time left:
                </span> */}
                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <TrueFalse
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
              isQuestionAnswered={setIsQuestionAnswered}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (showMultipleSelect === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span> */}

                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <MultipleSelect
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (showDescriptive === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span> */}

                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <DescriptiveQuestion
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={questionId}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (showFillup === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span> */}

                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <FillUps
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (showMultipleChoice === true) {
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span> */}
                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -10,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <MultipleChoice
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              isQuestionAnswered={setIsQuestionAnswered}
              number={number}
              questionType={questionType}
              index={index}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div className={assessmentStyles.quiz}>
          <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>
          {/* <h2>current score : 2</h2> */}

          <div className={assessmentStyles.questioncard}>
            <div style={{ textAlign: "right" }}>
              <div>
                {/* <BsAlarmFill />{" "}
                <span style={{ backgroundColor: "skyblue", marginRight: 55 }}>
                  Time left:
                </span>
                <div
                  style={{
                    maxHeight: 50,
                    maxWidth: 50,
                     marginLeft: "auto",
                    marginTop: -35,
                    fontWeight: "bold",
                  }}
                >
                  <CircularProgressbar
                    value={timePercent}
                    // text={`${percentage}%`}
                    text={convertIntoHoursAndMin(timer)}
                    sx={{ maxHeight: 50, maxWidth: 50 }}
                  />
                </div> */}
                {showTimer ? (
                  <div
                    style={{
                      maxHeight: 50,
                      maxWidth: 50,
                      marginLeft: "auto",
                      marginTop: -35,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgressbar
                      value={timePercent}
                      // text={`${percentage}%`}
                      text={convertIntoHoursAndMin(timer)}
                      sx={{ maxHeight: 50, maxWidth: 50 }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <h6>
              Question {num} out of {location.state.noOfQuestions}
            </h6>
            <MultipleChoice
              nodeId={location.state.node_id}
              courseLaunchId={location.state.course_id}
              questionId={location.state.question_id}
              questionNo="1"
              number={number}
              questionType={questionType}
              index={index}
            />

            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ float: "left" }}>
                {num !== 1 && (
                  <Button variant="contained" onClick={handleClickPrev}>
                    {t("rbkey_PrvsLBL")}
                  </Button>
                )}
              </div>
              <div style={{ float: "right" }}>
                <Button variant="contained" onClick={handleClickNext}>
                {t("rbkey_NxtLBL")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
  } else {
    return (
      <div className={assessmentStyles.quiz}>
        <h6 className={assessmentStyles.headercard}>ASSESSMENT</h6>
        {/* <h2>current score : 2</h2> */}

        <div className={assessmentStyles.questioncard}>
          <SubmitAssessment
            node_id={location.state.node_id}
            course_id={location.state.course_id}
          />
        </div>
      </div>
    );
  }
};

export default Quiz;

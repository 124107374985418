import React from "react";
import "./Popuptabvc.css";

const Popuptabvc = (props) => {
  return (
    <div className="popup-box2">
      <div className="box2">
        {/* <span className="close-icon1" onClick={props.handleClose}>
          x
        </span> */}
        <div>{props.content}</div>
      </div>
    </div>
  );
};

export default Popuptabvc;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import truck from "../../Assets/Images/truck.png";
import Navbar from "../../Pages/NavBar.jsx";
import axios from "axios";
import "./AV_Videochannel.css";
import { TfiAngleDoubleDown, TfiAngleDoubleUp } from "react-icons/tfi";
import { Audio } from "react-loader-spinner";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import NotFound from "../../Assets/Components/NotFound";
export const AV_All_Program = () => {
  var nominationStatus;
  const { searchField } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [postsData, setPostsData] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [searchValueOnEnter, setSearchValueOnEnter] = useState([]);

  const filteredPosts = posts.filter((item) => {
    return (
      item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      ) ||
      item.VC_ILT_CD?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      )
    );
  });
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, " ");
  }
  const pageCount = Math.ceil(filteredPosts.length / 16);

  useEffect(() => {
    if (page > pageCount && pageCount) {
      setPage(pageCount);
    }
  }, [pageCount, page]);

  useEffect(() => {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL +
          "/api/Program/AvailableLearning/Program?naRbkey=NA&searchType=L&orderBy=VC_ILT_CRS_LNCH_NM&order=ASC&pageNumber=1&corporateId=" +
          domain_id +
          "&numberOfRecordsPerPage=100&userId=" +
          sessionStorage.getItem("userId") +
          "&isJobRoleRequired=TRUE&isLocationRequired=FALSE&rbKeyValueProgam=Program&rbKeyValueCourse=eLearning&rbKeyValueILT=Classroom%20Training&sessionId=en-US"
      )
      .then((res) => {
        setPosts(res.data);
        setPostsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      	
    setLoading(true);
  }, []);

  const courses = [];
  const courseList = posts.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    if (typeof course.VC_ILT_CD == "string") {
      courses.push(course.VC_ILT_CD);
    }
  });

  function sortDate() {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL +
          "/api/Program/AvailableLearning/Program?naRbkey=NA&searchType=L&orderBy=DT_STRT_DT&order=ASC&pageNumber=1&corporateId=" +
          domain_id +
          "&numberOfRecordsPerPage=100&userId=" +
          sessionStorage.getItem("userId") +
          "&isJobRoleRequired=TRUE&isLocationRequired=FALSE&rbKeyValueProgam=Program&rbKeyValueCourse=eLearning&rbKeyValueILT=Classroom%20Training&sessionId=en-US"
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      	
    setLoading(true);
  }

  function sortName() {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL +
          "/api/Program/AvailableLearning/Program?naRbkey=NA&searchType=L&orderBy=VC_ILT_CRS_LNCH_NM&order=ASC&pageNumber=1&corporateId=" +
          domain_id +
          "&numberOfRecordsPerPage=100&userId=" +
          sessionStorage.getItem("userId") +
          "&isJobRoleRequired=TRUE&isLocationRequired=FALSE&rbKeyValueProgam=Program&rbKeyValueCourse=eLearning&rbKeyValueILT=Classroom%20Training&sessionId=en-US"
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      	
    setLoading(true);
  }

	
  if (posts.length === 0 && !loading) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  }

  function handleClickShowMore() {
    setShowMore(!showMore);
  }

  const handleKeyDown = (e, value) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
    } else if (e.key === "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setcurrentID("");
  };

  function SearchItemData(searchtext) {}
  const handleEnterKey = (event, value) => {
    if (event.key === "Enter") {
      setSearchValue(searchValueOnEnter);
      SearchItemData(searchValue);
    }
  };
  return (
    <div className="bg-image">
      <Navbar />
      <Link className="backButton" to="/availablelearning">
        {t("rbkey_BckLBL")}
      </Link>
      <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
        <div display="inline-block" style={{ marginLeft: "10px" }}>
          <p title={t("rbkey_prgrmMSG")}>{t("rbkey_PrgrmLbL")} </p>
        </div>
        <div
          display="inline-block"
          style={{
            marginLeft: "22%",
            color: "white",
            marginRight: "5px",
            marginTop: "0px",
          }}
        >
          <Dropdown className="dropdown" size="sm">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="dropdown"
              size="sm"
            >
              {" "}
              {t("rbkey_SrtByLBL")}{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={sortName}>
                {" "}
                {t("rbkey_NmLBL")}
              </Dropdown.Item>
              <Dropdown.Item onClick={sortDate}>
                {t("rbkey_DtLBL")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Stack
          spacing={2}
          width="250px"
          style={{ marginLeft: "350px", marginTop: "-1px" }}
        >
          <input
            placeholder="Search"
            value={searchValueOnEnter}
            onKeyDown={handleEnterKey}
            onChange={(e) => setSearchValueOnEnter(e.target.value)}
          />
        </Stack>
        <div
          display="inline-block"
          style={{
            fontSize: "small",
            fontWeight: "100",
            marginRight: "0",
            marginLeft: "25px",
          }}
        >
          {`${t("rbkey_PgLBL")} ${Math.min(
            page,
            Math.ceil(filteredPosts.length / 16)
          )} of ${pageCount}`}
        </div>
      </div>
      {!filteredPosts.length && <NotFound />}
      {showMore ? (
        <div>
          <div className="tileWrap">
            {posts
              .filter((item, key) => {
                return (
                  item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  ) ||
                  item.VC_ILT_CD?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  )
                );
              })
              .slice(16 * page - 16, 16 * page)
              .map((item, index) => {
                if (item.NMNTN_STTS === "C") {
                  nominationStatus = "Completed";
                } else if (item.NMNTN_STTS === "P") {
                  nominationStatus = "Pending";
                } else if (item.NMNTN_STTS === "S") {
                  nominationStatus = "Started";
                } else {
                  nominationStatus = "Pending";
                }
                const sasToken = SAS_TOKEN;
                const containerName = CONTAINER_NAME;
                const storageAccountName = STORAGE_ACCOUNT_NAME;
                let tempURL;
                if (item.VC_UPLDD_FL_NM != null) {
                  tempURL =
                    `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
                    sasToken;
                } else {
                  tempURL =
                    "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";
                }
                //
                let stringForDescription = removeTags(
                  item.VC_ILT_CRS_LNCH_DSCRPTN
                );
                //
                return (
                  <div className="container" key={index}>
                    <Link
                      className="link"
                      to="/details"
                      state={{
                        course_id: item.NM_CRS_LNCH_ID,
                        type: "Program",
                        course_name: item.VC_ILT_CRS_LNCH_NM,
                        start_date: item.DT_STRT_DT,
                        end_date: item.DT_END_DT,
                        credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                        progress: item.NM_PRGRSS,
                        description: stringForDescription,
                        isAvailableForever: item.BT_IS_AVLBL_FRVR,
                        imgSrc: tempURL,
                        VC_ILT_CD: item.VC_ILT_CD,
                        topic_name: item.VC_ILT_KND_NM,
                        certificate_prgrm_flag: item.BT_CERT_PRGM ? 1 : 0,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "125px",
                          objectFit: "cover",
                        }}
                        src={tempURL}
                        alt=""
                      />
                    </Link>

                    {/* <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                      </p>{" "}
                      |{" "}
                      <p title={item.VC_ILT_CD}>
                        {item.VC_ILT_CD?.substring(0, 11)}...
                      </p>
                    </div> */}
                    <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                        {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                      </p>
                    </div>
                    <p className="description">
                      {stringForDescription.toString().substring(0, 40)}
                      <Link
                        className="directlink"
                        to="/details"
                        state={{
                          course_id: item.NM_CRS_LNCH_ID,
                          type: "Program",
                          course_name: item.VC_ILT_CRS_LNCH_NM,
                          start_date: item.DT_STRT_DT,
                          end_date: item.DT_END_DT,
                          credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                          progress: item.NM_PRGRSS,
                          description: stringForDescription,
                          isAvailableForever: item.BT_IS_AVLBL_FRVR,
                          imgSrc: tempURL,
                          VC_ILT_CD: item.VC_ILT_CD,
                          topic_name: item.VC_ILT_KND_NM,
                          certificate_prgrm_flag: item.BT_CERT_PRGM ? 1 : 0,
                        }}
                      >
                        ...
                        {t("rbkey_Showmore_LBL")}
                      </Link>
                    </p>
                  </div>
                );
              })}
          </div>
          {!!filteredPosts.length && (
            <div className="paginationCntr">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={Math.min(page, Math.ceil(filteredPosts.length / 16))}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Stack>
            </div>
          )}
        </div>
      ) : (
        <div className="tileWrap">
          {posts
            .filter((item, key) => {
              return (
                item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                ) ||
                item.VC_ILT_CD?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                )
              );
            })
            .slice(0, 8)
            .map((item, index) => {
              if (item.NMNTN_STTS === "C") {
                nominationStatus = "Completed";
              } else if (item.NMNTN_STTS === "P") {
                nominationStatus = "Pending";
              } else if (item.NMNTN_STTS === "S") {
                nominationStatus = "Started";
              } else {
                nominationStatus = "Pending";
              }
            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
              let tempURL;
              if (item.VC_UPLDD_FL_NM != null) {
                tempURL =
                  `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
                  sasToken;
              } else {
                tempURL =
                  "https://miro.medium.com/v2/resize:fit:1100/0*a-6XePXYuq-YSGT0";
              }
              //
              let stringForDescription = removeTags(
                item.VC_ILT_CRS_LNCH_DSCRPTN
              );
              //
              return (
                <div className="container" key={index}>
                  <Link
                    className="link"
                    to="/details"
                    state={{
                      course_id: item.NM_CRS_LNCH_ID,
                      type: "Program",
                      course_name: item.VC_ILT_CRS_LNCH_NM,
                      start_date: item.DT_STRT_DT,
                      end_date: item.DT_END_DT,
                      credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                      progress: item.NM_PRGRSS,
                      description: stringForDescription,
                      isAvailableForever: item.BT_IS_AVLBL_FRVR,
                      imgSrc: tempURL,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                      certificate_prgrm_flag: item.BT_CERT_PRGM ? 1 : 0,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "125px",
                        objectFit: "cover",
                      }}
                      src={tempURL}
                      alt=""
                    />
                  </Link>
                  {/* <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                    </p>{" "}
                    |{" "}
                    <p title={item.VC_ILT_CD}>
                      {item.VC_ILT_CD?.substring(0, 11)}...
                    </p>
                  </div> */}
                  <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                    </p>
                  </div>
                  <p className="description">
                    {stringForDescription.toString().substring(0, 40)}
                    <Link
                      className="directlink"
                      to="/details"
                      state={{
                        course_id: item.NM_CRS_LNCH_ID,
                        type: "Program",
                        course_name: item.VC_ILT_CRS_LNCH_NM,
                        start_date: item.DT_STRT_DT,
                        end_date: item.DT_END_DT,
                        credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                        progress: item.NM_PRGRSS,
                        description: stringForDescription,
                        isAvailableForever: item.BT_IS_AVLBL_FRVR,
                        imgSrc: tempURL,
                        VC_ILT_CD: item.VC_ILT_CD,
                        topic_name: item.VC_ILT_KND_NM,
                        certificate_prgrm_flag: item.BT_CERT_PRGM ? 1 : 0,
                      }}
                    >
                      ...
                      {t("rbkey_Showmore_LBL")}
                    </Link>
                  </p>
                </div>
              );
            })}
        </div>
      )}
      {!!filteredPosts.length && (
        <div
          style={{
            textAlign: "center",
            paddingRight: "5px",
            borderRadius: "5px",
            paddingBottom: "20px",
          }}
        >
          <button className="showMore" onClick={handleClickShowMore}>
            {showMore ? (
              <div className="showMore">
                {t("rbkey_showless_LBL")}
                <TfiAngleDoubleUp />
              </div>
            ) : (
              <div className="showMore">
                {t("rbkey_Showmore_LBL")}
                <TfiAngleDoubleDown />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

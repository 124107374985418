import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import truck from "../../Assets/Images/truck.png";
import Navbar from "../../Pages/NavBar.jsx";
import axios from "axios";
import "./ML_Videochannel.css";
import "./../Common/Common.css";
import { TfiAngleDoubleDown, TfiAngleDoubleUp } from "react-icons/tfi";
import { Audio } from "react-loader-spinner";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { GrCertificate } from "react-icons/gr";
import Pagination from "@mui/material/Pagination";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import { P } from "react-html5video/dist";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import NotFound from "../../Assets/Components/NotFound";

export const ML_All_Elearning = (props) => {
  var nominationStatus;
  const { searchField } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [searchValue, setSearchValue] = useState([]);

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "  ");
  }
  const [searchValueOnEnter, setSearchValueOnEnter] = useState([]);

  let iconStyles = {
    color: "white",
    fontSize: "1.5em",
    margin: 0,
    textAlign: "right",
    right: "2px",
    alignItems: "right",
    textColor: "red",
    fill: "white",
  };

  const filteredPosts = posts.filter((item, key) => {
    return (
      item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      ) ||
      item.VC_ILT_CD?.toLowerCase().includes(
        searchValueOnEnter?.toString().toLowerCase()
      )
    );
  });

  const pageCount = Math.ceil(filteredPosts.length / 16);

  useEffect(() => {
    if (page > pageCount && pageCount) {
      setPage(pageCount);
    }
  }, [pageCount, page]);

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "DESC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(res.data);
        setPostsData(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const courses = [];
  const courseList = posts.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    if (typeof course.VC_ILT_CD == "string") {
      courses.push(course.VC_ILT_CD);
    }
  });

  	
  if (posts.length === 0 && !loading) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  } else if (posts.length === 0 && loading) {
    <NotFound />;
  }
  function sortDate() {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function sortName() {
    axios
      .get(PUBLIC_URL + "/api/ELearning/MyLearning/ELearning", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "VC_ILT_CRS_LNCH_NM",
          order: "ASC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setPosts(res.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleClickShowMore() {
    setShowMore(!showMore);
  }
  const handleKeyDown = (e, value) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
    } else if (e.key === "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setcurrentID("");
  };
  function SearchItemData(searchtext) {}
  const handleEnterKey = (event, value) => {
    if (event.key === "Enter") {
      setSearchValue(searchValueOnEnter);
      SearchItemData(searchValue);
    }
  };
  return (
    <div className="bg-image">
      <Navbar />

      <Link className="backButton" to="/mylearning">
        {t("rbkey_BckLBL")}
      </Link>
      <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
        <div display="inline-block" style={{ marginLeft: "10px" }}>
          <p title={t("rbkey_eLrningMSG")}>{t("rbkey_ELrrngLbl")}</p>
        </div>
        <div
          display="inline-block"
          style={{
            marginLeft: "22%",
            color: "white",
            marginRight: "5px",
            marginTop: "0px",
          }}
        >
          <Dropdown className="dropdown" size="sm">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="dropdown"
              size="sm"
            >
              {" "}
              {t("rbkey_SrtByLBL")}{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={sortName}>
                {" "}
                {t("rbkey_NmLBL")}
              </Dropdown.Item>
              <Dropdown.Item onClick={sortDate}>
                {t("rbkey_DtLBL")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Stack
          spacing={2}
          width="250px"
          style={{ marginLeft: "350px", marginTop: "-1px" }}
        >
          <input
            placeholder="Search"
            value={searchValueOnEnter}
            onKeyDown={handleEnterKey}
            onChange={(e) => setSearchValueOnEnter(e.target.value)}
          />
        </Stack>
        <div
          display="inline-block"
          style={{
            fontSize: "small",
            fontWeight: "100",
            marginRight: "0",
            marginLeft: "25px",
          }}
        >
          {`${t("rbkey_PgLBL")} ${Math.min(
            page,
            Math.ceil(filteredPosts.length / 16)
          )} of ${pageCount}`}
        </div>
      </div>
      {!filteredPosts.length && <NotFound />}
      {showMore ? (
        <div>
          <div className="tileWrap">
            {posts
              .filter((item, key) => {
                return (
                  item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  ) ||
                  item.VC_ILT_CD?.toLowerCase().includes(
                    searchValueOnEnter?.toString().toLowerCase()
                  )
                );
              })
              .slice(16 * page - 16, 16 * page)
              .map((item, index) => {
                if ((item.NMNTN_STTS = "C")) {
                  nominationStatus = "Completed";
                } else if ((item.NMNTN_STTS = "P")) {
                  nominationStatus = "Pending";
                } else if ((item.NMNTN_STTS = "S")) {
                  nominationStatus = "Started";
                } else {
                  nominationStatus = "Pending";
                }

                const sasToken = SAS_TOKEN;
                const containerName = CONTAINER_NAME;
                const storageAccountName = STORAGE_ACCOUNT_NAME;
                let tempURL;
                if (item.VC_UPLDD_FL_NM != null) {
                  tempURL =
                    `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
                    sasToken;
                } else {
                  tempURL =
                    "https://img.freepik.com/free-vector/e-learning-icons-flat_1284-3950.jpg?w=2000";
                }
                //
                let stringForDescription = removeTags(
                  item.VC_ILT_CRS_LNCH_DSCRPTN
                );
                //
                return (
                  <div className="container" key={index}>
                    <Link
                      className="link"
                      to="/detailsML"
                      state={{
                        course_id: item.NM_CRS_LNCH_ID,
                        type: item.NM_LRNNG_TYP,
                        course_name: item.VC_ILT_CRS_LNCH_NM,
                        start_date: item.DT_STRT_DT,
                        end_date: item.DT_END_DT,
                        credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                        progress: item.NM_PRGRSS,
                        description: stringForDescription,
                        isAvailableForever: item.BT_IS_AVLBL_FRVR,
                        certificateID: item.NM_CRTFCT_ID,
                        imgSrc: tempURL,
                        VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                        VC_USR_NM: item.VC_USR_NM,
                        DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                        VC_ILT_CD: item.VC_ILT_CD,
                        topic_name: item.VC_ILT_KND_NM,
                        certificate_id: item.NM_CRTFCT_ID,
                        certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "125px",
                          objectFit: "cover",
                        }}
                        src={tempURL}
                        alt=""
                      />
                    </Link>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        marginBottom: 0,
                      }}
                    >
                      <ProgressBar
                        now={item.NM_PRGRSS}
                        label={`${item.NM_PRGRSS}%`}
                        className="progressBar"
                      />

                      {item.NM_CRTFCT_ID > 0 && (
                        <>
                          {item.NM_PRGRSS == 100 && (
                            <GrCertificate
                              style={iconStyles}
                              title="Certificate"
                              onClick={() => {
                                if (item.NM_CRTFCT_ID > 0) {
                                  navigate("/certificatepage", {
                                    state: {
                                      VC_ILT_CRS_LNCH_NM:
                                        item.VC_ILT_CRS_LNCH_NM,
                                      VC_USR_NM: item.VC_USR_NM,
                                      DT_CMPLTN_DT:
                                        item.DT_CMPLTN_DT == null
                                          ? ""
                                          : item.DT_CMPLTN_DT.substring(0, 10),
                                      certificate_id: item.NM_CRTFCT_ID,
                                      certificate_prnt_flg:
                                        item.BT_LRNR_PRNT_CRTFCT,
                                    },
                                  });
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                    </p>

                    {/* <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 13)}...
                      </p>{" "}
                      |{" "}
                      <p title={item.VC_ILT_CD}>
                        {item.VC_ILT_CD?.substring(0, 13)}...
                      </p>
                    </div> */}
                    <div className="title" style={{ display: "flex" }}>
                      <p title={item.VC_ILT_CRS_LNCH_NM}>
                        {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                        {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                      </p>{" "}
                    </div>
                    <p className="description">
                      {stringForDescription.toString().substring(0, 40)}
                      <Link
                        className="directlink"
                        to="/detailsML"
                        state={{
                          course_id: item.NM_CRS_LNCH_ID,
                          type: item.NM_LRNNG_TYP,
                          course_name: item.VC_ILT_CRS_LNCH_NM,
                          start_date: item.DT_STRT_DT,
                          end_date: item.DT_END_DT,
                          credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                          progress: item.NM_PRGRSS,
                          description: stringForDescription,
                          isAvailableForever: item.BT_IS_AVLBL_FRVR,
                          certificateID: item.NM_CRTFCT_ID,
                          imgSrc: tempURL,
                          VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                          VC_USR_NM: item.VC_USR_NM,
                          DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                          VC_ILT_CD: item.VC_ILT_CD,
                          topic_name: item.VC_ILT_KND_NM,
                          certificate_id: item.NM_CRTFCT_ID,
                          certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                        }}
                      >
                        ...
                        {t("rbkey_Showmore_LBL")}
                      </Link>
                    </p>
                  </div>
                );
              })}
          </div>
          {!!filteredPosts.length && (
            <div className="paginationCntr">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={Math.min(page, Math.ceil(filteredPosts.length / 16))}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Stack>
            </div>
          )}
        </div>
      ) : (
        <div className="tileWrap">
          {posts
            .filter((item, key) => {
              return (
                item.VC_ILT_CRS_LNCH_NM?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                ) ||
                item.VC_ILT_CD?.toLowerCase().includes(
                  searchValueOnEnter?.toString().toLowerCase()
                )
              );
            })
            .slice(0, 8)
            .map((item, index) => {
              if ((item.NMNTN_STTS = "C")) {
                nominationStatus = "Completed";
              } else if ((item.NMNTN_STTS = "P")) {
                nominationStatus = "Pending";
              } else if ((item.NMNTN_STTS = "S")) {
                nominationStatus = "Started";
              } else {
                nominationStatus = "Pending";
              }

            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
              let tempURL;
              if (item.VC_UPLDD_FL_NM != null) {
                tempURL =
                  `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRCourseLaunchFolderImages/${item.NM_CRS_LNCH_ID}/${item.VC_UPLDD_FL_NM}?` +
                  sasToken;
              } else {
                tempURL =
                  "https://img.freepik.com/free-vector/e-learning-icons-flat_1284-3950.jpg?w=2000";
              }
              //
              let stringForDescription = removeTags(
                item.VC_ILT_CRS_LNCH_DSCRPTN
              );
              //
              return (
                <div className="container" key={index}>
                  <Link
                    className="link"
                    to="/detailsML"
                    state={{
                      course_id: item.NM_CRS_LNCH_ID,
                      type: item.NM_LRNNG_TYP,
                      course_name: item.VC_ILT_CRS_LNCH_NM,
                      start_date: item.DT_STRT_DT,
                      end_date: item.DT_END_DT,
                      credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                      progress: item.NM_PRGRSS,
                      description: stringForDescription,
                      isAvailableForever: item.BT_IS_AVLBL_FRVR,
                      certificateID: item.NM_CRTFCT_ID,
                      imgSrc: tempURL,
                      VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                      VC_USR_NM: item.VC_USR_NM,
                      DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                      certificate_id: item.NM_CRTFCT_ID,
                      certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "125px",
                        objectFit: "cover",
                      }}
                      //src="https://img-c.udemycdn.com/course/480x270/2121018_9de5_5.jpg"
                      src={tempURL}
                      alt=""
                    />
                  </Link>

                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "smaller",
                      marginBottom: 0,
                    }}
                  >
                    <ProgressBar
                      now={item.NM_PRGRSS}
                      label={`${item.NM_PRGRSS}%`}
                      className="progressBar"
                    />

                    {item.NM_CRTFCT_ID > 0 && (
                      <>
                        {item.NM_PRGRSS == 100 && (
                          <GrCertificate
                            style={iconStyles}
                            title="Certificate"
                            onClick={() => {
                              if (item.NM_CRTFCT_ID > 0) {
                                navigate("/certificatepage", {
                                  state: {
                                    VC_ILT_CRS_LNCH_NM: item.VC_ILT_CRS_LNCH_NM,
                                    VC_USR_NM: item.VC_USR_NM,
                                    DT_CMPLTN_DT:
                                      item.DT_CMPLTN_DT == null
                                        ? ""
                                        : item.DT_CMPLTN_DT.substring(0, 10),
                                    certificate_id: item.NM_CRTFCT_ID,
                                    certificate_prnt_flg:
                                      item.BT_LRNR_PRNT_CRTFCT,
                                  },
                                });
                              }
                            }}
                          />
                        )}
                      </>
                    )}
                  </p>

                  {/* <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 13)}...
                    </p>{" "}
                    |{" "}
                    <p title={item.VC_ILT_CD}>
                      {item.VC_ILT_CD?.substring(0, 13)}...
                    </p>
                  </div> */}
                  <div className="title" style={{ display: "flex" }}>
                    <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                    </p>{" "}
                  </div>
                  <p className="description">
                    {stringForDescription.toString().substring(0, 40)}
                    <Link
                      className="directlink"
                      to="/detailsML"
                      state={{
                        course_id: item.NM_CRS_LNCH_ID,
                        type: item.NM_LRNNG_TYP,
                        course_name: item.VC_ILT_CRS_LNCH_NM,
                        start_date: item.DT_STRT_DT,
                        end_date: item.DT_END_DT,
                        credit_hours: item.NM_ILT_CRS_CRDT_HRS,
                        progress: item.NM_PRGRSS,
                        description: stringForDescription,
                        isAvailableForever: item.BT_IS_AVLBL_FRVR,
                        certificateID: item.NM_CRTFCT_ID,
                        topic_name: item.VC_ILT_KND_NM,
                        certificate_id: item.NM_CRTFCT_ID,
                        certificate_prnt_flg: item.BT_LRNR_PRNT_CRTFCT,
                        DT_CMPLTN_DT: item.DT_CMPLTN_DT,
                      }}
                    >
                      ...
                      {t("rbkey_Showmore_LBL")}
                    </Link>
                  </p>
                </div>
              );
            })}
        </div>
      )}
      {!!filteredPosts.length && (
        <div
          style={{
            textAlign: "center",
            paddingRight: "5px",
            borderRadius: "5px",
            paddingBottom: "20px",
          }}
        >
          <button className="showMore" onClick={handleClickShowMore}>
            {showMore ? (
              <div className="showMore">
                {t("rbkey_showless_LBL")}
                <TfiAngleDoubleUp />
              </div>
            ) : (
              <div className="showMore">
                {t("rbkey_Showmore_LBL")}
                <TfiAngleDoubleDown />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

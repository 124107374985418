import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AV_Elearning } from "./AV_Elearning";
import { AV_ClassroomTraining } from "./AV_ClassroomTraining";
import { AV_Program } from "./AV_Program";
import truck from "../../Assets/Images/truck.png";
import Navbar from "../../Pages/NavBar.jsx";
import { FaUserCircle } from "react-icons/fa";
import "./AV_Videochannel.css";
import { useTranslation } from "react-i18next";
import { ImHome } from "react-icons/im";
import { GoDeviceCameraVideo } from "react-icons/go";
import { useSearchParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router-dom";
import { Stack, Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { Contexts } from "../../../contexts/Contexts";
import {
  PUBLIC_URL,
  PUBLIC_URL_API2,
  HOME_URL,
  DOMAIN_ID,
} from "./../Common/Constants.js";

export const AvailableLearning = () => {
  const { searchField } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);

  const [toggleState, setToggleState] = useState(0);
  const { t } = useTranslation();
  const [posts1, setPosts1] = useState([]);
  const [posts2, setPosts2] = useState([]);
  const [posts3, setPosts3] = useState([]);
  //
  const [postData1, setpostData1] = useState([]);
  const [postData2, setpostData2] = useState([]);
  const [postData3, setpostData3] = useState([]);

  const [searchValueOnEnter, setSearchValueOnEnter] = useState([]);

  const toggletab = (index) => {
    setToggleState(index);
  };
  const [searchValue, setSearchValue] = useState([]);
  const navigate = useNavigate();

  //
  useEffect(() => {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL_API2 +
          "/api/ClassRoomTraining/AvailableLearning/ILTDetailList?corporateId=" +
          domain_id,
        {
          params: {
            corporateId: domain_id,
          },
        }
      )
      .then((res) => {
        let temp = res.data.Table.concat(res.data.Table1);
        setPosts1(temp);
        setpostData1(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL +
          "/api/ELearning/AvailableLearning/ELearning?order=asc&pageNumber=1&corporateId=" +
          domain_id +
          "&numberOfRecordsPerPage=1000&userId=" +
          sessionStorage.getItem("userId") +
          "&naRbkey=NA&searchType=L&orderBy=VC_CRS_NM&isJobRoleRequired=TRUE&isLocationRequired=FALSE&rbKeyValueProgam=Program&rbKeyValueCourse=eLearning&rbKeyValueILT=Classroom%20Training&sessionId=en-US",
        {
          params: {
            naRbkey: "NA",

            order: "ASC",
            pageNumber: 1,
            corporateId: domain_id,
            numberOfRecordsPerPage: 1000,
            userId: sessionStorage.getItem("userId"),
          },
        }
      )
      .then((res) => {
        setPosts2(res.data);
        setpostData2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    axios
      .get(
        PUBLIC_URL +
          "/api/Program/AvailableLearning/Program?naRbkey=NA&searchType=L&orderBy=DT_STRT_DT&order=DESC&pageNumber=1&corporateId=" +
          domain_id +
          "&numberOfRecordsPerPage=100&userId=" +
          sessionStorage.getItem("userId") +
          "&isJobRoleRequired=TRUE&isLocationRequired=FALSE&rbKeyValueProgam=Program&rbKeyValueCourse=eLearning&rbKeyValueILT=Classroom%20Training&sessionId=en-US"
      )
      .then((res) => {
        setPosts3(res.data);
        setpostData3(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const courses = [];
  const courseList1 = posts1.map((course) => {
    courses.push(course.VC_ILT_NM);

    if (typeof course.VC_ILT_CD == "string") {
      courses.push(course.VC_ILT_CD);
    }
  });

  const courseList2 = posts2.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    if (typeof course.VC_ILT_CD == "string") {
      courses.push(course.VC_ILT_CD);
    }
  });

  const courseList3 = posts3.map((course) => {
    courses.push(course.VC_ILT_CRS_LNCH_NM);
    if (typeof course.VC_ILT_CD == "string") {
      courses.push(course.VC_ILT_CD);
    }
  });
  const handleSearchClick = () => {
    setSearchValue(searchValueOnEnter);
  };
  const handleKeyDown = (e, value) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
    } else if (e.key === "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setcurrentID("");
    SearchItemData(value);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      setSearchValue(searchValueOnEnter);
    }
  };
  function SearchItemData(searchtext) {
    if (searchtext == null) {
      setPosts1(postData1);
      setPosts2(postData2);
      setPosts3(postData3);
    } else {
      posts1.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM === searchtext) {
          let myData = [item];
          setPosts1(myData);
        }
      });
      posts2.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM === searchtext) {
          let myData = [item];
          setPosts2(myData);
        }
      });
      posts3.map((item, index) => {
        if (item.VC_ILT_CRS_LNCH_NM === searchtext) {
          let myData = [item];
          setPosts3(myData);
        }
      });
    }
  }
  //
  return (
    <div>
      <Navbar />
      <div className="bg-image">
        <p className="userinfo" style={{ display: "flex" }}>
          {" "}
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaUserCircle style={{ fontSize: "20px" }} />
          </div>
          <div
            style={{
              color: "white",
              textAlign: "right",
              paddingLeft: "5px",
              fontWeight: "normal",
            }}
          >
            {" "}
            {sessionStorage.getItem("userFirstName")}{" "}
            {sessionStorage.getItem("userLastName")}
          </div>
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            <GoDeviceCameraVideo
              title="VideoChannel"
              style={{ fontSize: "20px" }}
              onClick={() => {
                navigate("/videochannel");
              }}
            />
            <a
              href={HOME_URL}
              style={{
                color: "white",
                textAlign: "right",
                paddingLeft: "10px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              title="Back To Main Application"
            >
              <ImHome />
            </a>
          </div>
        </p>
        <div
          style={{
            color: "white",
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: "#435270",
            height: "40px",
            paddingTop: "8px",
            marginBottom: "20px",
            paddingLeft: "15px",
          }}
        >
          <p>
            {t("rbkey_AvlblLrnngLBL")}
            <Stack
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "row",
                float: "right",
                maxHeight: "25px",
                minWidth: "22%",
              }}
            >
              <input
                placeholder="Search"
                id="mySearch"
                value={searchValueOnEnter}
                onKeyDown={handleEnterKey}
                onChange={(e) => setSearchValueOnEnter(e.target.value)}
                style={{ width: "90%" }}
              />
              <SearchIcon
                style={{ marginTop: -1, marginRight: "5%" }}
                onClick={handleSearchClick}
              ></SearchIcon>
            </Stack>
          </p>
        </div>
        <div className="ribbon" style={{ width: "95%", marginLeft: "2.5%" }}>
          <div display="inline-block" style={{ marginLeft: "10px" }}>
            <p title={t("rbkey_ILTMSG")}>{t("rbkey_ClssrmTrnngsLBL")}</p>
          </div>
        </div>

        <AV_ClassroomTraining search={searchValue} />

        <div className="ribbon">
          <p title={t("rbkey_eLrningMSG")}>{t("rbkey_ELrrngLbl")}</p>
        </div>
        <AV_Elearning search={searchValue} />
        <div className="ribbon">
          <p title={t("rbkey_prgrmMSG")}>{t("rbkey_PrgrmLbL")} </p>
        </div>
        <AV_Program search={searchValue} />
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <p>{children}</p>}</div>;
}
export default AvailableLearning;

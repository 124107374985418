import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import assessmentStyles from "./Assessment.module.scss";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import {FaArrowAltCircleDown ,FaArrowAltCircleUp} from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export const Match = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const [questionDetails, setQuestionDetails] = useState([]);
  const [choiceDetails, setChoiceDetails] = useState([]);
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const [array, setArray] = React.useState([]); 
  let choices= new Array();
  let selectedoptions= new Array();
  let selectedAnsIds= new Array();
  let arr= new Array();
  const { t } = useTranslation();
 
  const classes = useStyles();

  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "darkblue"
    }
  }))(MuiTableHead);
  
  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "white"
    }
  }))(TableCell);
 

  useEffect(() => {
    setQuestionDetails([])
    axios({
      method: "GET",
      url:
        PUBLIC_URL +
        "/api/MatchAssessment/MyLearning/GetQuestionDetailsMatchAssessment",

      params: {
        QuestionID: location.state.question_id,
        UserId: sessionStorage.getItem("userId"),
        NodeId: location.state.node_id,
        CourseLaunchId: location.state.course_id,
        UserResponse: null,
      },
    })
      .then((response) => {
        setQuestionDetails(response);
        //let arrLength = response.data?.Table.length ;
        if (response.data?.Table1.length == 0)
        {
          setChoiceDetails(response.data?.Table)
        response.data?.Table.map((item, index) => {
          arr[index]=item.VC_CRRCT_MTCH;
          console.log(choiceDetails)
          //arr.push(item.VC_CRRCT_MTCH);
        })
      }
      else{
        response.data?.Table1.map((item, index) => {
          setChoiceDetails(response.data?.Table1)
          arr[index]=item.VC_CRRCT_MTCH;
          console.log("in",questionDetails?.data?.Table[index].VC_CRRCT_MTCH,choiceDetails)
          //questionDetails?.data?.Table[index].VC_CRRCT_MTCH ==arr[index];
        })
      }
        setArray(arr)
        console.log("res",questionDetails?.data?.Table1,array,arr ,choiceDetails,response.data?.Table1)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);

  const handleClickSave = () => {
    choiceDetails?.map((item, index) => {
      choices[index]=item.VC_CHC_TXT;
      selectedoptions[index]=item.VC_CRRCT_MTCH;
      selectedAnsIds[index]=item.NM_ANSWR_ID;    
    })
    let choicestr=JSON.stringify(choices);
    choicestr=choicestr.replaceAll(',', '~').replaceAll('[', '').replaceAll(']', '').replaceAll('"', '');
    let selectedoptionstr=JSON.stringify(selectedoptions);
    selectedoptionstr=selectedoptionstr.replaceAll(',', '~').replaceAll('[', '').replaceAll(']', '').replaceAll('"', '');
    let selectedAnsIdstr=JSON.stringify(selectedAnsIds);
    selectedAnsIdstr=selectedAnsIdstr.replaceAll(',', '~').replaceAll('[', '').replaceAll(']', '').replaceAll('"', '');
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/MatchAssessment/MyLearning/SaveMatchFollowingAssessmentAns",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: sessionStorage.getItem("userId"),
        },
        qustionId: location.state.question_id,
        //qustionId:1492,
        choice: choicestr,
        match: selectedoptionstr,
        answerId: selectedAnsIdstr,
        markQuestion: false,
        leftAssessmentAttemptTime: ""
      },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find((item, index) => index === event.oldIndex);
    const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);
  
    const reorderedItems = [
        ...remainingItems.slice(0, event.newIndex),
        movedItem,
        ...remainingItems.slice(event.newIndex)
    ];
    setArray(reorderedItems)
    choiceDetails?.map((item, index) => {
      if(array[index] != undefined)
      {
      item.VC_CRRCT_MTCH=array[index]
      }
     })
     console.log("arr",questionDetails?.data?.Table,array)
    return reorderedItems;
  }
  function changeOrder(index, direction) {
    console.log("index",index,direction)
    setArray(reorderArray({oldIndex: index, newIndex: index + (direction === "UP" ? (-1) : 1)}, array));
  }

  const handleCancel = (event) => {
    event.preventDefault();
    setisCancelClickedFromAssessmentTypes(true);
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        choice: true,
        finalizeAssessment: true,
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id : location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };

 
  return (
    <div>
      <h3 className={assessmentStyles.questiontext}>
        {questionDetails?.data?.Table[0]?.VC_QSTN_TXT}
      </h3>

      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Column A</TableHeaderCell>
            <TableHeaderCell align="right">Column B</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {choiceDetails?.map((item, index) => (
            <TableRow key={item.VC_CHC_TXT}>
              <TableCell component="th" scope="row">
              {item.VC_CHC_TXT}
              </TableCell>
              <TableCell align="right">  {item.VC_CRRCT_MTCH}
              <Button sx={{paddingRight:"0", marginRight :"0", minWidth:"0"}} onClick={() => changeOrder(index, "UP")}><FaArrowAltCircleUp/></Button>
              <Button sx={{paddingLeft:"0",minWidth:"10%"}} onClick={() => changeOrder(index, "DOWN")}><FaArrowAltCircleDown/></Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>  

    <div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>

      <div
        style={{
          marginLeft: "40%",
          marginTop: "5%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
        {t("rbkey_CnclLBL")}
        </Button>

        <Button
          variant="contained"
          sx={{ marginLeft: "5px" }}
          onClick={handleClickSave}
        >
          {t("rbkey_SvLBL")}
        </Button>
      </div>
    </div>
  );
};
export default Match;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

export const MultipleSelect = (props) => {
  const locationData = useLocation();
  const location = useLocation();
  const [msquestionDetails, setmsQuestionDetails] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { questionList, setQuestionList } = useState([]);
  const [answer, setAnswer] = useState("");
  const [selectedAns, setSelectedAns] = useState();
  const num = location.state.questionNo;
  const navigate = useNavigate();
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const [itemSelected, setitemSelected] = useState([]);
  const [isChecked, setisChecked] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setitemSelected([]);
    setmsQuestionDetails([]);
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/MultipleSelectQuestions/MyLearning/GetAssessmentQuestionDetailsMultipleSelect",

      data: {
        // questionId: questionList?.Table[num]?.NM_QSTN_ID,
        questionId: location.state.question_id,
        userId: sessionStorage.getItem("userId"),
        nodeId: location.state.node_id,
        courseLaunchId: location.state.course_id,
        userResponse: null,
      },
    })
      .then((response) => {
        setmsQuestionDetails(response);
        if (
          response.data.Table1 != undefined &&
          response.data.Table1.length > 0
        ) {
          let temp = [];
          if(location.state.button_type=="start" && response.data.Table7[0].BT_IS_ATTMPTD==true)
          {
            response.data.Table1.map((item) => {
              temp.push(item.NM_ANSWR_ID);
            });
            setitemSelected(temp);
          }
          else if (location.state.button_type=="resume"){
            response.data.Table1.map((item) => {
              temp.push(item.NM_ANSWR_ID);
            });
            setitemSelected(temp);
          }
          else{
          }
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);

  const handleClickSave = () => {
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/MultipleSelectQuestions/MyLearning/SaveAssessmentQuestionMultipleSelect",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: location.state.question_id,
        choice: itemSelected.toString(),
        markQuestion: true,
        leftAssessmentAttemptTime: "",
      },
      // data: {
      //   userCourseModel: {
      //     courseLaunchId: location.state.questionId,
      //     nodeId: location.state.node_id,
      //     userId: sessionStorage.getItem("userId"),
      //   },
      //   qustionId: location.state.questionId,
      //   answer: answer,
      //   resultantQuestion: "string",
      //   markQuestion: true,
      //   leftAssessmentAttemptTime: "",
      // },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("itemSelected : ", itemSelected);
  }, [itemSelected]);

  const handleCancel = (event) => {
    event.preventDefault();
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        choice: true,
        finalizeAssessment: true,
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    setisCancelClickedFromAssessmentTypes(true);
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id: location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };

  const optionClicked = (isCorrect) => {
    console.log(isCorrect, msquestionDetails.data.Table[0]?.VC_ANSWR_TXT);
    if (msquestionDetails.data.Table[0]?.VC_ANSWR_TXT === isCorrect) {
    } else {
    }
  };

  return (
    <div>
      <h4 className={assessmentStyles.questiontext}>
        {msquestionDetails?.data?.Table[0]?.VC_QSTN_TXT}
      </h4>
      {msquestionDetails.data?.Table?.map((item, index) => (
        <div key={index}>
          <ul key={index}>
            <li
              onClick={() => optionClicked(item.VC_ANSWR_TXT)}
              key={item.NM_ANSWR_ID}
            >
              <input
                id={`multi_checkbox_${item.NM_ANSWR_ID}`}
                type="checkbox"
                name="answer"
                style={{ marginRight: "5px" }}
                checked={itemSelected.includes(item.NM_ANSWR_ID)}
                onChange={() => {
                  debugger;
                  setisChecked(!isChecked);
                  if (!itemSelected.includes(item.NM_ANSWR_ID)) {
                    setitemSelected((prev) => [...prev, item.NM_ANSWR_ID]);
                  } else {
                    setitemSelected(
                      itemSelected.filter(
                        (listItem) => listItem !== item.NM_ANSWR_ID
                      )
                    );
                  }
                }}
              />
              {item.VC_ANSWR_TXT}
            </li>
          </ul>
        </div>
      ))}

<div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>
      <div
        style={{
          marginLeft: "40%",
          marginTop: "5%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
        {t("rbkey_CnclLBL")}
        </Button>

        <Button
          variant="contained"
          sx={{ marginLeft: "5px" }}
          onClick={handleClickSave}
        >
          {t("rbkey_SvLBL")}
        </Button>
      </div>
    </div>
  );
};
export default MultipleSelect;

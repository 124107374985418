import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect, useState } from "react";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Contexts } from "../../../contexts/Contexts";
import "./language.scss";

const LanguageSelect = () => {
  let { currentID } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const { languageSelected } = useContext(Contexts);
  const { setlanguageSelected } = useContext(Contexts);
  const { langMap } = useContext(Contexts);
  let obj = {};
  if (langMap != undefined && langMap != null) {
    obj = JSON.parse(langMap);
  }
  const selected = localStorage.getItem("i18nextLng") || languageSelected;

  const [selectLang, setSelectLang] = useState(selected);
  //Working One
  setlanguageSelected(selectLang);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }

    i18next.changeLanguage(localStorage.getItem("lang"));
  }, [menuAnchor, selected]);
  return (
    <div>
      <Button
        onClick={({ currentTarget }) => {
          setcurrentID("");
          setMenuAnchor(currentTarget);
        }}
      >
        {!obj[selected]?.label
          ? "English"
          : localStorage.getItem("lang") === "ja-JP"
          ? "Japanese"
          : obj[selected]?.label}

        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListSubheader className="ddlSubHeader">
              {t("rbkey_SlcRsrcLanguagesLBL")}
            </ListSubheader>
            {Object.keys(obj)?.map((item) => (
              <ListItem
                button
                key={item}
                className="ddlListItem"
                onClick={() => {
                  i18next.changeLanguage(item);
                  setSelectLang(item);
                  setMenuAnchor(null);
                  localStorage.setItem("lang", item);
                }}
              >
                {obj[item]?.label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;

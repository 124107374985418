import React from "react";
import "./Popuptab.css";

const Popuptab = (props) => {
  return (
    <div className="popup-box1">
      <div id="box1">
        {/* <span className="close-icon1" onClick={props.handleClose}>
          x
        </span> */}
        <div>{props.content}</div>
      </div>
    </div>
  );
};

export default Popuptab;

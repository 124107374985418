import React, { useEffect, useState } from "react";
import ListItem from "../listItem/ListItem";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Contexts } from "../../../contexts/Contexts";
import Pagination from "@mui/material/Pagination";
import { TfiAngleDoubleDown, TfiAngleDoubleUp } from "react-icons/tfi";

import { Stack } from "@mui/material";
import { display } from "@mui/system";
import { Audio } from "react-loader-spinner";
import "./Category.scss";

export default function Category({ items, type }) {
  const { searchField } = useContext(Contexts);

  const [moreFlag, setmoreFlag] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [page, setPage] = React.useState(1);
  const [posts, setPosts] = useState([]);

  const [postsData, setPostsData] = useState([]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  function handleClickShowMore() {
    setShowMore(!showMore);
  }
  useEffect(() => {
    console.log(items);
    setisLoaded(false);
    setTimeout(() => {
      setisLoaded(true);
    }, 4000);
  }, []);

  const pageCount = Math.ceil(items?.length / 16);
  useEffect(() => {
    if (page > pageCount && pageCount) {
      setPage(pageCount);
    }
  }, [pageCount, page]);

  let videourl;

  return (
    <div>
      {!isLoaded ? (
        <div
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="60" width="75" color="grey" arialLabel="Loading" />
          </div>
        </div>
      ) : (
        <>
          {showMore ? (
            <div id={type.replace(/ /g, "")} className="listCategory">
              <div className="listTitleHeader">
                <span className="listTitleCategory">{type}</span>
                <span style={{ marginLeft: "75%", color: "whitesmoke" }}>{`${t(
                  "rbkey_PgLBL"
                )} ${Math.min(
                  page,
                  Math.ceil(items?.length / 16)
                )} of ${pageCount}`}</span>
              </div>

              <div className="tileWrap">
                {items !== undefined &&
                  items
                    .slice(16 * page - 16, 16 * page)
                    .map((eachitem, index) => {
                      return (
                        <div key={index} style={{ marginLeft: 40 }}>
                          <ListItem
                            type={type.replace(/ /g, "")}
                            info={eachitem}
                            moreFlag={moreFlag}
                            setmoreFlag={setmoreFlag}
                          />
                          <div className="titleCategory">
                            {eachitem.VC_PDCST_NM.substring(0, 34)}..
                          </div>
                        </div>
                      );
                    })}
              </div>
              {!!items.length && (
                <div className="paginationCntr">
                  <Stack spacing={2}>
                    <Pagination
                      count={pageCount}
                      page={Math.min(page, Math.ceil(items?.length / 16))}
                      onChange={handleChange}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </Stack>
                </div>
              )}
            </div>
          ) : (
            <div id={type.replace(/ /g, "")} className="listCategory">
              <div className="listTitleHeader">
                <span className="listTitleCategory">{type}</span>
              </div>
              <div className="tileWrap">
                {items !== undefined &&
                  items.slice(0, 8).map((eachitem, index) => {
                    return (
                      <div key={index} style={{ marginLeft: 40 }}>
                        <ListItem
                          type={type.replace(/ /g, "")}
                          info={eachitem}
                          moreFlag={moreFlag}
                          setmoreFlag={setmoreFlag}
                        />
                        <div className="titleCategory">
                          {eachitem.VC_PDCST_NM.substring(0, 34)}..
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {items !== undefined && !!items.length && (
            <div
              style={{
                textAlign: "center",
                paddingRight: "5px",
                borderRadius: "5px",
                paddingBottom: "20px",
              }}
            >
              <button className="showMore" onClick={handleClickShowMore}>
                {showMore ? (
                  <div className="showMore">
                    {t("rbkey_showless_LBL")}
                    <TfiAngleDoubleUp />
                  </div>
                ) : (
                  <div className="showMore">
                    {t("rbkey_Showmore_LBL")}
                    <TfiAngleDoubleDown />
                  </div>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

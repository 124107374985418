import React from "react";
import Box from "@mui/material/Box";
import "./AccessDenied.css";

const AccessDenied = () => {
  return (
    <div className="divAccessDenied">
      <Box
        sx={{
          width: "100%",
          height: "95%",
          p: 1,
          m: 1,
          borderRadius: 2,
          textAlign: "center",
          align: "left",
          margin: "0 auto",
        }}
      >
        {/* <div>ACCESS DENIED</div>
        <label className="dvAcessDeniedDescription">
          Please contact System Administrator if you require access.
        </label> */}
      </Box>
    </div>
  );
};

export default AccessDenied;

import React, { useState, useEffect } from "react";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

export const DescriptiveQuestion = (props) => {
  const inputProps = {
    step: 300,
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [answer, setAnswer] = useState("");
  const [descQuestionDetails, setDescQuestionDetails] = useState();
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const { t } = useTranslation();

  useEffect(() => {
    setAnswer("");
    setDescQuestionDetails();
    axios({
      method: "GET",
      url:
        PUBLIC_URL +
        "/api/DeascriptiveAssessment/MyLearning/GetQuestionDetailsDescriptiveAssessment",

      params: {
        QuestionID: location.state.question_id,
        CourseLaunchId: location.state.course_id,
        NodeId: location.state.node_id,
        UserId: sessionStorage.getItem("userId"),
      },
    })
      .then((response) => {
        setDescQuestionDetails(response.data);
        if(location.state.button_type=="start" && response.data.Table5[0].BT_IS_ATTMPTD==true)
        {
          setAnswer(response.data.Table1[0].VC_ANSWR_TXT);
        }
        else if (location.state.button_type=="resume"){
          setAnswer(response.data.Table1[0].VC_ANSWR_TXT);
        }
        else{
          setAnswer("");
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);

  const handleClickSave = () => {
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/DeascriptiveAssessment/MyLearning/SaveDescriptiveAssessmentAns",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        userResponse: answer,
        markQuestion: true,
        leftAssessmentAttemptTime: "",
      },

      // data: {
      // userCourseModel: {
      //   courseLaunchId: location.state.course_id,
      //   nodeId: location.state.node_id,
      //   userId: parseInt(sessionStorage.getItem("userId")),
      // },
      // qustionId: location.state.questionId,
      // answer: answer,
      // resultantQuestion: "string",
      // markQuestion: true,
      // leftAssessmentAttemptTime: "",
      // },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        choice: true,
        finalizeAssessment: true,
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    setisCancelClickedFromAssessmentTypes(true);
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        node_id: location.state.node_id,
        type: location.state.type,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };

  // useEffect(() => {
  // console.log("descQuestionDetails.Table[0].VC_QSTN_TXT :",descQuestionDetails.Table[0].VC_QSTN_TXT);
  // }, [descQuestionDetails])

  return (
    <div>
      <h3 className={assessmentStyles.questiontext}>
        {descQuestionDetails?.Table[0]?.VC_QSTN_TXT}
      </h3>

      <TextareaAutosize
        maxRows={5}
        aria-label="maximum height"
        placeholder="Type your answer"
        style={{ width: "100%", height: "100px" }}
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />

      <div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>

      <div
        style={{
          marginLeft: "40%",
          marginTop: "5%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleClickSave}
          sx={{ marginLeft: "5px" }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default DescriptiveQuestion;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

export const FillUps = (props) => {
  const location = useLocation();
  const [questionDetails, setQuestionDetails] = useState([]);
  const [answer, setAnswer] = useState("");
  const [questionFromSpan, setquestionFromSpan] = useState("");
  const num = location.state.questionNo;
  const navigate = useNavigate();
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const { t } = useTranslation();

  useEffect(() => {
    setAnswer("");
    setQuestionDetails([])
    axios({
      method: "GET",
      url:
        PUBLIC_URL +
        "/api/FillBlanksAssessment/MyLearning/GetQuestionDetailFillBlanks",

      params: {
        QuestionID: location.state.question_id,
        UserId: sessionStorage.getItem("userId"),
        NodeId: location.state.node_id,
        CourseLaunchId: location.state.course_id,
        UserResponse: null,
      },
    })
      .then((response) => {
        setQuestionDetails(response.data);
        if(response.data.Table1[0] != undefined && response.data.Table1.length > 0){
          if(location.state.button_type=="start" && response.data.Table7[0].BT_IS_ATTMPTD==true)
          {
            setAnswer(response.data.Table1[0].VC_ANSWR_TXT);
          }
          else if (location.state.button_type=="resume"){
            setAnswer(response.data.Table1[0].VC_ANSWR_TXT);
          }
          else{
            setAnswer("");
          }


        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);

  const handleClickNext = () => {
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/ELearning/MyLearning/EL/GetAssessmentQuestionDetails",

      data: {
        questionId: location.state.questionId,
        userId: sessionStorage.getItem("userId"),
        nodeId: location.state.node_id,
        courseLaunchId: location.state.course_id,
        userResponse: null,
      },
    })
      .then((response) => {
        setQuestionDetails(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickSave = () => {
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/FillBlanksAssessment/MyLearning/SaveFillBlanksAssessmentAns",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: location.state.question_id,
        answer: answer,
        resultantQuestion: questionDetails.Table6[0] != undefined ? questionDetails.Table6[0].VC_QSTN_TXT : "" ,
        markQuestion: true,
        leftAssessmentAttemptTime: "",
      },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setisCancelClickedFromAssessmentTypes(true);
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        choice: true,
        finalizeAssessment: true,
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id: location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };

  useEffect(() => {
  console.log("props : ", props);
  console.log("location : ", location.state);
  }, [props,location])

  useEffect(() => {
    
    console.log("questionDetails : ", questionDetails);
    if (questionDetails.Table6 != undefined) {
      var array = questionDetails.Table6[0].VC_QSTN_TXT.split("span");
      
      if (array.length > 0) {
      var res = array.filter(function(v, i) {
        // check the index is odd
        return i % 2 == 0;
      });
      let text = res.toString();
      let finalString=text.replaceAll("<,>","_________")
      setquestionFromSpan(finalString);
    }
      
      // if (array.length > 0) {
        
      //   let oddNumbers = array.filter(function(number) {
      //     return number % 2 !== 0
      //   }); 
    
      //   console.log(oddNumbers);
      //   console.log(array);
      //   array[0] = array[0].replace("<", "");
      //   array[2] = array[2].replace(">", "");
      //   array[1] = "_________";
      //   let finalString = array[0] + array[1] + array[2];
      //   finalString.replace("<","_________")
      //   setquestionFromSpan(finalString);
      // }
    }
  }, [questionDetails]);



  return (
    <div>
      <h3 className={assessmentStyles.questiontext}>
        {/* {questionDetails?.Table6?.VC_QSTN_TXT?.replace(
          '<span style="text-decoration: underline;">blank</span>',
          "____"
        )} */}

        {questionFromSpan}
      </h3>
      Answer :{" "}
      <input
        type="text"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />

<div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>
      <div
        style={{
          marginLeft: "40%",
          marginTop: "5%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
        {t("rbkey_CnclLBL")}
        </Button>

        <Button
          variant="contained"
          sx={{ marginLeft: "5px" }}
          onClick={handleClickSave}
        >
          {t("rbkey_SvLBL")}
        </Button>
      </div>
    </div>
  );
};
export default FillUps;

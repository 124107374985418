import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import assessmentStyles from "./Assessment.module.scss";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import { useContext } from "react";
import { Contexts } from "../../../../contexts/Contexts";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

export const TrueFalse = (props) => {
  // const locationData = useLocation();
  const location = useLocation();
  const [tfquestionDetails, settfQuestionDetails] = useState([]);
  const [answer, setAnswer] = useState("");
  const [selectedAns, setSelectedAns] = useState("");
  const num = location.state.questionNo;
  const navigate = useNavigate();
  let { setisCancelClickedFromAssessmentTypes } = useContext(Contexts);
  const { t } = useTranslation();
  
    useEffect(() => {
      setSelectedAns("");
      settfQuestionDetails([]);
    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        "/api/ELearning/MyLearning/EL/GetAssessmentQuestionDetailsTF",

      data: {
        // questionId: questionList?.Table[num]?.NM_QSTN_ID,
        questionId: location.state.question_id,
        userId: sessionStorage.getItem("userId"),
        nodeId: location.state.node_id,
        courseLaunchId: location.state.course_id,
        userResponse: null,
      },
    })
      .then((response) => {
        debugger;
        console.log("list",location.state.questionList)
        settfQuestionDetails(response);
        let arr= new Array();
        location.state.questionList.Table?.map((item, index) => {
                   arr[index]=item.BT_IS_CRRNT_QSTN_ATTMPT;
                })       
        if (
          response.data.Table1 != undefined &&
          response.data.Table1.length > 0 
        ) {
          debugger;
          //location.state.time=response.data.Table6[0].NM_LFT_ASSSSMNT_ATTMPT_TM.replace(/\s+/g, ' ').trim();
          //location.state.remainingTime=response.data.Table6[0].NM_LFT_ASSSSMNT_ATTMPT_TM.replace(/\s+/g, ' ').trim();
          if(location.state.button_type=="start" && response.data.Table6[0].BT_IS_ATTMPTD==true)
          {
            
            if (response.data.Table1[0].BT_ANSWR == true) {
              setSelectedAns("true");
            } else {
              setSelectedAns("false");
            }
          }
          else if (location.state.button_type=="resume"){
          if (response.data.Table1[0].BT_ANSWR == true) {
            setSelectedAns("true");
          } else {
            setSelectedAns("false");
          }
          }
          else{
            setSelectedAns("");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state.question_id]);


  const handleCancel = (event) => {
    event.preventDefault();
    setisCancelClickedFromAssessmentTypes(true);
    console.log("timer",location.state.remainingTime)
    let hour=parseInt(location.state.remainingTime.substring(0, 2))*3600
    let min=parseInt(location.state.remainingTime.substring(3, 5))*60
    let second=parseInt(location.state.remainingTime.substring(6, 8))
    let timeleft=hour+min+second;

    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveLeftAssessmentTime",
      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        choice: selectedAns === "true" ? true : false,
        markQuestion: true,
        //leftAssessmentAttemptTime: location.state.remainingTime.substring(3, 8),
        leftAssessmentAttemptTime: timeleft.toString()
      },
    

    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
    navigate("/assessment", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        node_id: location.state.node_id,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
      },
    });
  };
  const handleClickSave = () => {
    axios({
      method: "POST",
      url: PUBLIC_URL + "/api/ELearning/MyLearning/EL/SaveTFAnswer",

      data: {
        userCourseModel: {
          courseLaunchId: location.state.course_id,
          nodeId: location.state.node_id,
          userId: parseInt(sessionStorage.getItem("userId")),
        },
        qustionId: props.questionId,
        choice: selectedAns === "true" ? true : false,
        markQuestion: true,
        leftAssessmentAttemptTime: "",
      },

      // data: {
      //   userCourseModel: {
      //     courseLaunchId: location.state.questionId,
      //     nodeId: location.state.node_id,
      //     userId: sessionStorage.getItem("userId"),
      //   },
      //   qustionId: location.state.questionId,
      //   choice: answer === "true" ? true : false,
      //   markQuestion: true,
      //   leftAssessmentAttemptTime: "",
      // },
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const optionClicked = (isCorrect) => {
    if (tfquestionDetails.data.Table[0]?.VC_ANSWR_TXT === isCorrect) {
    } else {
    }
  };
  return (
    <div>
      <h3 className={assessmentStyles.questiontext}>
        {tfquestionDetails?.data?.Table[0]?.VC_QSTN_TXT}{" "}
      </h3>

      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedAns}
          onChange={(e) => setSelectedAns(e.target.value)}
        >
          <FormControlLabel value="true" control={<Radio />} label={t("rbkey_TrLBL")} />
          <FormControlLabel value="false" control={<Radio />} label={t("rbkey_FlsLBL")} />
        </RadioGroup>
      </FormControl>

      <div style={{paddingBottom:"2%",paddingTop:"1%"}}>
      <Alert severity="info">{t("rbKey_AlertMsgforAssmnt")}</Alert>
      </div>

      <div
        style={{
          marginLeft: "40%",
          marginTop: "5%",
        }}
      >
        <Button variant="contained" onClick={handleCancel}>
        {t("rbkey_CnclLBL")}
        </Button>

        <Button
          variant="contained"
          sx={{ marginLeft: "5px" }}
          onClick={handleClickSave}
        >
          {t("rbkey_SvLBL")}
        </Button>
      </div>
    </div>
  );
};
export default TrueFalse;

import "./ListEL.scss";
import React from "react";


export const ListEL = () => {
  return (
    <div>ListEL</div>
  )
}



import "./Slideshow.scss";
import React, { useEffect } from "react";
import { Info, PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BasicModel from "../../../ELearning/Components/ListItemML/popdescription/BasicModal";
import { Contexts } from "../../../../src/contexts/Contexts";
import fallback from "./fallback.png";

const colors = ["black", "#00C49F", "#FFBB28"];
const delay = 15000;

function Slideshow({ slides, props }) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  let navigate = useNavigate();
  const routeChange = (index) => {
    let videourl = slides[index].VC_VSTRM_URL;
    //Changes made by Saurav
    let path = `/watch`;
    navigate(path, {
      state: {
        videourl: videourl,
      },
    });
  };

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {slides.map((slide, index) => {
            if (slide !== undefined && slide !== null) {
              return (
                <div className="slide" key={index}>
                  <img
                    src={
                      slide.VC_THUMBNAIL_URL ? slide.VC_THUMBNAIL_URL : fallback
                    }
                    alt=""
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className="slideshowDots">
          {slides.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
        <div className="buttons">
          <button className="play">
            <PlayArrow onClick={() => routeChange(index)} />
          </button>

          <BasicModel
            //Added by Saurav
            Description={"Name : " + slides[index]?.VC_PDCST_NM + " "}
            Description2ndline={
              "Description : " + slides[index]?.VC_DESCRIPTION + " "
            }
          />
        </div>
      </div>
    </>
  );
}

export default Slideshow;

import "./navbar.scss";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  PUBLIC_URL_API2,
  DOMAIN_ID,
} from "./../../../ELearning/Components/Common/Constants.js";
import { UrlHelper } from "@microsoft/applicationinsights-common";

const NavbarVC = ({
  type,
  state,
  setState,
  searchItems,
  setElseCategoryItems,
  setElseCategoryItems1,
}) => {
  const { t } = useTranslation();
  const [isScrolled, setisScrolled] = useState(false);
  const [searchableArrayString, setsearchableArrayString] = useState([]);
  const { languageSelected } = useContext(Contexts);
  const { searchField } = useContext(Contexts);
  const { setsearchField } = useContext(Contexts);
  const { setdropdownItems } = useContext(Contexts);
  const { options } = useContext(Contexts);
  const { setoptions } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);

  const { setisVideoChannelLoaded } = useContext(Contexts);
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.token}` },
  };

  const colourStylesRow = {
    dropdownIndicator: (styles) => ({ ...styles, color: "#red" }),
  };

  useEffect(() => {
    const domain_id =
      sessionStorage.getItem("userDomain") !== null
        ? sessionStorage.getItem("userDomain")
        : DOMAIN_ID;

    const fetchData = async () => {
      const result = await fetch(
        PUBLIC_URL_API2 +
          "/api/Podcast/CategoryByDomain/" +
          languageSelected +
          "/" +
          domain_id,
        config
      );

      const json = await result.json();
      let tempArray = [
        {
          NM_CATEGORY_ID: 0,
          VC_CATEGORY_NAME: t("rbkey_SelectCategory"),
          VC_CATEGORY_VALUE: "",
        },
      ];
      json.map((item) =>
        tempArray.push({
          NM_CATEGORY_ID: item.NM_CATEGORY_ID,
          VC_CATEGORY_NAME: item.VC_CATEGORY_NAME,
          VC_CATEGORY_VALUE: item.VC_CATEGORY_NAME,
        })
      );
      if (tempArray.length === 0) {
        setisVideoChannelLoaded(false);
      } else {
        setisVideoChannelLoaded(true);

        setoptions(tempArray);
      }
    };
    fetchData();
  }, [languageSelected]);

  const dropDownChange = async (e) => {
    setState(e.currentTarget.value);
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const url =
      PUBLIC_URL_API2 +
      `/api/Podcast/Podcast4Category/` +
      option +
      `/` +
      sessionStorage.getItem("userId") +
      `/` +
      languageSelected;

    let temp = [];
    if (e.currentTarget.value !== "") {
      const result1 = await fetch(url, config);
      const json1 = await result1.json();

      json1.map((x, index) =>
        temp.push({
          index: index,
          ID: x.NM_PDCST_ID,
          Label: x.VC_PDCST_NM,
          category: x.VC_CATEGORY_NAME,
        })
      );

      if (json1 !== undefined) {
        setElseCategoryItems(json1);
      }

      setdropdownItems(json1);
    } else {
      searchItems.map((x, index) =>
        temp.push({
          index: index,
          ID: x.NM_PDCST_ID,
          Label: x.VC_PDCST_NM,
          category: x.VC_CATEGORY_NAME,
        })
      );
      setElseCategoryItems(searchItems);
    }
    let distinctArray = getUnique(temp, "Label");
    setsearchableArrayString(distinctArray);
  };

  function getUnique(array, key) {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  }

  useEffect(() => {
    if (searchItems != null && searchItems !== undefined) {
      let temp = [];
      searchItems.map((x, index) =>
        temp.push({
          index: index,
          ID: x.NM_PDCST_ID,
          Label: x.VC_PDCST_NM,
          category: x.VC_CATEGORY_NAME,
        })
      );
      let distinctArray = getUnique(temp, "Label");
      setsearchableArrayString(distinctArray);
    }
  }, [searchItems]);

  const handleKeyDown = (e, value) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      setsearchField(e.target.value);
      setElseCategoryItems1(e.target.value);
    } else if (e.key === "Enter" && e.target.value.length <= 3) {
      alert("Please enter more than 3 characters..");
    }
    setcurrentID("");
  };

  const handleSearchChange = (e, value) => {
    setsearchField(value);
    setElseCategoryItems1(value);
    setcurrentID("");
  };

  window.onscroll = () => {
    setisScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  const filterSearchResults = (searchableItem) => {
    const item = searchableItem?.category?.toLowerCase();
    if (state && item !== undefined && item !== null) {
      return item.includes(state);
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="navbar scrolled">
        <div className="container1">
          <div className="left">
            <Autocomplete
              className="autoCompleteTextBox"
              id="combo-box-demo"
              sx={{
                width: 300,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined css-1sumxir-MuiFormLabel-root-MuiInputLabel-root":
                  {
                    display: "none",
                  },
              }}
              size="small"
              options={searchableArrayString.map((item) => item.Label)}
              value={searchField}
              onKeyDown={(e, value) => {
                handleKeyDown(e, value);
              }}
              onChange={(e, value) => {
                handleSearchChange(e, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("rbkey_SrchTitleLBL").toUpperCase()}
                />
              )}
            />
            {type && (
              <div className="category">
                <select
                  name="genre"
                  styles={colourStylesRow}
                  onChange={(e) => dropDownChange(e)}
                >
                  {options.map((option) => (
                    <option
                      key={option.NM_CATEGORY_ID}
                      value={option.VC_CATEGORY_VALUE}
                      id={option.NM_CATEGORY_ID}
                    >
                      {option.VC_CATEGORY_NAME}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarVC;

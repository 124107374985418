import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { PUBLIC_URL } from "./../../Common/Constants.js";
import Table from "@mui/material/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Audio } from "react-loader-spinner";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export const Feedback = (props) => {
  const det = React.useRef(null);
  const det1 = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "darkblue",
    },
  }))(MuiTableHead);

  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "white",
    },
  }))(TableCell);

  function onClickSubmit() {
    axios({
      method: "post",
      url: PUBLIC_URL + "/api/Program/MyLearning/P/TrackCourse",
      data: {
        courseLaunchId: location.state.course_id,
        nodeId: parseInt(`${location.state.node_id}`),
        userId: sessionStorage.getItem("userId"),
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
    onClickDone();
  }
  function onClickDone() {
    navigate("/detailsML", {
      state: {
        course_id: location.state.course_id,
        type: location.state.type,
        course_name: location.state.course_name,
        start_date: location.state.start_date,
        end_date: location.state.end_date,
        credit_hours: location.state.credit_hours,
        progress: location.state.progress,
        description: location.state.description,
        VC_ILT_CD: location.state.VC_ILT_CD,
        imgSrc: location.state.imgSrc,
        topic_name: location.state.topic_name,
        node_id: location.state.node_id,
        certificateID: location.state.certificateID,
        DT_CMPLTN_DT: location.state.DT_CMPLTN_DT,
      },
    });
  }

  useEffect(() => {
    getFdbckDet();
  }, [location.state.course_id]);

  const getFdbckDet = async () => {
    setIsLoaded(false);
    await axios({
      method: "post",
      url:
        PUBLIC_URL +
        "/api/ELearning/GetFeedbackPreview?CourseLaunchId=" +
        location.state.course_id +
        "&UserId=" +
        parseInt(sessionStorage.getItem("userId")) +
        "&NodeId=" +
        location.state.node_id +
        "&TrRbKey=True&FlsRbKey=False",
      data: {
        CourseLaunchId: location.state.course_id,
        UserId: parseInt(sessionStorage.getItem("userId")),
        NodeId: location.state.node_id,
        TrRbKey: "True",
        FlsRbKey: "False",
      },
    })
      .then((res) => {
        det.current = res.data.Table;
        det1.current = res.data.Table1;
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <h3 align="center">Feedback Results</h3>
      {isLoaded && (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableRow>
                <TableCell align="left">
                  <strong>eLearning Name</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>
                    {det1?.current !== null &&
                      det1?.current !== undefined &&
                      det1?.current[0]?.VC_CRS_LNCH_NM}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Subject</strong>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  {det1?.current !== null &&
                    det1?.current !== undefined &&
                    det1?.current[0]?.VC_CRSS_NM}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Total Marks</strong>
                </TableCell>
                <TableCell align="left">
                  {det1?.current !== null &&
                    det1?.current !== undefined &&
                    det1?.current[0]?.FL_MRKS_OBTND}{" "}
                  {"/"}{" "}
                  {det1?.current !== null &&
                    det1?.current !== undefined &&
                    det1?.current[0]?.FL_TTL_MRKS}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Question Count</strong>
                </TableCell>
                <TableCell align="left">
                  {det?.current !== null &&
                    det?.current !== undefined &&
                    det?.current.length}
                </TableCell>
              </TableRow>
            </Table>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableHeaderCell>S. No.</TableHeaderCell>
                  <TableHeaderCell align="left">Question Text</TableHeaderCell>
                  <TableHeaderCell align="left">Question Type</TableHeaderCell>
                  <TableHeaderCell align="left">Question Marks</TableHeaderCell>
                  <TableHeaderCell align="left">Marks Obtained</TableHeaderCell>
                  <TableHeaderCell align="left">
                    Question Feedback
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {det.current !== null &&
                  det.current !== undefined &&
                  det?.current?.map((item, index) => (
                    <TableRow key={item.NM_QSTN_ID}>
                      <TableCell align="left"> {index + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {" "}
                        {item.VC_QSTN_TXT}{" "}
                      </TableCell>
                      <TableCell align="left">{item.NM_QSTN_TYP}</TableCell>
                      <TableCell align="left">
                        {item.FL_QSTN_LVL_MRKS}
                      </TableCell>
                      <TableCell align="left">{item.FL_MRKS_OBTND}</TableCell>
                      <TableCell align="left">{item.VC_FDBCK}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button variant="contained" onClick={onClickSubmit}>
                Done
              </Button>
            </div>
          </div>
        </>
      )}

      {!isLoaded && (
        <>
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="50" width="65" color="grey" arialLabel="Loading" />
          </div>
        </>
      )}
    </div>
  );
};
export default Feedback;
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Home.scss";
import Fusologo from "../Assets/Images/FusoSifnImg5.png";
import Popuptab from "../Components/Popupfortab/Popuptab";
import "../Components/Popupfortab/Popuptab.css";
import { useNavigate } from "react-router-dom";
import Popuptabvc from "../Components/Popupforvc/Popuptabvc";
import "../Components/Popupforvc/Popuptabvc.css";
import LanguageSelect from "../../VideoChannel/components/Language/languageSelect";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { Button } from "@mui/material";
import { useMediaQuery, useTheme } from "@material-ui/core";
import DrawerComponent from "./Drawer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Navbar() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isOpen11, setIsOpen11] = useState(false);
  const togglePopup11 = () => {
    setIsOpen11(!isOpen11);
  };
  const [isOpen12, setIsOpen12] = useState(false);
  const togglePopup12 = () => {
    setIsOpen12(!isOpen12);
  };
  const [y, setY] = useState(window.scrollY);
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY || y < window.scrollY) {
        if (isOpen11 === true || isOpen12 === true) {
          setIsOpen11(false);
          setIsOpen12(false);
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  function CheckOutsideClick(props) {
    const ref = useRef(null);
    const { onClickOutside, children } = props;

    const handleClickOutside = (event) => {
      if (ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        if (isOpen11 === true || isOpen12 === true) {
          setIsOpen11(false);
          setIsOpen12(false);
        }
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }, []);

    if (!children) {
      return null;
    }
    return <div ref={ref}>{children}</div>;
  }

  function handleChangeNavigation() {
    navigate("/mylearning");
  }
  if (isMobile) {
    return <DrawerComponent />;
  }
  return (
    <>
      <div>
        <CheckOutsideClick>
          {isOpen11 && (
            <Popuptab
              content={
                <div>
                  <Link className="link" to="/mylearning">
                    <Button className="linkbutton" color="inherit">
                      {t("rbkey_MyECrssLBL").toUpperCase()}
                    </Button>
                  </Link>
                  <p></p>
                  <Link className="link" to="/availablelearning">
                    <Button
                      className="linkbutton"
                      fullWidth="true"
                      color="inherit"
                      sx={{ width: "165px" }}
                    >
                      {t("rbkey_AvlblLrnngLBL").toUpperCase()}
                    </Button>
                  </Link>
                </div>
              }
              handleClose={togglePopup11}
            />
          )}
          {isOpen12 && (
            <Popuptabvc
              content={
                <div>
                  <Link className="link" to="/videochannel">
                    <Button className="linkbutton" color="inherit">
                      {t("rbkey_VideoChannelLBL").toUpperCase()}
                    </Button>
                  </Link>
                </div>
              }
              handleClose={togglePopup12}
            />
          )}
        </CheckOutsideClick>
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="MyTabs"
              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              <img src={Fusologo} alt="FusoImg"></img>
              <Tab
                className="MyTab"
                label={t("rbkey_DshbrdLBL")}
                onClick={() => {
                  navigate("/dashboard");
                  setIsOpen11(false);
                  setIsOpen12(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />
              <Tab
                className="MyTab"
                label={t("rbkey_LrnngCntr")}
                onClick={() => {
                  togglePopup11();

                  setIsOpen12(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />
              <Tab
                className="MyTab"
                label={t("rbkey_CllbrtnLBL")}
                onClick={() => {
                  togglePopup12();
                  setIsOpen11(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />
              <Tab
                className="MyTab"
                label={t("rbkey_IltClndrLBL")}
                onClick={() => {
                  navigate("/trainingcalender");
                  setIsOpen11(false);
                  setIsOpen12(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />

              <Tab
                className="MyTab"
                label={t("rbkey_CntctUsLBL")}
                onClick={() => {
                  navigate("/contact");
                  setIsOpen11(false);
                  setIsOpen12(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />
              <Tab
                className="MyTab"
                label={t("rbkey_HlpLBL")}
                onClick={() => {
                  navigate("/help");
                  setIsOpen11(false);
                  setIsOpen12(false);
                }}
                style={{
                  paddingTop: 12,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  minWidth: 150,
                }}
              />

              <div
                className="language"
                style={{ maxHeight: 40, paddingTop: 5, borderRadius: 5 , marginLeft: "auto", marginRight:"10px"}}
              >
                <LanguageSelect style={{ float: "right" }} />
              </div>
            </Tabs>
          </Box>
        </div>
      </div>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./VideoChannel/components/Language/i18nextInit";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { PUBLIC_URL } from "./ELearning/Components/Common/Constants.js";
debugger;
const query = new URLSearchParams(window.location.search);

sessionStorage.setItem("domainId", query.get("domainId"));

if (
  sessionStorage.getItem("case") != "case_1" &&
  (query.get("CourseId") === null || query.get("CourseId") === undefined)
) {
  sessionStorage.setItem("case", "case_2");
} else {
  sessionStorage.setItem("case", "case_1");
}

// case 1 - direct react url
// case 2 - navigating from LMS
if (sessionStorage.getItem("case") === "case_2") {
  if (query.get("authCode")) {
    const authCode = query.get("authCode");
    sessionStorage.setItem("authCode", authCode);
  }
  //sessionStorage.setItem("userID", 46387);
} else if (sessionStorage.getItem("case") === "case_1") {
  const CourseId = query.get("CourseId");
  const CourseType = query.get("CourseType");
  const selectedLang = query.get("selectedLang");
  sessionStorage.setItem("CourseId", CourseId);
  sessionStorage.setItem("CourseType", CourseType);
  if (selectedLang != null && selectedLang != "") {
    sessionStorage.setItem("selectedLanguage", selectedLang.split("-")[0]);
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

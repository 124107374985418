import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Pages/NavBar.jsx";
import truck from "../Assets/Images/truck.png";
import Popuptab from "./Popupfortab/Popuptab";
import "./Popupfortab/Popuptab.css";

export const Mylearning = () => {
  const [isOpen11, setIsOpen11] = useState(true);
  const togglePopup11 = () => {
    setIsOpen11(!isOpen11);
  };

  return (
    <div>
      <Navbar />
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${truck})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          backgroundfilter: "blur(5px)",
        }}
      >
        {isOpen11 && (
          <Popuptab
            content={
              <div>
                <Link className="link" to="/mylearning">
                  MY LEARNING
                </Link>
                <p></p>
                <Link className="link" to="/availablelearning">
                  AVAILABLE LEARNING
                </Link>
              </div>
            }
            handleClose={togglePopup11}
          />
        )}
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <p>{children}</p>}</div>;
}
export default Mylearning;

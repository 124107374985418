import "./App.scss";
import Home from "./VideoChannel/pages/Home/Home";
// import Watch from "./pages/watch/Watch";
import Watch from "./VideoChannel/pages/watch/Watch";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Contexts } from "./contexts/Contexts";
import { ListEL } from "./ELearning/Components/listEL/ListEL";
import { ListItemEL } from "./ELearning/Components/listItemEL/ListItemEL";
import { ListItemML } from "./ELearning/Components/ListItemML/ListItemML";
import Mylearning from "./ELearning/Components/Mylearning";
import AvailableLearning from "./ELearning/Components/AvailableLearning/AvailableLearning";
import Mylearn from "./ELearning/Components/MyLearning/mylearn";
import { Dashboard } from "./ELearning/Components/Dashboard/Dashboard";
import HomePage from "./ELearning/Pages/HomePage";
import OfferList from "./ELearning/Components/OfferList/OfferList";
import { Feedback } from "./ELearning/Components/Feedback/Feedback";
import swal from "sweetalert";
import LoginPage from "./ELearning/Pages/LoginPage";
import { ML_All_ClassroomTraining } from "./ELearning/Components/MyLearning/ML_All_ClassroomTraining";
import { ML_All_Elearning } from "./ELearning/Components/MyLearning/ML_All_Elearning";
import { ML_All_Program } from "./ELearning/Components/MyLearning/ML_All_Program";
import { AV_All_ClassroomTraining } from "./ELearning/Components/AvailableLearning/AV_All_ClassroomTraining";
import { AV_All_Elearning } from "./ELearning/Components/AvailableLearning/AV_All_Elearning";
import Assignment from "./ELearning/Components/Contents/Assignment/Assignment";
import PreReadMaterial from "./ELearning/Components/Contents/PreReadMaterial/PreReadMaterial";
import { AV_All_Program } from "./ELearning/Components/AvailableLearning/AV_All_Program";
import Quiz from "./ELearning/Components/Contents/Assessment/Quiz";
import Assessment from "./ELearning/Components/Contents/Assessment/Assessment";
import WatchML from "./ELearning/Components/ListItemML/watch/WatchML";
import Certificatefeature from "./ELearning/Pages/Certificatefeature/Certificatefeature";
import {
  PUBLIC_URL,
  PUBLIC_URL_API2,
} from "./ELearning/Components/Common/Constants.js";
import AccessDenied from "./AccessDenied/AccessDenied";
import OfferDetails from "./ELearning/Components/ListItemML/OfferDetails";
import { Audio } from "react-loader-spinner";
import {ContactUs} from "./ELearning/Components/ContactUs/ContactUs";
import { useTranslation } from "react-i18next";


function App() {
  const [optionValue, setoptionValue] = useState("");
  const [tokenReceived, settokenReceived] = useState(false);
  const [searchField, setsearchField] = useState("");
  const [currentID, setcurrentID] = useState("");
  const [prevState, setprevState] = useState(0);
  const [ifDataFound, setifDataFound] = useState(true);
  const [dropdownItems, setdropdownItems] = useState([]);
  const [languageSelected, setlanguageSelected] = useState("");
  const [langMap, setlangMap] = useState();
  const [startBtnGlobal, setstartBtnGlobal] = useState(false);
  const [gblEnrollDisable, setgblEnrollDisable] = useState(false);
  const [EL_P_ML_data, setEL_P_ML_data] = useState([]);
  const [isVideoChannelLoaded, setisVideoChannelLoaded] = useState(false);
  const [
    isCancelClickedFromAssessmentTypes,
    setisCancelClickedFromAssessmentTypes,
  ] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const [options, setoptions] = useState([]);
  const [isAuthorised, setIsAuthorised] = useState(true);

  let duration = 0;
  let playbackCompleted = 0;
  const { t } = useTranslation();
  const wrapper = document.createElement('div');
  wrapper.innerHTML = t("rbkey_ErrorMessage");

  // useEffect(() => {
  //   setisLoaded(true);
  //   setTimeout(() => {
  //     setisLoaded(false);
  //   }, 3000);
  // }, []);

  useEffect(() => {
    setisLoaded(false);
    if (sessionStorage.getItem("case") === "case_1") {
      settokenReceived(true);
      setlanguageSelected(sessionStorage.getItem("selectedLanguage"));
      setisLoaded(true);
    } else if (sessionStorage.getItem("case") === "case_2") {
      axios({
        method: "post",
        url: PUBLIC_URL_API2 + "/api/Authenticate",
        data: {
          authString: sessionStorage.getItem("authCode"),
        },
      })
        .then(
          (response) => {
            if (
              response.data.token !== null &&
              response.data.token !== "" &&
              response.data.token !== undefined
            ) {
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("userId", response.data.userId);
              sessionStorage.setItem(
                "userFirstName",
                response.data.userFirstName
              );
              sessionStorage.setItem(
                "userLastName",
                response.data.userLastName
              );
              sessionStorage.setItem(
                "domainLanguages",
                response.data.domainLanguages
              );
              sessionStorage.setItem(
                "selectedLanguage",
                response.data.selectedLanguage
              );
              sessionStorage.setItem("userDomain", response.data.userDomain);
              setlanguageSelected(response.data.selectedLanguage);
              settokenReceived(true);
              setlangMap(sessionStorage.getItem("domainLanguages"));
              sessionStorage.setItem("domainId", response.data.userDomain);
            } else {
              setIsAuthorised(false);
            }
          },
          (error) => {
            swal({
              //title: "Error!",
              content: wrapper, 
              //icon: "error",
            });
            setisLoaded(true);
            setIsAuthorised(false);
            console.log("CORS ERROR FOR DEVELOPER", error);
          }
        )
        .catch((error) => {
          swal({
            //title: "Error!",
            content: wrapper, 
            //icon: "error",
          });
          console.log(error);
          console.log("CORS ERROR FOR DEVELOPERS", error);
        });
    } else {
      setIsAuthorised(false);
    }

    setisLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          {tokenReceived && (
            <Contexts.Provider
              value={{
                searchField,
                setsearchField,
                duration,
                currentID,
                setcurrentID,
                prevState,
                setprevState,
                playbackCompleted,
                ifDataFound,
                setifDataFound,
                dropdownItems,
                setdropdownItems,
                languageSelected,
                setlanguageSelected,
                langMap,
                setlangMap,
                startBtnGlobal,
                setstartBtnGlobal,
                gblEnrollDisable,
                setgblEnrollDisable,
                EL_P_ML_data,
                setEL_P_ML_data,
                isVideoChannelLoaded,
                setisVideoChannelLoaded,
                isCancelClickedFromAssessmentTypes,
                setisCancelClickedFromAssessmentTypes,
                options,
                setoptions,
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/watchml" element={<WatchML />} />

                <Route path="/watch" element={<Watch />} />
                <Route path="/listelearning" element={<ListEL />} />

                <Route path="listitemelearning" element={<ListItemEL />} />
                <Route exact path="/home" element={<HomePage />} />
                <Route path="learningcenter" element={<Mylearning />} />
                {/* <Route path="mylearning" element={<Mylearn/>} /> */}
                <Route path="mylearning" element={<Mylearn />} />
                <Route path="quiz" element={<Quiz />} />
                <Route
                  path="availablelearning"
                  element={<AvailableLearning />}
                />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="trainingcalender" element={<Dashboard />} />
                <Route path="contact" element={<ContactUs />} />
                <Route
                  path="videochannel"
                  element={
                    <Home state={optionValue} setState={setoptionValue} />
                  }
                />
                <Route path="help" element={<Dashboard />} />
                <Route path="details" element={<ListItemEL />} />
                <Route
                  path="/Login"
                  // path="https://dscfmje-lmshorui2.azurewebsites.net/Login/:course/:id"

                  element={
                    <LoginPage
                      props={{
                        cr_id: sessionStorage.getItem("CourseId"),

                        cr_type: sessionStorage.getItem("CourseType"),
                      }}
                    />
                  }
                />
                <Route path="detailsML" element={<ListItemML />} />

                <Route
                  path="mylearning/classroomtraining"
                  element={<ML_All_ClassroomTraining />}
                />
                <Route
                  path="mylearning/elearning"
                  element={<ML_All_Elearning />}
                />
                <Route path="mylearning/program" element={<ML_All_Program />} />
                <Route
                  path="availablelearning/classroomtraining"
                  element={<AV_All_ClassroomTraining />}
                />
                <Route
                  path="availablelearning/elearning"
                  element={<AV_All_Elearning />}
                />
                <Route
                  path="availablelearning/program"
                  element={<AV_All_Program />}
                />
                <Route path="detailsML" element={<ListItemML />} />
                <Route path="assignment" element={<Assignment />} />
                <Route path="prereadmaterial" element={<PreReadMaterial />} />
                <Route path="assessment" element={<Assessment />} />
                <Route
                  path="certificatepage"
                  element={<Certificatefeature />}
                />
                <Route path="offerLists" element={<OfferList />} />
                <Route path="offerDetails" element={<OfferDetails />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="contact" element={<ContactUs />} />
              </Routes>
            </Contexts.Provider>
          )}

          {!tokenReceived && !isAuthorised && <AccessDenied />}

          {!tokenReceived && isAuthorised && (
            <div
              style={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "24px",
              }}
            >
              <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
                <Audio
                  height="60"
                  width="75"
                  color="grey"
                  arialLabel="Loading"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          <div style={{ paddingLeft: "45%", paddingTop: "20%" }}>
            <Audio height="60" width="75" color="grey" arialLabel="Loading" />
          </div>
        </div>
      )}
    </>
  );
}

export default App;

import { PlayArrow, Close, ThumbUp, ThumbUpOffAlt } from "@mui/icons-material";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./listItem.scss";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import image1 from "../../../../src/VideoChannel/image1.png";
import image2 from "../../../../src/VideoChannel/image2.png";
import image3 from "../../../../src/VideoChannel/image3.png";
import image4 from "../../../../src/VideoChannel/image4.png";
import axios from "axios";
import { ClickAwayListener } from "@mui/material";
import { Contexts } from "../../../contexts/Contexts";
import { useTranslation } from "react-i18next";
import { PUBLIC_URL } from "./../../../ELearning/Components/Common/Constants.js";

const ListItem = (props) => {
  const [stateprops, setstateprops] = useState();
  const [likesCount, setlikesCount] = useState(props.info.NM_LIKES);
  const [likesFlag, setlikesFlag] = useState(props.info.NM_LIKE_FLAG);
  const [isCloseIconClicked, setisCloseIconClicked] = useState(false);
  const [readMoreFlag, setreadMoreFlag] = useState(false);
  const listItemRef = useRef();
  let { currentID } = useContext(Contexts);
  let { setcurrentID } = useContext(Contexts);
  const [readLessFlag, setreadLessFlag] = useState(false);

  const [likecheck, setLikecheck] = useState(props.info.NM_LIKE_FLAG);
  const [Id, setId] = useState(props.info.NM_PDCST_ID);
  const [corpID, setCorpID] = useState(props.info.NM_CRPRT_ID);
  const [isLike, setIsLike] = useState(props.info.NM_LIKE_FLAG ? 1 : 0);

  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.token}` },
  };

  useEffect(() => {
    // console.log("props", props);
    if (props != null && props != undefined) {
      setstateprops(props);
    }
  }, [props]);

  useEffect(() => {
    if (props.info.NM_LIKES) {
      setlikesCount(props.info.NM_LIKES);
    } else {
      setlikesCount(0);
    }
  }, [props.info.NM_LIKES]);

  let navigate = useNavigate();
  const routeChange = (
    videourl,
    id,
    podcastId,
    corporateId,
    timestamp,
    playbackCompleted
  ) => {
    axios
      .get(
        PUBLIC_URL + `/api/Podcast/AddViews/` + id,

        config
      )
      .then((response) => {});

    let path = `/watch`;
    navigate(path, {
      state: {
        videourl: videourl,
        podcastId: podcastId,
        corporateId: corporateId,
        timestamp: timestamp,
        playbackCompleted: playbackCompleted,
        //moreFlag: stateprops.moreFlag,
        //setmoreFlag: stateprops.setmoreFlag,
      },
    });
  };
  let classNotifier = false;

  const handlelike = () => {
    setlikesFlag(!likesFlag);

    if (!likesFlag) {
      setlikesCount(likesCount + 1);
    } else {
      if (likesCount - 1 > 0) {
        setlikesCount(likesCount - 1);
      } else {
        setlikesCount(0);
      }
    }

    axios({
      method: "POST",
      url:
        PUBLIC_URL +
        `/api/Podcast/AddLikeAndDislike/` +
        Id +
        "/" +
        sessionStorage.getItem("userId") +
        `?isLike=${!likesFlag === true ? 1 : 0}&corporateId=` +
        corpID,
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        accept: "text/plain",
        "content-type": "text/plain; charset=utf-8",
      },
      data: {
        corporateId: corpID,
        isLike: !likesFlag === true ? 1 : 0,
        podcastId: Id,
        userID: Number(sessionStorage.getItem("userId")),
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isHovered, setisHovered] = useState(true);
  const [imageUrls, setimageUrls] = useState([
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
  ]);
  const imageClicked = (e) => {
    if (currentID == null || currentID == undefined || currentID == 0) {
      // double equal too
      var temp;
      temp = e.currentTarget.id;
      setcurrentID(temp);
    } else {
      var element = document.getElementById(currentID);
      element.classList.remove("clickedclass");
      element.classList.add("listItem");
      var temp;
      temp = e.currentTarget.id;
      setcurrentID(temp);
    }
    classNotifier = false;
    checkClass(e);
  };

  const checkClass = (e) => {
    e.stopPropagation();

    if (!classNotifier) {
      e.currentTarget.classList.add("clickedclass");
      e.currentTarget.classList.remove("listItem");
    } else {
      e.currentTarget.parentElement.classList.remove("clickedclass");
      e.currentTarget.parentElement.classList.add("listItem");
    }
  };

  const closeIconClicked = (e) => {
    setisCloseIconClicked(false);
    classNotifier = true;
    checkClass(e);
    setreadMoreFlag(false);
  };
  const handleReadMore = (e) => {
    setreadMoreFlag(true);
    setreadLessFlag(true);
    let r = document.querySelector(":root");
    let rs = getComputedStyle(r);
    r.style.setProperty("--overflow-property", "auto");
  };

  const handleReadLess = (e) => {
    setreadMoreFlag(false);
    setreadLessFlag(false);
  };

  const handleClickAway = (e) => {
    if (
      currentID !== 0 &&
      currentID !== undefined &&
      currentID !== "" &&
      currentID != null
    ) {
      var element = document.getElementById(currentID);
      element.classList.remove("clickedclass");
      element.classList.add("listItem");
    }
    setcurrentID("");
  };
  const { t } = useTranslation();
  const trailer =
    "https://dscfmjelmsmedia-jpea.streaming.media.azure.net/91880eb0-2971-4e3d-8794-21ef454746ad/200311LMS";

  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <div
        ref={listItemRef}
        id={`${props.type}_${stateprops?.info.VC_CATEGORY_NAME}_${stateprops?.info.NM_PDCST_ID}`}
        className="listItem"
        onClick={(e) => {
          imageClicked(e);
        }}
      >
        <img src={stateprops?.info.VC_THUMBNAIL_URL} alt="" />
        <Close
          className="closeicon"
          onClick={(e) => {
            closeIconClicked(e);
          }}
        />
        {isHovered && (
          <>
            <div id={stateprops?.info.NM_PDCST_ID} className="itemInfo">
              <div className="icons">
                {stateprops?.info.NM_VIDEO_TIME_REMAINING > 0 &&
                  (stateprops?.info.NM_VIDEO_COMPLETED == false ||
                    stateprops?.info.NM_VIDEO_COMPLETED == null) && (
                    <span className="ContinueWatching">
                      {" "}
                      {t("rbkey_resume")}
                    </span>
                  )}
                {stateprops?.info.NM_VIDEO_COMPLETED && (
                  <span className="ContinueWatching">{t("rbkey_Replay")}</span>
                )}
                <PlayArrow
                  className="icon"
                  onClick={() =>
                    routeChange(
                      stateprops.info.VC_VSTRM_URL,
                      stateprops.info.NM_PDCST_ID,
                      stateprops.info.NM_PDCST_ID,
                      stateprops.info.NM_CRPRT_ID,
                      stateprops.info.NM_VIDEO_TIME_REMAINING,
                      stateprops.info.NM_VIDEO_COMPLETED
                    )
                  }
                />
                <span className="likesCount">{likesCount}</span>
                <>
                  {likesFlag ? (
                    <ThumbUp
                      className="icon"
                      onClick={handlelike}
                      sx={{ color: "deepskyblue" }}
                    />
                  ) : (
                    //unlike
                    <ThumbUpOffAlt className="icon" onClick={handlelike} />
                  )}
                </>
              </div>

              <div className="itemInfoTop">
                <span>
                  {stateprops?.info?.VC_DURATION != null
                    ? stateprops?.info.VC_DURATION
                    : "00:00:00"}
                </span>
                <span className="views">
                  {stateprops?.info?.NM_VIEWS !== undefined &&
                  stateprops?.info?.NM_VIEWS !== null
                    ? stateprops?.info?.NM_VIEWS
                    : 0}
                  {t("rbkey_views")}
                </span>
              </div>

              {/* {!readMoreFlag &&
              stateprops?.info?.VC_DESCRIPTION != null &&
              stateprops?.info.VC_DESCRIPTION.length > 50 ? (
                <>
                  <div className="desc">
                    <label className="labelDes">Description : </label>
                    <>
                      {stateprops?.info?.VC_DESCRIPTION.substring(0, 50)}...
                    </>{" "}
                  </div>
                  <div className="readMore" onClick={(e) => handleReadMore(e)}>
                    {t("rbkey_ReadMore")}
                  </div>
                </>
              ) : (
                <div className="desc">{stateprops?.info.VC_DESCRIPTION}</div>
              )} */}
              {/* {console.log(
                "stateprops?.info, ",
                stateprops?.info,
                stateprops?.info.VC_DESCRIPTION,
                stateprops?.info.VC_DESCRIPTION.length
              )} */}
              {!readMoreFlag &&
              stateprops?.info?.VC_DESCRIPTION?.length > 50 ? (
                <>
                  <div className="desc">
                    <label className="labelDes">Description : </label>
                    <>
                      {stateprops?.info.VC_DESCRIPTION.substring(0, 50)}...
                    </>{" "}
                  </div>
                  <div className="readMore" onClick={(e) => handleReadMore(e)}>
                    {t("rbkey_ReadMore")}
                  </div>
                </>
              ) : (
                <div className="desc">
                  <label className="labelDes">Description : </label>
                  <>{stateprops?.info.VC_DESCRIPTION}</>
                </div>
              )}
              {readLessFlag && (
                <div className="readMore" onClick={(e) => handleReadLess(e)}>
                  {t("rbkey_ReadLess")}
                </div>
              )}
              <div className="genre">
                <b>
                  <u>{t("rbkey_CtgryLBL")}</u>
                </b>
                : {stateprops?.info.VC_CATEGORY_NAME}
              </div>
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ListItem;

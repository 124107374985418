import React from "react";
import "./Footerfuso.css";

const Footerfuso = () => {
  return (
    <div className="footerfuso">
    <p>
      <span>
        <p
          style={{
            color: "rgba(255,255,255,0.4)",
            fontSize: 14,
            marginBottom: "0.55rem",
          }}
        >
          Privacy Policy
        </p>
      </span>
    </p>
    <p
      style={{
        marginBottom: 0.5,
        fontSize: 14,
        color: "rgba(255,255,255,0.2)",
      }}
      className="phoverfooter"
    >
      ©2018 Mitsubishi Fuso Truck and Bus Corporation. All rights reserved
    </p>
  </div>
  );
};
export default Footerfuso;
